import React, {useEffect, useRef} from 'react';
import styles from './ViewIssuesModal.module.scss';
import {ErrorPlusBig, ModalCloseSvg, QuestionIcon} from "@assets/icons";
import Tippy from "@tippyjs/react";
import {viewIssuesModalIssues} from "@assets/constants/viewIssuesModalWrapperIssues"
import Button, {ModifierType} from "../../../../components/UI/Button";

interface IViewIssuesModalProps {
	handleClickViewIssues:() => void;
	handleClickViewIssuesModalOption:(option:any) => void;
	updatedOptions:any;
}

const ViewIssuesModal = (props: IViewIssuesModalProps) => {
	const {  handleClickViewIssues,handleClickViewIssuesModalOption,updatedOptions } = props ;
	const getIssuesCount = (issueIndex:any) => {
		const issuesCount  = updatedOptions[issueIndex].issues_count ;
		return issuesCount
	}


	const viewIssuesModalRef = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		const handleClickOutside = (event: Event) => {
			if (
			 viewIssuesModalRef.current &&
			 !viewIssuesModalRef.current.contains(event.target as Node)
			) {
				handleClickViewIssues();
			}
		};

		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [viewIssuesModalRef, handleClickViewIssues]);

	const isHaveIssues = updatedOptions?.some((updateOption:any) => updateOption.issues_count > 0)

	return (
	 <div className={styles['modal__overlay']}>
		 <div ref={viewIssuesModalRef} className={styles.modal}>
			 <div className={styles['modal__header']}>
				 <div className={styles['modal__header-left']}>
					 <ErrorPlusBig/>
					 <span className={styles['modal__header-title']}>Filter Issues</span>
				 </div>
				 <ModalCloseSvg
				  className={styles['modal__closeButton']}
				  onClick={handleClickViewIssues}
				 />
			 </div>
			 <div className={styles['modal__text']}>
				 {isHaveIssues ? "Filter listings with issues by selecting issue type(s) below." : "No issues found!"}
			 </div>
			 <div className={styles['modal__line']}></div>
			 <div className={styles['modal__wrapper']}>
				 {viewIssuesModalIssues?.map((modalItem: any, index: number) => (
				  <div
				   key={modalItem.id}
				   className={styles['modal__item']}
				  >
					  <div className={styles['modal__item-left']}>
						  <span
						   className={!getIssuesCount(index) ? `${styles['modal__item-count']} ${styles['modal__item-count--notActive']}` : index !== 0 ? styles['modal__item-count'] : `${styles['modal__item-count']} ${styles['modal__item-count--red']}`}>{getIssuesCount(index)}</span>
						  <div className={styles['modal__item-text']}>{modalItem.label}</div>
						  <Tippy
						   interactive={true}
						   arrow={false}
						   zIndex={9999}
						   trigger="click"
						   content={
							   <div className={styles.popoverContent}>
								   <p>{modalItem.tooltipText}</p>
							   </div>
						   }
						  >
							  <QuestionIcon
							   className={styles['modal__item-questionIcon']}
							  />
						  </Tippy>
					  </div>
					  <Button
					   style={{
						   background:!getIssuesCount(index) ? "#F8F8F8" : "",
						   color:!getIssuesCount(index)  ? "#767676" : "",
						   cursor:!getIssuesCount(index) ? "not-allowed" : "pointer"
					   }}
					   modifier={ModifierType.Primary2}
					   handleClick={() => {
						   if(!getIssuesCount(index)) return
						   handleClickViewIssuesModalOption(modalItem)
					   }}
					  >
						  <span>Filter</span>
					  </Button>
				  </div>
				 ))}
			 </div>
		 </div>
	 </div>

	);
};

export default ViewIssuesModal;
