import { useState, useEffect } from 'react';
import { compare } from 'compare-versions';


const UseDetailedExtensionVersion = (compareVersion:any) => {
	const [isExtensionSupport, setIsExtensionSupport] = useState<boolean | null>(null);

	useEffect(() => {
		const bodyElement = document.body;

		const handleAttributeChange = () => {
			const extensionVersionString = bodyElement.getAttribute('lp_custom_chrome_extension_version');
			try {
				if (extensionVersionString) {
					const isSupported:boolean = compare(extensionVersionString, compareVersion, '>=');
					setIsExtensionSupport(isSupported);
				} else {
					setIsExtensionSupport(false);
				}
			} catch (e) {
				console.error('Error comparing versions:', e);
				setIsExtensionSupport(false);
			}
		};

		const observer = new MutationObserver(() => {
			handleAttributeChange();
		});
		observer.observe(bodyElement, {
			attributes: true,
			attributeFilter: ['lp_custom_chrome_extension_version'],
		});

		// Initial attribute check
		handleAttributeChange();

		return () => {
			observer.disconnect();
		};
	}, [compareVersion]);

	return isExtensionSupport;
};

export default UseDetailedExtensionVersion;
