import React, {useState, useEffect, useRef} from 'react';
import QRCode from 'react-qr-code';
import styles from './SingleProductView.module.scss';
import Sliders from './components/slider/Sliders';
import PlatformBlockİtem from '../../../pages/CatalogPage/components/Table/components/PlatformBlockItem/index';
import Vector from '../../../assets/icons/svg/Vector.svg';
import { MoonLoader } from 'react-spinners';
import useBasicFuncs from '../../../hooks/useBasisFuncs';
import { instanceAxios } from '../../../services';
import {
    AddButton, AddListingSVG,
    AddPlus,
    CheckedIcon,
    CloseSvg,
    CrosspostSingleView,
    EditPencil, ExitButton, PopoverIcon, PopoverIconWhite,
    SVGNewSquares
} from "../../../assets/icons";
import _ from 'lodash';
import {
    EditListing,
    Print,
} from '../../../assets/icons';

import {useDispatch, useSelector} from "react-redux";
import {
    iframeLoaderChanger, setCandidantLinkPure, setDuplicateListingId, setEditAddModalId, setEditAddModalShow,
    setIframeLink,
    setIframeName, setListingViewId,
    setShowIframe, setShowListingViewModal,
    setShowQuestionModal
} from "../../../redux/slices/NavigationSlice";
import Tippy from "@tippyjs/react";
import 'tippy.js/dist/tippy.css';
import './SingleProductView.css';
import jwt_decode from "jwt-decode";
import getToken from "../../../utils/getToken";
import {useNavigate} from "react-router-dom";

interface Tooltip {
    ebay?: boolean;
    etsy?: boolean;
    poshmark?: boolean;
    mercari?: boolean;
    amazon?: boolean;
    grailed?: boolean;
    facebook?: boolean;
    depop?: boolean;
    kidizen?: boolean;
    shopify?: boolean;
    instagram?: boolean;
    vestiaire?: boolean;
}

interface ProductInfo {
    created_at: string;
    id?: number;
    images: [];
    metadata: any;
    status: string;
    thumbnail: string;
    type: string;
    updated_at: string;
    user_id: number;
}

const SingleProductView = () => {
    const navigate = useNavigate()
    const location = window.location ;
    const searchParams = new URLSearchParams(location.search);
    const listViewId = useSelector((state: any) => state.navigation.listingViewId);
    const [productInfo, setProductInfo] = useState<ProductInfo | null>(null);
    const customMarketsLocal = useSelector((state: any) => state.customMarketplaces.customMarketplaces);
    const listingViewModalID = useSelector((state: any) => state.navigation.listingViewId);
    const listingViewModalShow = useSelector((state: any) => state.navigation.listingViewModalShow);
    const [visibleCrossPost, setVisibleCrossPost] = useState(false);
    const [shopifyDinamicLink, setShopifyDinamicLink] = useState(null);
    const [globalFooters, setGlobalFoooters] = useState<any>({});
    const [productInfoJSON, setProductInfoJSON] = useState<any>(null);
    const [allowSendListingJSON, setAllowSendListingJSON] = useState(false);
    const [id, setId] = useState<any>(null);
    const dispatch = useDispatch();
    const token: any = getToken();
    const decodedToken: any = jwt_decode(token);
    const roles = decodedToken?.data?.user?.roles;
    const is_role_allowed = roles.some((role: any) => {
        return (
            role.toLowerCase() === 'corporate master' ||
            role.toLowerCase() === 'corporate sub'
        );
    });
    const hasBusiness = roles
        .map((role: string) => role.toLowerCase())
        .includes('Business'.toLowerCase());
    const hasSimple = roles
        .map((role: string) => role.toLowerCase())
        .includes('simple'.toLowerCase());
    const hasPro = roles
        .map((role: string) => role.toLowerCase())
        .includes('pro'.toLowerCase());

    useEffect(() => {
        const url = window.location.href;
        const id:any = url.split('/').pop();
        setId(+id);
    }, []);
    const origin = window.location.origin;
    const { bdecode } = useBasicFuncs();
    const iframeLink = useSelector(
        (state: any) => state.navigation.iframeLink
    );
    const spanKeywords = productInfo?.metadata[453]?.split(', ');
    const initialPlatforms = {
        eBay: { isCopied: false },
        Etsy: { isCopied: false },
        Poshmark: { isCopied: false },
        Mercari: { isCopied: false },
        Amazon: { isCopied: false },
        Grailed: { isCopied: false },
        Facebook: { isCopied: false },
        Depop: { isCopied: false },
        Kidizen: { isCopied: false },
        Shopify: { isCopied: false },
        Instagram: { isCopied: false },
        listing_party: { isCopied: false },
        Vestiaire: { isCopied: false },
    };
    const [platforms, setPlatforms] = useState<any>(initialPlatforms);
    const handleUpdateState = (platformName: any) => {
        try {
            setPlatforms((prevPlatforms:any) => ({
                ...prevPlatforms,
                [platformName]: { isCopied: true },
            }));
            setTimeout(() => {
                setPlatforms((prevPlatforms:any) => ({
                    ...prevPlatforms,
                    [platformName]: { isCopied: false },
                }));
            }, 1000);
        }
        catch (e) {
            console.log('error', e)
        }
    };

    const [tooltipsPlatformActive, setTooltipsPlatformActive] = useState<Tooltip>(
        {
            ebay: false,
            etsy: false,
            poshmark: false,
            mercari: false,
            amazon: false,
            grailed: false,
            facebook: false,
            depop: false,
            kidizen: false,
            shopify: false,
            instagram: false,
            vestiaire: false,
        },
    );
    const handleTooltipClickPlatforms = (tooltipName: any) => {
        setTooltipsPlatformActive((prevState) => ({
            ebay: tooltipName === 'eBay' ? !prevState.ebay : false,
            etsy: tooltipName === 'Etsy' ? !prevState.etsy : false,
            poshmark: tooltipName === 'Poshmark' ? !prevState.poshmark : false,
            mercari: tooltipName === 'Mercari' ? !prevState.mercari : false,
            amazon: tooltipName === 'Amazon' ? !prevState.amazon : false,
            grailed: tooltipName === 'Grailed' ? !prevState.grailed : false,
            facebook: tooltipName === 'Facebook' ? !prevState.facebook : false,
            instagram: tooltipName === 'Instagram' ? !prevState.instagram : false,
            depop: tooltipName === 'Depop' ? !prevState.depop : false,
            kidizen: tooltipName === 'Kidizen' ? !prevState.kidizen : false,
            shopify: tooltipName === 'Shopify' ? !prevState.shopify : false,
            vestiaire: tooltipName === 'Vestiaire' ? !prevState.vestiaire : false,
        }));
    };
    const [isLoading, setIsLoading] = useState(true);

    async function fetchData(): Promise<void> {
        try {
            const response = await instanceAxios.post('/listings/list', {
                metadata: { offset: 0, limit: 1 },
                filter_by: { id: id ? id : listingViewModalID },
                options: {
                    include_thumbnail: true,
                    include_images: true,
                },
            });
            if (response.status === 200) {
                setProductInfo(response?.data?.data[0]);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    async function fetchGlobalFooters(): Promise<void> {
        try {
                const response = await instanceAxios.post('/users/legacy/metadata/list', {
                    metadata: { offset: 0, limit: 10 },
                    filter_by: {},
                });
                if (response.status === 200) {
                    const subdomain = _.get(
                        response.data.data,
                        'mepr_shopify_id_subdomain',
                    );
                    if (subdomain !== undefined) {
                        setShopifyDinamicLink(subdomain);
                    }
                   if(!hasSimple && !hasBusiness) {
                       setGlobalFoooters(response.data.data);
                    }
                }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    const renderFooterItem = (property: string, title: string) => {
        if (_.has(globalFooters, property)) {
            const description = globalFooters[property];
            const sanitizedDescription = description.replace(
                /(\r\n|\r|\n)+/g,
                '<br>',
            );

            return (
                <>
                    {
                        description.length ? <div className={styles.footerItemContainer}>
                            <p className={styles.globalFootersItemTitle}>{title}</p>
                            <p
                                className={styles.globalFootersItemSubTitle}
                                dangerouslySetInnerHTML={{__html: sanitizedDescription}}
                            />
                        </div> : ''
                    }
                </>

            );
        }
        return null;
    };

    useEffect(() => {
        // Array of codes to iterate over
        const codesToCheck = ['891.1', '891.2', '891.3', '891.4', '891.5', '891.6', '891.7', '891.8', '891.9', '891.10'];

        // Iterate over codes and update state
        codesToCheck.forEach(code => {
            const title = findAndReturnTitleByKey(code, customMarketsLocal);

            if (title) {
                setPlatforms((prevPlatforms:any) => ({
                    ...prevPlatforms,
                    [title]: { isCopied: false },
                }));
            }
        });
    }, [customMarketsLocal]);


    const shopifyLinkLocal = shopifyDinamicLink
        ? `https://admin.shopify.com/store/${shopifyDinamicLink}/products/${productInfo?.metadata[855]}`
        : `https://.myshopify.com/admin/products/${productInfo?.metadata[855]}`;


    function findAndReturnTitleByKey(codeToFind:any, customMarkets:any) {
        for (const market of customMarkets) {
            if (market?.platform && market?.platform?.code && market?.platform?.code?.includes(codeToFind)) {
                return market?.platform?.title;
            }
        }
        // If code is not found, you can return null or any other default value
        return null;
    }
    const isRequestSentRef = useRef<any>(false); // Track if the request has been sent
    useEffect(() => {
        const fetchDataAndFooters = async () => {
            if (isRequestSentRef.current) return; // Block if request already sent
            if(id || listingViewModalID) {
                isRequestSentRef.current = true; // Mark as sent to prevent further requests
            }
            setIsLoading(true);
            try {
                if (id || listingViewModalID) {
                    if(id) {
                        dispatch(setListingViewId(id))
                    }
                    else {
                        dispatch(setListingViewId(listingViewModalID))
                    }
                    await Promise.all([fetchData(), fetchGlobalFooters()]);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
            finally {
                setAllowSendListingJSON(true);
                setIsLoading(false);
            }
        };

        fetchDataAndFooters();
    }, [id, listingViewModalID]);

    useEffect(() => {
        return () => {
            dispatch(setListingViewId(null));
            dispatch(setShowListingViewModal(false));
        }
    }, []);


    useEffect(() => {
        if(allowSendListingJSON) {
            try {
                const jsonStringProductInfo = JSON.stringify(productInfo);
                const jsonStringFooters = JSON.stringify(globalFooters);
                const productInfoObj = JSON.parse(jsonStringProductInfo);
                const footersObj = JSON.parse(jsonStringFooters);

                const combinedObj = {
                    ...productInfoObj,
                    ...footersObj
                };

                const jsonStringCombined = JSON.stringify(combinedObj);
                if(productInfo) {
                    setProductInfoJSON(jsonStringCombined)
                }
            }
            catch (e) {
                console.log(e, 'error')
            }
        }

    }, [allowSendListingJSON, productInfo]);


    const crossPostRef:any = useRef(null);


    const checkCrossPostClass = () => {
        if (crossPostRef.current && crossPostRef.current.classList.contains('lp_custom_crosspost_icon_disabled')) {
            setVisibleCrossPost(true);
        } else {
            setVisibleCrossPost(false);
        }
    };

    useEffect(() => {
        try {
            const crossPostElement = crossPostRef.current;

            // if (!crossPostElement) return;

            // Create an observer instance linked to the callback function
            const observer = new MutationObserver(() => {
                checkCrossPostClass();
            });

            // Start observing the target node for configured mutations
            observer.observe(crossPostElement, {
                attributes: true,
                attributeFilter: ['class'],
            });

            // Initial check
            checkCrossPostClass();

            // Cleanup function to disconnect the observer
            return () => {
                observer.disconnect();
            };
        }
        catch (e) {
            console.log(e, 'error')
        }
    }, [crossPostRef.current, productInfo]);

    const platformsCode: string[] = [
        '539.3',
        '539.2',
        '539.3',
        '539.4',
        '539.5',
        '539.6',
        '539.7',
        '539.8',
        '539.9',
        '539.11',
        '539.12',
        '539.13',
        '539.14',
        '539.15',
        '539.16',
        '539.17',
        '891.1',
        '891.2',
        '891.3',
        '891.4',
        '891.5',
        '891.6',
        '891.7',
        '891.8',
        '891.9',
        '891.10'
    ];

    const containKeys = platformsCode.some(
     (key) => typeof productInfo?.metadata[key] === 'string'
    );

    const handleCloseListingModal = () =>{
        if( searchParams?.has('modal')) {
            searchParams.delete('modal');
            navigate(`?${searchParams.toString()}`);
        }
        dispatch(setShowListingViewModal(false))
    }

    useEffect(() => {
        if (listingViewModalShow) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }

        // Cleanup function to reset overflow when component unmounts
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [listingViewModalShow]);

    useEffect(() => {
        return () => {
            dispatch(setShowListingViewModal(false));
        }
    }, []);

    const descriptionKey = hasPro && productInfo?.metadata[854] ? 854 : 505;


    return (
        <div className={`${styles.singleProductViewContainer} ${listingViewModalShow ? styles.singloProductAsModal : ''} `} id={"singleProductView"}>

            <div className={`${styles.SingleProductView} ${listingViewModalShow ? styles.singleproductViewModal : ''}`}>
                {
                    isLoading ? (
                        <div className={styles.loading}>
                            <MoonLoader />
                        </div>
                    ) :  productInfo && (
                        <div className={styles.SingleProductViewContent} id={`${listingViewModalShow ? 'listingViewModalContent' : 'listingViewNavigateContent'}`}>
                            <div className={styles.fixedHeader}>
                                <div className={styles.titleRightTop}>
                                    <button
                                     className={`${styles.buttonCrossPost} lp_custom_crosspost_icon lp_custom_crosspost_icon_disabled lp_custom_chrome_extension_single_view_page_crosspost_icon`}
                                     ref={crossPostRef}>
                                        <Tippy
                                         interactive={true}
                                         trigger={'click'}
                                         placement={'bottom'}
                                         zIndex={9991}
                                         content={
                                             visibleCrossPost ?
                                              <div className="popover">
                                                  <p>
                                                      Crossposting is not enabled.{' '}
                                                      <a
                                                       style={{width: '100%', color: 'blue'}}
                                                       target="_blank"
                                                       href="https://help.listperfectly.com/en/articles/8913965"
                                                      >
                                                          Install
                                                      </a>{' '}
                                                      or activate extension to enable.{' '}
                                                  </p>
                                              </div> : null
                                         }
                                        >
                                            <div
                                             style={{
                                                 display: 'flex',
                                                 alignItems: 'center',
                                                 gap: '10px',
                                                 padding: '5px',
                                             }}
                                            >
                                                <CrosspostSingleView/>
                                                <span style={{display:"none"}}>Crosspost</span>
                                            </div>
                                        </Tippy>
                                    </button>

                                    <button className={styles.buttonEdit} onClick={() => {
                                        if(listingViewModalShow) {
                                            dispatch(setEditAddModalShow(true));
                                            dispatch(setEditAddModalId(listViewId));
                                            dispatch(setShowListingViewModal(false));
                                        }else {
                                            navigate(`/listings/edit/${listViewId}`);
                                        }
                                    }}>
                                        <EditPencil/>
                                        <span>Edit</span>
                                    </button>
                                    {
                                     (hasPro && is_role_allowed) &&
                                     <>
                                     <button className={styles.buttonPrint}
                                             onClick={() => window.open(`${origin}/print-qr-page/${listViewId}`)}>
                                         <Print/>
                                         Inventory Label
                                     </button>
                                         <Tippy
                                          interactive={true}
                                          zIndex={9991}
                                          trigger="click"
                                          arrow={false}
                                          content={
                                              <div className={`popover ${styles.popoverContent}`}>
                                                  <>
                                                     <p>Easily manage your inventory with labels that include the item title, SKU, and a QR code. Scan the code to instantly view the listing in your List Perfectly catalog. Ideal for quick item location and efficient inventory management. <a target='_blank' href={"https://help.listperfectly.com/en/articles/8912348 "}>Learn more.</a> </p>
                                                  </>
                                              </div>
                                          }
                                         >
                                              <PopoverIcon
                                               className={styles.popoverIcon}
                                               color={'#fff'}
                                              ></PopoverIcon>
                                         </Tippy>
                                     </>
                                    }

                                    <button className={styles.buttonDuplicate} onClick={() => {
                                        dispatch(setDuplicateListingId(listViewId));
                                        navigate('/listings/add');
                                        dispatch(setShowListingViewModal(false));
                                    }}>
                                        <SVGNewSquares/>
                                        <span>Duplicate</span>
                                    </button>
                                    <button className={styles.buttonNew} onClick={() => {
                                        navigate('/listings/add');
                                        dispatch(setShowListingViewModal(false));
                                    }}>
                                        <AddListingSVG/>
                                        <span>New</span>
                                    </button>
                                    {
                                     listingViewModalShow &&
                                     <div className={styles.closeIconListingModal} onClick={handleCloseListingModal}>
                                         <ExitButton/>
                                     </div>
                                    }

                                </div>
                            </div>
                            <div className={`${styles.singleViewBody} ${listingViewModalShow ? styles.singleViewBodyModal : ''}`}>
                                <div className={styles.listingViewTitle}>
                                    Listing Preview
                                </div>
                                {productInfoJSON && <div className="lp_custom_chrome_extension_listing_data">{productInfoJSON}</div>}
                                <div className={styles.title}>

                                <div className={styles.titleLeft}>
                                        <p
                                         className={`${styles.mobileHeaderTitle} ${listingViewModalShow ? styles.mobileHeaderTitleModal : ''}`}>Listing
                                            Preview
                                            {
                                             listingViewModalShow &&
                                             <div className={styles.closeIconListingModal}
                                                  onClick={handleCloseListingModal}>
                                                 <ExitButton/>
                                             </div>
                                            }
                                        </p>


                                        {productInfo?.metadata[133] ? (
                                         <div className={`${styles.titleText}`}>
                                             {bdecode(productInfo?.metadata[133])}
                                         </div>
                                        ) : (
                                         <div className={styles.titleText}>--</div>
                                        )}
                                        {productInfo?.metadata[22] && (
                                         <div className={styles.price}>
                                             {formatter.format(productInfo?.metadata[22])}
                                         </div>
                                        )}
                                    </div>
                                    <div className={styles.titleRight}>
                                        <div className={styles.listingStatusesContainer}>
                                            {/* Active */}
                                            {containKeys && !productInfo?.metadata[859.1] &&
                                             !productInfo?.metadata[539.1] && productInfo?.metadata[851] !== '0' && (
                                              <div
                                               className={`${styles.listingStatusSinglView} ${styles.active}`}>Active</div>
                                             )}

                                            {/* Out of Stock */}
                                            {productInfo?.metadata[851] === '0' &&
                                             !productInfo?.metadata[859.1] && !productInfo?.metadata[539.1] && (
                                              <div
                                               className={`${styles.listingStatusSinglView} ${styles.outOfStock}`}>Out
                                                  of Stock</div>
                                             )}

                                            {/* Sold */}
                                            {productInfo?.metadata[539.1] && !productInfo?.metadata[859.1] && (
                                             <div
                                              className={`${styles.listingStatusSinglView} ${styles.sold}`}>Sold</div>
                                            )}

                                            {/* Draft */}
                                            {productInfo?.metadata[859.1] && (
                                             <div
                                              className={`${styles.listingStatusSinglView} ${styles.draft}`}>Draft</div>
                                            )}

                                            {/* Unlisted */}
                                            {!containKeys && !productInfo?.metadata[859.1] &&
                                             !productInfo?.metadata[539.1] && (
                                              <div
                                               className={`${styles.listingStatusSinglView} ${styles.unlisted}`}>Unlisted</div>
                                             )}
                                        </div>
                                        <div>
                                            {productInfo?.metadata[1] ? (
                                             <div className="printViewSku">
                                                 <div className={styles.titleObjectKeyNameSKU}>SKU</div>
                                                 <div className={styles.titleObjectValueSKU}>
                                                     {bdecode(productInfo?.metadata[1])}
                                                 </div>
                                             </div>
                                            ) : ''}
                                            {productInfo?.metadata[851] ? (
                                             <div className="printViewQuantity">
                                                 <div className={styles.titleObjectKeyNameSKU}>Quantity</div>
                                                 <div className={styles.titleObjectValueSKU}>
                                                     {bdecode(productInfo?.metadata[851])}
                                                 </div>
                                             </div>
                                            ) : ''}
                                            {productInfo?.metadata[726] ? (
                                             <div>
                                                 <div className={styles.titleObjectKeyNameSKU}>Seller Notes</div>
                                                 <div className={styles.titleObjectValueSKU}>
                                                     {bdecode(productInfo?.metadata[726])}
                                                 </div>
                                             </div>
                                            ) : ''}
                                        </div>
                                    </div>
                                </div>
                                <div className={`${styles.images}`}>
                                    <Sliders
                                     imageUrls={productInfo?.images}
                                     imagesLength={productInfo?.images?.length}
                                    />
                                </div>
                                <div className={`${styles.info} `}>
                                    <div className={styles.listedOn}>
                                        <div className={`${styles.fieldTitle} singlePageForTippy`}>
                                            Listed on
                                        </div>
                                        {productInfo?.metadata[539.2] && (
                                         <div className={styles.platformInfo}>
                                             <div className={styles.iconBlock}>
                                                 <PlatformBlockİtem
                                                  platformName={'eBay'}
                                                  href={`https://ebay.com/itm/${productInfo?.metadata[618]}`}
                                                  isLink={
                                                   productInfo?.metadata[618] &&
                                                   productInfo?.metadata[539.2] === 'eBay'
                                                  }
                                                  fromPlatform={true}
                                                  visible={tooltipsPlatformActive.ebay}
                                                  handleTooltipClickPlatforms={handleTooltipClickPlatforms}
                                                  tooltipsPlatformActive={tooltipsPlatformActive}
                                                  IsSold={productInfo?.metadata[539.1]}

                                                 />
                                                </div>
                                                <div>
                                                    <div className={styles.platformName}>eBay</div>
                                                    <div className={styles.idDiv}>
                                                        <span className={styles.idWord}>ID</span>
                                                        {productInfo?.metadata[618] ? (
                                                            <a
                                                                className={styles.aListedOnId}
                                                                href={`https://ebay.com/itm/${productInfo?.metadata[618]}`}
                                                                target="_blank"
                                                            >
                                                                {productInfo?.metadata[618]}
                                                            </a>
                                                        ) : (
                                                            <div className={styles.noMarketListed}>-</div>
                                                        )}
                                                        {productInfo?.metadata[618] && (
                                                            <>
                                                                {
                                                                    platforms['eBay']?.isCopied ?
                                                                        <div className={styles.copiedContent}><CheckedIcon/>
                                                                            <span>Copied</span></div> : (
                                                                            <img
                                                                                style={{cursor: 'pointer'}}
                                                                                src={Vector}
                                                                                alt="copyIcon"
                                                                                onClick={() => {
                                                                                    navigator.clipboard.writeText(
                                                                                        productInfo?.metadata[618],
                                                                                    );
                                                                                    handleUpdateState('eBay')
                                                                                }
                                                                                }
                                                                            />
                                                                        )
                                                                }
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {productInfo?.metadata[539.3] && (
                                            <div className={styles.platformInfo}>
                                                <div className={styles.iconBlock}>
                                                    <PlatformBlockİtem
                                                        platformName={'Etsy'}
                                                        href={`https://www.etsy.com/listing/${productInfo?.metadata[619]}}`}
                                                        isLink={
                                                            productInfo?.metadata[619] &&
                                                            productInfo?.metadata[539.3] === 'Etsy'
                                                        }
                                                        fromPlatform={true}
                                                        visible={tooltipsPlatformActive.etsy}
                                                        handleTooltipClickPlatforms={handleTooltipClickPlatforms}
                                                        tooltipsPlatformActive={tooltipsPlatformActive}
                                                        IsSold={productInfo?.metadata[539.1]}

                                                    />
                                                </div>
                                                <div>
                                                    <div className={styles.platformName}>Etsy</div>
                                                    <div className={styles.idDiv}>
                                                        <span className={styles.idWord}>ID</span>
                                                        {productInfo?.metadata[619] ? (
                                                            <a
                                                                className={styles.aListedOnId}
                                                                href={`https://www.etsy.com/listing/${productInfo?.metadata[619]}}`}
                                                                target="_blank"
                                                            >
                                                                {productInfo?.metadata[619]}
                                                            </a>
                                                        ) : (
                                                            <div className={styles.noMarketListed}>-</div>
                                                        )}
                                                        {productInfo?.metadata[619] && (
                                                            <>
                                                                {
                                                                    platforms['Etsy']?.isCopied ?
                                                                        <div className={styles.copiedContent}><CheckedIcon/>
                                                                            <span>Copied</span></div> : (
                                                                            <img
                                                                                style={{cursor: 'pointer'}}
                                                                                src={Vector}
                                                                                alt="copyIcon"
                                                                                onClick={() => {
                                                                                    navigator.clipboard.writeText(
                                                                                        productInfo?.metadata[619],
                                                                                    );
                                                                                    handleUpdateState('Etsy')
                                                                                }
                                                                                }
                                                                            />
                                                                        )
                                                                }
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                        {productInfo?.metadata[539.4] && (
                                            <div className={styles.platformInfo}>
                                                <div className={styles.iconBlock}>
                                                    <PlatformBlockİtem
                                                        platformName={'Poshmark'}
                                                        href={`https://poshmark.com/listing/${productInfo?.metadata[620]}`}
                                                        isLink={
                                                            productInfo?.metadata[620] &&
                                                            productInfo?.metadata[539.4] === 'Poshmark'
                                                        }
                                                        fromPlatform={true}
                                                        visible={tooltipsPlatformActive.poshmark}
                                                        handleTooltipClickPlatforms={handleTooltipClickPlatforms}
                                                        tooltipsPlatformActive={tooltipsPlatformActive}
                                                        IsSold={productInfo?.metadata[539.1]}

                                                    />
                                                </div>
                                                <div>
                                                    <div className={styles.platformName}>Poshmark</div>
                                                    <div className={styles.idDiv}>
                                                        <span className={styles.idWord}>ID</span>
                                                        {productInfo?.metadata[620] ? (
                                                            <a
                                                                className={styles.aListedOnId}
                                                                href={`https://poshmark.com/listing/${productInfo?.metadata[620]}`}
                                                                target="_blank"
                                                            >
                                                                {productInfo?.metadata[620]}
                                                            </a>
                                                        ) : (
                                                            <div className={styles.noMarketListed}>-</div>
                                                        )}
                                                        {productInfo?.metadata[620] && (
                                                            <>
                                                                {
                                                                    platforms['Poshmark']?.isCopied ?
                                                                        <div className={styles.copiedContent}><CheckedIcon/>
                                                                            <span>Copied</span></div> : (
                                                                            <img
                                                                                style={{cursor: 'pointer'}}
                                                                                src={Vector}
                                                                                alt="copyIcon"
                                                                                onClick={() => {
                                                                                    navigator.clipboard.writeText(
                                                                                        productInfo?.metadata[620],
                                                                                    );
                                                                                    handleUpdateState('Poshmark')
                                                                                }
                                                                                }
                                                                            />
                                                                        )
                                                                }
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                        {productInfo?.metadata[539.5] && (
                                            <div className={styles.platformInfo}>
                                                <div className={styles.iconBlock}>
                                                    <PlatformBlockİtem
                                                        platformName={'Mercari'}
                                                        href={`https://www.mercari.com/us/item/${productInfo?.metadata[621]}`}
                                                        isLink={
                                                            productInfo?.metadata[621] &&
                                                            productInfo?.metadata[539.5] === 'Mercari'
                                                        }
                                                        fromPlatform={true}
                                                        visible={tooltipsPlatformActive.mercari}
                                                        handleTooltipClickPlatforms={handleTooltipClickPlatforms}
                                                        tooltipsPlatformActive={tooltipsPlatformActive}
                                                        IsSold={productInfo?.metadata[539.1]}

                                                    />
                                                </div>
                                                <div>
                                                    <div className={styles.platformName}>Mercari</div>
                                                    <div className={styles.idDiv}>
                                                        <span className={styles.idWord}>ID</span>
                                                        {productInfo?.metadata[621] ? (
                                                            <a
                                                                className={styles.aListedOnId}
                                                                href={`https://www.mercari.com/us/item/${productInfo?.metadata[621]}`}
                                                                target="_blank"
                                                            >
                                                                {productInfo?.metadata[621]}
                                                            </a>
                                                        ) : (
                                                            <div className={styles.noMarketListed}>-</div>
                                                        )}
                                                        {productInfo?.metadata[621] && (
                                                            <>
                                                                {
                                                                    platforms['Mercari']?.isCopied ?
                                                                        <div className={styles.copiedContent}><CheckedIcon/>
                                                                            <span>Copied</span></div> : (
                                                                            <img
                                                                                style={{cursor: 'pointer'}}
                                                                                src={Vector}
                                                                                alt="copyIcon"
                                                                                onClick={() => {
                                                                                    navigator.clipboard.writeText(
                                                                                        productInfo?.metadata[621],
                                                                                    );
                                                                                    handleUpdateState('Mercari')
                                                                                }
                                                                                }
                                                                            />
                                                                        )
                                                                }
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {productInfo?.metadata[539.7] && (
                                            <div className={styles.platformInfo}>
                                                <div className={styles.iconBlock}>
                                                    <PlatformBlockİtem
                                                        platformName={'Amazon'}
                                                        // sync={row?.sync}
                                                        href={`https://www.amazon.com/${productInfo?.metadata[836]}`}
                                                        isLink={
                                                            productInfo?.metadata[836] &&
                                                            productInfo?.metadata[539.7] === 'Amazon'
                                                        }
                                                        fromPlatform={true}
                                                        visible={tooltipsPlatformActive.amazon}
                                                        handleTooltipClickPlatforms={handleTooltipClickPlatforms}
                                                        tooltipsPlatformActive={tooltipsPlatformActive}
                                                        IsSold={productInfo?.metadata[539.1]}

                                                    />
                                                </div>
                                                <div>
                                                    <div className={styles.platformName}>Amazon</div>
                                                    <div className={styles.idDiv}>
                                                        <span className={styles.idWord}>ID</span>
                                                        {productInfo?.metadata[836] ? (
                                                            <a
                                                                className={styles.aListedOnId}
                                                                href={`https://www.amazon.com/${productInfo?.metadata[836]}`}
                                                                target="_blank"
                                                            >
                                                                {productInfo?.metadata[836]}
                                                            </a>
                                                        ) : (
                                                            <div className={styles.noMarketListed}>-</div>
                                                        )}
                                                        {productInfo?.metadata[836] && (
                                                            <>
                                                                {
                                                                    platforms['Amazon']?.isCopied ?
                                                                        <div className={styles.copiedContent}><CheckedIcon/>
                                                                            <span>Copied</span></div> : (
                                                                            <img
                                                                                style={{cursor: 'pointer'}}
                                                                                src={Vector}
                                                                                alt="copyIcon"
                                                                                onClick={() => {
                                                                                    navigator.clipboard.writeText(
                                                                                        productInfo?.metadata[836],
                                                                                    );
                                                                                    handleUpdateState('Amazon')
                                                                                }
                                                                                }
                                                                            />
                                                                        )
                                                                }
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                        {productInfo?.metadata[539.8] && (
                                            <div className={styles.platformInfo}>
                                                <div className={styles.iconBlock}>
                                                    <PlatformBlockİtem
                                                        platformName={'Grailed'}
                                                        // sync={row?.sync}
                                                        href={`https://www.grailed.com/listings/${productInfo?.metadata[839]}`}
                                                        isLink={
                                                            productInfo?.metadata[839] &&
                                                            productInfo?.metadata[539.8] === 'Grailed'
                                                        }
                                                        fromPlatform={true}
                                                        visible={tooltipsPlatformActive.grailed}
                                                        handleTooltipClickPlatforms={handleTooltipClickPlatforms}
                                                        tooltipsPlatformActive={tooltipsPlatformActive}
                                                        IsSold={productInfo?.metadata[539.1]}

                                                    />
                                                </div>
                                                <div>
                                                    <div className={styles.platformName}>Grailed</div>
                                                    <div className={styles.idDiv}>
                                                        <span className={styles.idWord}>ID</span>
                                                        {productInfo?.metadata[839] ? (
                                                            <a
                                                                className={styles.aListedOnId}
                                                                href={`https://www.grailed.com/listings/${productInfo?.metadata[839]}`}
                                                                target="_blank"
                                                            >
                                                                {productInfo?.metadata[839]}
                                                            </a>
                                                        ) : (
                                                            <div className={styles.noMarketListed}>-</div>
                                                        )}
                                                        {productInfo?.metadata[839] && (
                                                            <>
                                                                {
                                                                    platforms['Grailed']?.isCopied ?
                                                                        <div className={styles.copiedContent}><CheckedIcon/>
                                                                            <span>Copied</span></div> : (
                                                                            <img
                                                                                style={{cursor: 'pointer'}}
                                                                                src={Vector}
                                                                                alt="copyIcon"
                                                                                onClick={() => {
                                                                                    navigator.clipboard.writeText(
                                                                                        productInfo?.metadata[839],
                                                                                    );
                                                                                    handleUpdateState('Grailed')
                                                                                }
                                                                                }
                                                                            />
                                                                        )
                                                                }
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {productInfo?.metadata[539.9] && (
                                            <div className={styles.platformInfo}>
                                                <div className={styles.iconBlock}>
                                                    <PlatformBlockİtem
                                                        platformName={'Facebook'}
                                                        // sync={row?.sync}
                                                        href={`https://www.facebook.com/marketplace/category/${productInfo?.metadata[840]}`}
                                                        isLink={
                                                            productInfo?.metadata[840] &&
                                                            productInfo?.metadata[539.9] === 'Facebook'
                                                        }
                                                        fromPlatform={true}
                                                        visible={tooltipsPlatformActive.facebook}
                                                        handleTooltipClickPlatforms={handleTooltipClickPlatforms}
                                                        tooltipsPlatformActive={tooltipsPlatformActive}
                                                        IsSold={productInfo?.metadata[539.1]}
                                                    />
                                                </div>
                                                <div>
                                                    <div className={styles.platformName}>Facebook</div>
                                                    <div className={styles.idDiv}>
                                                        <span className={styles.idWord}>ID</span>
                                                        {productInfo?.metadata[840] ? (
                                                            <a
                                                                className={styles.aListedOnId}
                                                                href={`https://www.facebook.com/marketplace/category/${productInfo?.metadata[840]}`}
                                                                target="_blank"
                                                            >
                                                                {productInfo?.metadata[840]}
                                                            </a>
                                                        ) : (
                                                            <div className={styles.noMarketListed}>-</div>
                                                        )}
                                                        {productInfo?.metadata[840] && (
                                                            <>
                                                                {
                                                                    platforms['Facebook']?.isCopied ?
                                                                        <div className={styles.copiedContent}><CheckedIcon/>
                                                                            <span>Copied</span></div> : (
                                                                            <img
                                                                                style={{cursor: 'pointer'}}
                                                                                src={Vector}
                                                                                alt="copyIcon"
                                                                                onClick={() => {
                                                                                    navigator.clipboard.writeText(
                                                                                        productInfo?.metadata[840],
                                                                                    );
                                                                                    handleUpdateState('Facebook')
                                                                                }
                                                                                }
                                                                            />
                                                                        )
                                                                }
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {productInfo?.metadata[539.11] && (
                                            <div className={styles.platformInfo}>
                                                <div className={styles.iconBlock}>
                                                    <PlatformBlockİtem
                                                        platformName={'Depop'}
                                                        // sync={row?.sync}
                                                        href={`https://www.depop.com/products/${productInfo?.metadata[841]}`}
                                                        isLink={
                                                            productInfo?.metadata[841] &&
                                                            productInfo?.metadata[539.11] === 'Depop'
                                                        }
                                                        fromPlatform={true}
                                                        visible={tooltipsPlatformActive.depop}
                                                        handleTooltipClickPlatforms={handleTooltipClickPlatforms}
                                                        tooltipsPlatformActive={tooltipsPlatformActive}
                                                        IsSold={productInfo?.metadata[539.1]}

                                                    />
                                                </div>
                                                <div>
                                                    <div className={styles.platformName}>Depop</div>
                                                    <div className={styles.idDiv}>
                                                        <span className={styles.idWord}>ID</span>
                                                        {productInfo?.metadata[841] ? (
                                                            <a
                                                                className={styles.aListedOnId}
                                                                href={`https://www.depop.com/products/${productInfo?.metadata[841]}`}
                                                                target="_blank"
                                                            >
                                                                {productInfo?.metadata[841]}
                                                            </a>
                                                        ) : (
                                                            <div className={styles.noMarketListed}>-</div>
                                                        )}
                                                        {productInfo?.metadata[841] && (
                                                            <>
                                                                {
                                                                    platforms['Depop']?.isCopied ?
                                                                        <div className={styles.copiedContent}><CheckedIcon/>
                                                                            <span>Copied</span></div> : (
                                                                            <img
                                                                                style={{cursor: 'pointer'}}
                                                                                src={Vector}
                                                                                alt="copyIcon"
                                                                                onClick={() => {
                                                                                    navigator.clipboard.writeText(
                                                                                        productInfo?.metadata[841],
                                                                                    );
                                                                                    handleUpdateState('Depop')
                                                                                }
                                                                                }
                                                                            />
                                                                        )
                                                                }
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                        {productInfo?.metadata[539.12] && (
                                            <div className={styles.platformInfo}>
                                                <div className={styles.iconBlock}>
                                                    <PlatformBlockİtem
                                                        platformName={'Kidizen'}
                                                        // sync={row?.sync}
                                                        href={`https://www.kidizen.com/items/${productInfo?.metadata[853]}`}
                                                        isLink={
                                                            productInfo?.metadata[853] &&
                                                            productInfo?.metadata[539.12] === 'Kidizen'
                                                        }
                                                        fromPlatform={true}
                                                        visible={tooltipsPlatformActive.kidizen}
                                                        handleTooltipClickPlatforms={handleTooltipClickPlatforms}
                                                        tooltipsPlatformActive={tooltipsPlatformActive}
                                                        IsSold={productInfo?.metadata[539.1]}
                                                    />
                                                </div>
                                                <div>
                                                    <div className={styles.platformName}>Kidizen</div>
                                                    <div className={styles.idDiv}>
                                                        <span className={styles.idWord}>ID</span>
                                                        {productInfo?.metadata[853] ? (
                                                            <a
                                                                className={styles.aListedOnId}
                                                                href={`https://www.kidizen.com/items/${productInfo?.metadata[853]}`}
                                                                target="_blank"
                                                            >
                                                                {productInfo?.metadata[853]}
                                                            </a>
                                                        ) : (
                                                            <div className={styles.noMarketListed}>-</div>
                                                        )}
                                                        {productInfo?.metadata[853] && (
                                                            <>
                                                                {
                                                                    platforms['Kidizen']?.isCopied ?
                                                                        <div className={styles.copiedContent}><CheckedIcon/>
                                                                            <span>Copied</span></div> : (
                                                                            <img
                                                                                style={{cursor: 'pointer'}}
                                                                                src={Vector}
                                                                                alt="copyIcon"
                                                                                onClick={() => {
                                                                                    navigator.clipboard.writeText(
                                                                                        productInfo?.metadata[853],
                                                                                    );
                                                                                    handleUpdateState('Kidizen')
                                                                                }
                                                                                }
                                                                            />
                                                                        )
                                                                }
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {productInfo?.metadata[539.13] && (
                                            <div className={styles.platformInfo}>
                                                <div className={styles.iconBlock}>
                                                    <PlatformBlockİtem
                                                        platformName={'Shopify'}
                                                        // sync={row?.sync}
                                                        href={shopifyLinkLocal}
                                                        isLink={
                                                            productInfo?.metadata[855] &&
                                                            productInfo?.metadata[539.13] === 'Shopify'
                                                        }
                                                        fromPlatform={true}
                                                        visible={tooltipsPlatformActive.shopify}
                                                        handleTooltipClickPlatforms={handleTooltipClickPlatforms}
                                                        tooltipsPlatformActive={tooltipsPlatformActive}
                                                        IsSold={productInfo?.metadata[539.1]}

                                                    />
                                                </div>
                                                <div>
                                                    <div className={styles.platformName}>Shopify</div>
                                                    <div className={styles.idDiv}>
                                                        <span className={styles.idWord}>ID</span>
                                                        {productInfo?.metadata[855] ? (
                                                            <a
                                                                className={styles.aListedOnId}
                                                                href={`https://accounts.shopify.com/lookup?rid=70f0f0e2-3f19-4767-849c-f8e779c0005a`}
                                                                target="_blank"
                                                            >
                                                                {productInfo?.metadata[855]}
                                                            </a>
                                                        ) : (
                                                            <div className={styles.noMarketListed}>-</div>
                                                        )}
                                                        {productInfo?.metadata[855] && (
                                                            <>
                                                                {
                                                                    platforms['Shopify']?.isCopied ?
                                                                        <div className={styles.copiedContent}><CheckedIcon/>
                                                                            <span>Copied</span></div> : (
                                                                            <img
                                                                                style={{cursor: 'pointer'}}
                                                                                src={Vector}
                                                                                alt="copyIcon"
                                                                                onClick={() => {
                                                                                    navigator.clipboard.writeText(
                                                                                        productInfo?.metadata[855],
                                                                                    );
                                                                                    handleUpdateState('Shopify')
                                                                                }
                                                                                }
                                                                            />
                                                                        )
                                                                }
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                        {productInfo?.metadata[539.14] && (
                                            <div className={styles.platformInfo}>
                                                <div className={styles.iconBlock}>
                                                    <PlatformBlockİtem
                                                        platformName={'Instagram'}
                                                        // sync={row?.sync}
                                                        href={`https://www.instagram.com/p/${productInfo?.metadata[856]}`}
                                                        isLink={
                                                            productInfo?.metadata[856] &&
                                                            productInfo?.metadata[539.14] === 'Instagram'
                                                        }
                                                        fromPlatform={true}
                                                        visible={tooltipsPlatformActive.instagram}
                                                        handleTooltipClickPlatforms={handleTooltipClickPlatforms}
                                                        tooltipsPlatformActive={tooltipsPlatformActive}
                                                        IsSold={productInfo?.metadata[539.1]}

                                                    />
                                                </div>
                                                <div>
                                                    <div className={styles.platformName}>Instagram</div>
                                                    <div className={styles.idDiv}>
                                                        <span className={styles.idWord}>ID</span>
                                                        {productInfo?.metadata[856] ? (
                                                            <a
                                                                className={styles.aListedOnId}
                                                                href={`https://www.instagram.com/p/${productInfo?.metadata[856]}`}
                                                                target="_blank"
                                                            >
                                                                {productInfo?.metadata[856]}
                                                            </a>
                                                        ) : (
                                                            <div className={styles.noMarketListed}>-</div>
                                                        )}
                                                        {productInfo?.metadata[856] && (
                                                            <>
                                                                {
                                                                    platforms['Instagram']?.isCopied ?
                                                                        <div className={styles.copiedContent}><CheckedIcon/>
                                                                            <span>Copied</span></div> : (
                                                                            <img
                                                                                style={{cursor: 'pointer'}}
                                                                                src={Vector}
                                                                                alt="copyIcon"
                                                                                onClick={() => {
                                                                                    navigator.clipboard.writeText(
                                                                                        productInfo?.metadata[856],
                                                                                    );
                                                                                    handleUpdateState('Instagram')
                                                                                }
                                                                                }
                                                                            />
                                                                        )
                                                                }
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                        {productInfo?.metadata[539.16] && (
                                            <div className={styles.platformInfo}>
                                                <div className={styles.iconBlock}>
                                                    <PlatformBlockİtem
                                                        platformName={'listing_party'}
                                                        href={`https://listingparty.com/listings?id`}
                                                        isLink={true}
                                                        IsSold={productInfo?.metadata[539.1]}
                                                    />
                                                </div>
                                                <div>
                                                    <div className={styles.platformName}>Listing Party</div>
                                                    <div className={styles.idDiv}>
                                                        <span className={styles.idWord}>ID</span>
                                                        {productInfo?.metadata[621] ? (
                                                            <a
                                                                className={styles.aListedOnId}
                                                                href={`https://listingparty.com/listings?id`}
                                                                target="_blank"
                                                            >
                                                                {productInfo?.metadata[621]}
                                                            </a>
                                                        ) : (
                                                            <div className={styles.noMarketListed}>-</div>
                                                        )}
                                                        {productInfo?.metadata[621] && (
                                                            <>
                                                                {
                                                                    platforms['listing_party']?.isCopied ?
                                                                        <div className={styles.copiedContent}><CheckedIcon/>
                                                                            <span>Copied</span></div> : (
                                                                            <img
                                                                                style={{cursor: 'pointer'}}
                                                                                src={Vector}
                                                                                alt="copyIcon"
                                                                                onClick={() => {
                                                                                    navigator.clipboard.writeText(
                                                                                        productInfo?.metadata[621],
                                                                                    );
                                                                                    handleUpdateState('listing_party')
                                                                                }
                                                                                }
                                                                            />
                                                                        )
                                                                }
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                        {productInfo?.metadata[539.17] && (
                                            <div className={styles.platformInfo}>
                                                <div className={styles.iconBlock}>
                                                    <PlatformBlockİtem
                                                        platformName={'Vestiaire'}
                                                        // sync={row?.sync}
                                                        href={`https://vestiairecollective.com/${productInfo?.metadata[885]}.shtml`}
                                                        isLink={
                                                            productInfo?.metadata[885] &&
                                                            productInfo?.metadata[539.17] === 'Vestiaire'
                                                        }
                                                        fromPlatform={true}
                                                        visible={tooltipsPlatformActive.vestiaire}
                                                        handleTooltipClickPlatforms={handleTooltipClickPlatforms}
                                                        tooltipsPlatformActive={tooltipsPlatformActive}
                                                        IsSold={productInfo?.metadata[539.1]}
                                                    />
                                                </div>
                                                <div>
                                                    <div className={styles.platformName}>Vestiaire</div>
                                                    <div className={styles.idDiv}>
                                                        <span className={styles.idWord}>ID</span>
                                                        {productInfo?.metadata[885] ? (
                                                            <a
                                                                className={styles.aListedOnId}
                                                                href={`https://vestiairecollective.com/${productInfo?.metadata[885]}.shtml`}
                                                                target="_blank"
                                                            >
                                                                {productInfo?.metadata[885]}
                                                            </a>
                                                        ) : (
                                                            <div className={styles.noMarketListed}>-</div>
                                                        )}
                                                        {productInfo?.metadata[885] && (
                                                            <>
                                                                {
                                                                    platforms['Vestiaire']?.isCopied ?
                                                                        <div className={styles.copiedContent}><CheckedIcon/>
                                                                            <span>Copied</span></div> : (
                                                                            <img
                                                                                style={{cursor: 'pointer'}}
                                                                                src={Vector}
                                                                                alt="copyIcon"
                                                                                onClick={() => {
                                                                                    navigator.clipboard.writeText(
                                                                                        productInfo?.metadata[885],
                                                                                    );
                                                                                    handleUpdateState('Vestiaire')
                                                                                }
                                                                                }
                                                                            />
                                                                        )
                                                                }
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {(productInfo?.metadata[891.1] && !hasSimple) && (
                                            <div className={styles.platformInfo}>
                                                <div className={styles.iconBlock}>
                                                    <img
                                                        style={{width: '35px'}}
                                                        src="https://cdn.listperfectly.com/app.listperfectly.com/globals/icons/shop_light.svg"
                                                    />
                                                </div>
                                                <div>
                                                    <div className={styles.platformName}>
                                                        {findAndReturnTitleByKey('891.1', customMarketsLocal)}
                                                    </div>
                                                    <div className={styles.idDiv}>
                                                        <span className={styles.idWord}>ID</span>
                                                        {productInfo?.metadata[892] ? (
                                                            <a
                                                                className={styles.aListedOnId}
                                                                href={`${productInfo?.metadata[892]}`}
                                                                target="_blank"
                                                            >
                                                                {productInfo?.metadata[892]}
                                                            </a>
                                                        ) : (
                                                            <div className={styles.noMarketListed}>-</div>
                                                        )}
                                                        {productInfo?.metadata[892] && (
                                                            <>
                                                                {
                                                                    platforms[findAndReturnTitleByKey('891.1', customMarketsLocal)]?.isCopied ?
                                                                        <div className={styles.copiedContent}><CheckedIcon/>
                                                                            <span>Copied</span></div> : (
                                                                            <img
                                                                                style={{cursor: 'pointer'}}
                                                                                src={Vector}
                                                                                alt="copyIcon"
                                                                                onClick={() => {
                                                                                    navigator.clipboard.writeText(
                                                                                        productInfo?.metadata[892],
                                                                                    );
                                                                                    handleUpdateState(findAndReturnTitleByKey('891.1', customMarketsLocal))
                                                                                }
                                                                                }
                                                                            />
                                                                        )
                                                                }
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {(productInfo?.metadata[891.2] && !hasSimple) && (
                                            <div className={styles.platformInfo}>
                                                <div className={styles.iconBlock}>
                                                    <img
                                                        style={{width: '35px'}}
                                                        src="https://cdn.listperfectly.com/app.listperfectly.com/globals/icons/shop_light.svg"
                                                    />
                                                </div>
                                                <div>
                                                    <div className={styles.platformName}>
                                                        {findAndReturnTitleByKey('891.2', customMarketsLocal)}
                                                    </div>
                                                    <div className={styles.idDiv}>
                                                        <span className={styles.idWord}>ID</span>
                                                        {productInfo?.metadata[893] ? (
                                                            <a
                                                                className={styles.aListedOnId}
                                                                href={`${productInfo?.metadata[893]}`}
                                                                target="_blank"
                                                            >
                                                                {productInfo?.metadata[893]}
                                                            </a>
                                                        ) : (
                                                            <div className={styles.noMarketListed}>-</div>
                                                        )}
                                                        {productInfo?.metadata[893] && (
                                                            <>
                                                                {
                                                                    platforms[findAndReturnTitleByKey('891.2', customMarketsLocal)]?.isCopied ?
                                                                        <div className={styles.copiedContent}><CheckedIcon/>
                                                                            <span>Copied</span></div> : (
                                                                            <img
                                                                                style={{cursor: 'pointer'}}
                                                                                src={Vector}
                                                                                alt="copyIcon"
                                                                                onClick={() => {
                                                                                    navigator.clipboard.writeText(
                                                                                        productInfo?.metadata[893],
                                                                                    );
                                                                                    handleUpdateState(findAndReturnTitleByKey('891.2', customMarketsLocal))
                                                                                }
                                                                                }
                                                                            />
                                                                        )
                                                                }
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {(productInfo?.metadata[891.3] && !hasSimple) && (
                                            <div className={styles.platformInfo}>
                                                <div className={styles.iconBlock}>
                                                    <img
                                                        style={{width: '35px'}}
                                                        src="https://cdn.listperfectly.com/app.listperfectly.com/globals/icons/shop_light.svg"
                                                    />
                                                </div>
                                                <div>
                                                    <div className={styles.platformName}>
                                                        {findAndReturnTitleByKey('891.3', customMarketsLocal)}
                                                    </div>
                                                    <div className={styles.idDiv}>
                                                        <span className={styles.idWord}>ID</span>
                                                        {productInfo?.metadata[894] ? (
                                                            <a
                                                                className={styles.aListedOnId}
                                                                href={`${productInfo?.metadata[894]}`}
                                                                target="_blank"
                                                            >
                                                                {productInfo?.metadata[894]}
                                                            </a>
                                                        ) : (
                                                            <div className={styles.noMarketListed}>-</div>
                                                        )}
                                                        {productInfo?.metadata[894] && (
                                                            <>
                                                                {
                                                                    platforms[findAndReturnTitleByKey('891.3', customMarketsLocal)]?.isCopied ?
                                                                        <div className={styles.copiedContent}><CheckedIcon/>
                                                                            <span>Copied</span></div> : (
                                                                            <img
                                                                                style={{cursor: 'pointer'}}
                                                                                src={Vector}
                                                                                alt="copyIcon"
                                                                                onClick={() => {
                                                                                    navigator.clipboard.writeText(
                                                                                        productInfo?.metadata[894],
                                                                                    );
                                                                                    handleUpdateState(findAndReturnTitleByKey('891.3', customMarketsLocal))
                                                                                }
                                                                                }
                                                                            />
                                                                        )
                                                                }
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {(productInfo?.metadata[891.4] && !hasSimple)  && (
                                            <div className={styles.platformInfo}>
                                                <div className={styles.iconBlock}>
                                                    <img
                                                        style={{width: '35px'}}
                                                        src="https://cdn.listperfectly.com/app.listperfectly.com/globals/icons/shop_light.svg"
                                                    />
                                                </div>
                                                <div>
                                                    <div className={styles.platformName}>
                                                        {findAndReturnTitleByKey('891.4', customMarketsLocal)}
                                                    </div>
                                                    <div className={styles.idDiv}>
                                                        <span className={styles.idWord}>ID</span>
                                                        {productInfo?.metadata[895] ? (
                                                            <a
                                                                className={styles.aListedOnId}
                                                                href={`${productInfo?.metadata[895]}`}
                                                                target="_blank"
                                                            >
                                                                {productInfo?.metadata[895]}
                                                            </a>
                                                        ) : (
                                                            <div className={styles.noMarketListed}>-</div>
                                                        )}
                                                        {productInfo?.metadata[895] && (
                                                            <>
                                                                {
                                                                    platforms[findAndReturnTitleByKey('891.4', customMarketsLocal)]?.isCopied ?
                                                                        <div className={styles.copiedContent}><CheckedIcon/>
                                                                            <span>Copied</span></div> : (
                                                                            <img
                                                                                style={{cursor: 'pointer'}}
                                                                                src={Vector}
                                                                                alt="copyIcon"
                                                                                onClick={() => {
                                                                                    navigator.clipboard.writeText(
                                                                                        productInfo?.metadata[895],
                                                                                    );
                                                                                    handleUpdateState(findAndReturnTitleByKey('891.4', customMarketsLocal))
                                                                                }
                                                                                }
                                                                            />
                                                                        )
                                                                }
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {(productInfo?.metadata[891.5] && !hasSimple) && (
                                            <div className={styles.platformInfo}>
                                                <div className={styles.iconBlock}>
                                                    <img
                                                        style={{width: '35px'}}
                                                        src="https://cdn.listperfectly.com/app.listperfectly.com/globals/icons/shop_light.svg"
                                                    />
                                                </div>
                                                <div>
                                                    <div className={styles.platformName}>
                                                        {findAndReturnTitleByKey('891.5', customMarketsLocal)}
                                                    </div>
                                                    <div className={styles.idDiv}>
                                                        <span className={styles.idWord}>ID</span>
                                                        {productInfo?.metadata[896] ? (
                                                            <a
                                                                className={styles.aListedOnId}
                                                                href={`${productInfo?.metadata[896]}`}
                                                                target="_blank"
                                                            >
                                                                {productInfo?.metadata[896]}
                                                            </a>
                                                        ) : (
                                                            <div className={styles.noMarketListed}>-</div>
                                                        )}
                                                        {productInfo?.metadata[896] && (
                                                            <>
                                                                {
                                                                    platforms[findAndReturnTitleByKey('891.5', customMarketsLocal)]?.isCopied ?
                                                                        <div className={styles.copiedContent}><CheckedIcon/>
                                                                            <span>Copied</span></div> : (
                                                                            <img
                                                                                style={{cursor: 'pointer'}}
                                                                                src={Vector}
                                                                                alt="copyIcon"
                                                                                onClick={() => {
                                                                                    navigator.clipboard.writeText(
                                                                                        productInfo?.metadata[896],
                                                                                    );
                                                                                    handleUpdateState(findAndReturnTitleByKey('891.5', customMarketsLocal))
                                                                                }
                                                                                }
                                                                            />
                                                                        )
                                                                }
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {(productInfo?.metadata[891.6] && (!hasSimple && !hasBusiness)) && (
                                            <div className={styles.platformInfo}>
                                                <div className={styles.iconBlock}>
                                                    <img
                                                        style={{width: '35px'}}
                                                        src="https://cdn.listperfectly.com/app.listperfectly.com/globals/icons/shop_light.svg"
                                                    />
                                                </div>
                                                <div>
                                                    <div className={styles.platformName}>
                                                        {findAndReturnTitleByKey('891.6', customMarketsLocal)}
                                                    </div>
                                                    <div className={styles.idDiv}>
                                                        <span className={styles.idWord}>ID</span>
                                                        {productInfo?.metadata[897] ? (
                                                            <a
                                                                className={styles.aListedOnId}
                                                                href={`${productInfo?.metadata[897]}`}
                                                                target="_blank"
                                                            >
                                                                {productInfo?.metadata[897]}
                                                            </a>
                                                        ) : (
                                                            <div className={styles.noMarketListed}>-</div>
                                                        )}
                                                        {productInfo?.metadata[897] && (
                                                            <>
                                                                {
                                                                    platforms[findAndReturnTitleByKey('891.6', customMarketsLocal)]?.isCopied ?
                                                                        <div className={styles.copiedContent}><CheckedIcon/>
                                                                            <span>Copied</span></div> : (
                                                                            <img
                                                                                style={{cursor: 'pointer'}}
                                                                                src={Vector}
                                                                                alt="copyIcon"
                                                                                onClick={() => {
                                                                                    navigator.clipboard.writeText(
                                                                                        productInfo?.metadata[897],
                                                                                    );
                                                                                    handleUpdateState(findAndReturnTitleByKey('891.6', customMarketsLocal))
                                                                                }
                                                                                }
                                                                            />
                                                                        )
                                                                }
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {(productInfo?.metadata[891.7] && (!hasSimple && !hasBusiness)) && (
                                            <div className={styles.platformInfo}>
                                                <div className={styles.iconBlock}>
                                                    <img
                                                        style={{width: '35px'}}
                                                        src="https://cdn.listperfectly.com/app.listperfectly.com/globals/icons/shop_light.svg"
                                                    />
                                                </div>
                                                <div>
                                                    <div className={styles.platformName}>
                                                        {findAndReturnTitleByKey('891.7', customMarketsLocal)}
                                                    </div>
                                                    <div className={styles.idDiv}>
                                                        <span className={styles.idWord}>ID</span>
                                                        {productInfo?.metadata[898] ? (
                                                            <a
                                                                className={styles.aListedOnId}
                                                                href={`${productInfo?.metadata[898]}`}
                                                                target="_blank"
                                                            >
                                                                {productInfo?.metadata[898]}
                                                            </a>
                                                        ) : (
                                                            <div className={styles.noMarketListed}>-</div>
                                                        )}
                                                        {productInfo?.metadata[898] && (
                                                            <>
                                                                {
                                                                    platforms[findAndReturnTitleByKey('891.7', customMarketsLocal)]?.isCopied ?
                                                                        <div className={styles.copiedContent}><CheckedIcon/>
                                                                            <span>Copied</span></div> : (
                                                                            <img
                                                                                style={{cursor: 'pointer'}}
                                                                                src={Vector}
                                                                                alt="copyIcon"
                                                                                onClick={() => {
                                                                                    navigator.clipboard.writeText(
                                                                                        productInfo?.metadata[898],
                                                                                    );
                                                                                    handleUpdateState(findAndReturnTitleByKey('891.7', customMarketsLocal))
                                                                                }
                                                                                }
                                                                            />
                                                                        )
                                                                }
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {(productInfo?.metadata[891.8] && (!hasSimple && !hasBusiness)) && (
                                            <div className={styles.platformInfo}>
                                                <div className={styles.iconBlock}>
                                                    <img
                                                        style={{width: '35px'}}
                                                        src="https://cdn.listperfectly.com/app.listperfectly.com/globals/icons/shop_light.svg"
                                                    />
                                                </div>
                                                <div>
                                                    <div className={styles.platformName}>
                                                        {findAndReturnTitleByKey('891.8', customMarketsLocal)}
                                                    </div>
                                                    <div className={styles.idDiv}>
                                                        <span className={styles.idWord}>ID</span>
                                                        {productInfo?.metadata[899] ? (
                                                            <a
                                                                className={styles.aListedOnId}
                                                                href={`${productInfo?.metadata[899]}`}
                                                                target="_blank"
                                                            >
                                                                {productInfo?.metadata[899]}
                                                            </a>
                                                        ) : (
                                                            <div className={styles.noMarketListed}>-</div>
                                                        )}
                                                        {productInfo?.metadata[899] && (
                                                            <>
                                                                {
                                                                    platforms[findAndReturnTitleByKey('891.8', customMarketsLocal)]?.isCopied ?
                                                                        <div className={styles.copiedContent}><CheckedIcon/>
                                                                            <span>Copied</span></div> : (
                                                                            <img
                                                                                style={{cursor: 'pointer'}}
                                                                                src={Vector}
                                                                                alt="copyIcon"
                                                                                onClick={() => {
                                                                                    navigator.clipboard.writeText(
                                                                                        productInfo?.metadata[899],
                                                                                    );
                                                                                    handleUpdateState(findAndReturnTitleByKey('891.8', customMarketsLocal))
                                                                                }
                                                                                }
                                                                            />
                                                                        )
                                                                }
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {(productInfo?.metadata[891.9] && (!hasSimple && !hasBusiness)) && (
                                            <div className={styles.platformInfo}>
                                                <div className={styles.iconBlock}>
                                                    <img
                                                        style={{width: '35px'}}
                                                        src="https://cdn.listperfectly.com/app.listperfectly.com/globals/icons/shop_light.svg"
                                                    />
                                                </div>
                                                <div>
                                                    <div className={styles.platformName}>
                                                        {findAndReturnTitleByKey('891.9', customMarketsLocal)}
                                                    </div>
                                                    <div className={styles.idDiv}>
                                                        <span className={styles.idWord}>ID</span>
                                                        {productInfo?.metadata[900] ? (
                                                            <a
                                                                className={styles.aListedOnId}
                                                                href={`${productInfo?.metadata[900]}`}
                                                                target="_blank"
                                                            >
                                                                {productInfo?.metadata[900]}
                                                            </a>
                                                        ) : (
                                                            <div className={styles.noMarketListed}>-</div>
                                                        )}
                                                        {productInfo?.metadata[900] && (
                                                            <>
                                                                {
                                                                    platforms[findAndReturnTitleByKey('891.9', customMarketsLocal)]?.isCopied ?
                                                                        <div className={styles.copiedContent}><CheckedIcon/>
                                                                            <span>Copied</span></div> : (
                                                                            <img
                                                                                style={{cursor: 'pointer'}}
                                                                                src={Vector}
                                                                                alt="copyIcon"
                                                                                onClick={() => {
                                                                                    navigator.clipboard.writeText(
                                                                                        productInfo?.metadata[900],
                                                                                    );
                                                                                    handleUpdateState(findAndReturnTitleByKey('891.9', customMarketsLocal))
                                                                                }
                                                                                }
                                                                            />
                                                                        )
                                                                }
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {(productInfo?.metadata[891.10] && (!hasSimple && !hasBusiness)) && (
                                            <div className={styles.platformInfo}>
                                                <div className={styles.iconBlock}>
                                                    <img
                                                        style={{width: '35px'}}
                                                        src="https://cdn.listperfectly.com/app.listperfectly.com/globals/icons/shop_light.svg"
                                                    />
                                                </div>
                                                <div>
                                                    <div className={styles.platformName}>
                                                        {findAndReturnTitleByKey('891.10', customMarketsLocal)}
                                                    </div>
                                                    <div className={styles.idDiv}>
                                                        <span className={styles.idWord}>ID</span>
                                                        {productInfo?.metadata[901] ? (
                                                            <a
                                                                className={styles.aListedOnId}
                                                                href={`${productInfo?.metadata[901]}`}
                                                                target="_blank"
                                                            >
                                                                {productInfo?.metadata[901]}
                                                            </a>
                                                        ) : (
                                                            <div className={styles.noMarketListed}>-</div>
                                                        )}
                                                        {productInfo?.metadata[901] && (
                                                            <>
                                                                {
                                                                    platforms[findAndReturnTitleByKey('891.10', customMarketsLocal)]?.isCopied ?
                                                                        <div className={styles.copiedContent}><CheckedIcon/>
                                                                            <span>Copied</span></div> : (
                                                                            <img
                                                                                style={{cursor: 'pointer'}}
                                                                                src={Vector}
                                                                                alt="copyIcon"
                                                                                onClick={() => {
                                                                                    navigator.clipboard.writeText(
                                                                                        productInfo?.metadata[901],
                                                                                    );
                                                                                    handleUpdateState(findAndReturnTitleByKey('891.10', customMarketsLocal))
                                                                                }
                                                                                }
                                                                            />
                                                                        )
                                                                }
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    {
                                     !hasSimple && <div className={styles.itemDetails}>
                                         <div className={styles.fieldTitle}>Item Details</div>
                                         <div className={styles.itemCondition}>
                                             <div className={styles.itemDetailsLeftRight}>

                                                 {productInfo?.metadata[206] ? (
                                                  <div className={styles.objectNameValueDiv}>
                                                      <div className={styles.itemDetailsObjectKeyName}>Brand</div>
                                                      <div className={styles.itemDetailsObjectValue}>
                                                          {bdecode(productInfo?.metadata[206])}
                                                      </div>
                                                  </div>
                                                 ) : ''
                                                 }


                                                 {productInfo?.metadata[213] ? (
                                                  <div className={styles.objectNameValueDiv}>
                                                      <div className={styles.itemDetailsObjectKeyName}>Size</div>
                                                      <div className={styles.itemDetailsObjectValue}>
                                                          {bdecode(productInfo?.metadata[213])}
                                                      </div>
                                                  </div>
                                                 ) : ''
                                                 }


                                                 {productInfo?.metadata[209] ? (
                                                  <div className={styles.objectNameValueDiv}>
                                                      <div className={styles.itemDetailsObjectKeyName}>Color</div>
                                                      <div className={styles.itemDetailsObjectValue}>
                                                          {bdecode(productInfo?.metadata[209])}
                                                      </div>
                                                  </div>
                                                 ) : ''
                                                 }


                                                 {productInfo?.metadata[82] ? (
                                                  <div className={styles.objectNameValueDiv}>
                                                      <div className={styles.itemDetailsObjectKeyName}>
                                                          Material
                                                      </div>
                                                      <div className={styles.itemDetailsObjectValue}>
                                                          {bdecode(productInfo?.metadata[82])}
                                                      </div>
                                                  </div>
                                                 ) : ''}


                                                 {productInfo?.metadata[4] ? (
                                                  <div className={styles.objectNameValueDiv}>
                                                      <div className={styles.itemDetailsObjectKeyName}>
                                                          Intended For
                                                      </div>
                                                      <div className={styles.itemDetailsObjectValue}>
                                                          {bdecode(productInfo?.metadata[4])}
                                                      </div>
                                                  </div>
                                                 ) : ''
                                                 }


                                                 {productInfo?.metadata[205] ? (
                                                  <div className={styles.objectNameValueDiv}>
                                                      <div className={styles.itemDetailsObjectKeyName}>Style</div>
                                                      <div className={styles.itemDetailsObjectValue}>
                                                          {bdecode(productInfo?.metadata[205])}
                                                      </div>
                                                  </div>
                                                 ) : ''
                                                 }

                                             </div>

                                             <div className={styles.itemDetailsLeftRight}>

                                                 {productInfo?.metadata[15] ? (
                                                  <div className={styles.objectNameValueDiv}>
                                                      <div className={styles.itemDetailsObjectKeyName}>
                                                          Condition
                                                      </div>
                                                      <div className={styles.itemDetailsObjectValue}>
                                                          {bdecode(productInfo?.metadata[15])}
                                                      </div>
                                                  </div>
                                                 ) : ''
                                                 }
                                                 {productInfo?.metadata[17] ? (
                                                  <div>
                                                      <div className={styles.itemDetailsObjectKeyName}>
                                                          Condition Notes
                                                      </div>
                                                      <div className={styles.itemDetailsObjectValue}>
                                                          {bdecode(productInfo?.metadata[17])}
                                                      </div>
                                                  </div>
                                                 ) : ''}


                                                 {productInfo?.metadata[453] ? (
                                                  <div>
                                                      <div className={styles.itemDetailsObjectKeyName}>
                                                          Keywords/Tags
                                                      </div>
                                                      <div className={styles.keywordDiv}>
                                                          <ul className={styles.keywordsWrapper}>
                                                              {spanKeywords?.map((item: any, index: number) => (
                                                               <li key={index}>
                                                                   <span>{item ? bdecode(item).trim() : ''}</span>
                                                               </li>
                                                              ))}
                                                          </ul>
                                                      </div>
                                                  </div>
                                                 ) : ''}
                                             </div>

                                         </div>
                                         <div className={styles.itemValues}>

                                             {productInfo?.metadata[847] ? (
                                              <div>
                                                  <div className={styles.itemDetailsObjectKeyName}>COGS</div>
                                                  <div className={styles.objectValueText}>
                                                      {formatter.format(productInfo?.metadata[847])}
                                                  </div>
                                              </div>
                                             ) : ''}


                                             {productInfo?.metadata[104] ? (
                                              <div>
                                                  <div className={styles.itemDetailsObjectKeyName}>MSRP</div>
                                                  <div className={styles.objectValueText}>
                                                      {formatter.format(productInfo?.metadata[104])}
                                                  </div>
                                              </div>
                                             ) : ''
                                             }


                                             {productInfo?.metadata[704] ? (
                                              <div>
                                                  <div className={styles.itemDetailsObjectKeyName}>UPC</div>

                                                  <div className={styles.objectValueText}>
                                                      {productInfo?.metadata[704]}
                                                  </div>
                                              </div>
                                             ) : ''}

                                         </div>
                                     </div>
                                    }
                                    {
                                     !hasSimple && <div className={styles.platformTitles}>
                                         <div className={styles.fieldTitle}> Platform Titles</div>
                                         {
                                             productInfo?.metadata[791] ? (
                                              <>
                                                  <div className={styles.platformTitleObjectKeyName}>eBay Title</div>
                                                  <div>
                                                      <div className={styles.platformTitleText}>
                                                          {bdecode(productInfo?.metadata[791])}
                                                      </div>
                                                  </div>
                                              </>
                                             ) : ''
                                         }
                                         {
                                             productInfo?.metadata[789] ? (
                                              <>
                                                  <div className={styles.platformTitleObjectKeyName}>
                                                      Mercari Title
                                                  </div>
                                                  <div>
                                                      <div className={styles.platformTitleText}>
                                                          {bdecode(productInfo?.metadata[789])}
                                                      </div>
                                                  </div>
                                              </>
                                             ) : ''
                                         }
                                         {
                                             productInfo?.metadata[788] ? (
                                              <>
                                                  <div className={styles.platformTitleObjectKeyName}>Grailed Title</div>
                                                  <div>
                                                      <div className={styles.platformTitleText}>
                                                          {bdecode(productInfo?.metadata[788])}
                                                      </div>
                                                  </div>
                                              </>
                                             ) : ''
                                         }

                                         {
                                             productInfo?.metadata[786] ? (
                                              <>
                                                  <div className={styles.platformTitleObjectKeyName}>
                                                      Poshmark Title
                                                  </div>
                                                  <div>
                                                      <div className={styles.platformTitleText}>
                                                          {bdecode(productInfo?.metadata[786])}
                                                      </div>
                                                  </div>
                                              </>
                                             ) : ''
                                         }
                                         {
                                             productInfo?.metadata[869] ? (
                                              <>
                                                  <div className={styles.platformTitleObjectKeyName}>
                                                      Facebook Title
                                                  </div>
                                                  <div>
                                                      <div className={styles.platformTitleText}>
                                                          {bdecode(productInfo?.metadata[869])}
                                                      </div>
                                                  </div>
                                              </>
                                             ) : ''
                                         }

                                     </div>
                                    }
                                    <div className={styles.shipDescription}>
                                        {
                                         (!hasSimple && !hasBusiness) && <>
                                             <div className={styles.fieldTitle}>Shipping</div>
                                             <div className={styles.shipping}>

                                                 {
                                                     productInfo?.metadata[837] ? (
                                                      <div className={styles.objectNameValueDiv}>
                                                          <div className={styles.shippingObjectKeyName}>
                                                              Shipping Zip Code
                                                          </div>
                                                          <div className={styles.shippingObjectValue}>
                                                              {bdecode(productInfo?.metadata[837])}
                                                          </div>
                                                      </div>
                                                     ) : ''
                                                 }
                                                 {
                                                     productInfo?.metadata[2] || productInfo?.metadata[3] ? (
                                                      <div className={styles.objectNameValueDiv}>
                                                          <div className={styles.shippingObjectKeyName}>
                                                              Package Weight
                                                          </div>
                                                          <div className={styles.packageProperties}>
                                                              {productInfo?.metadata[2] ? (
                                                               <div className={styles.packageObjectValue}>
                                                                   {productInfo?.metadata[2]}{' '}
                                                                   <span className={styles.packageMeausure}>lbs</span>
                                                               </div>
                                                              ) : ''}
                                                              {productInfo?.metadata[3] ? (
                                                               <div className={styles.packageObjectValue}>
                                                                   {productInfo?.metadata[3]}{' '}
                                                                   <span className={styles.packageMeausure}>oz</span>
                                                               </div>
                                                              ) : ''}
                                                          </div>
                                                      </div>
                                                     ) : ''
                                                 }

                                                 {
                                                     productInfo?.metadata[531] || productInfo?.metadata[532] || productInfo?.metadata[533] ?
                                                      (
                                                       <div className={styles.objectNameValueDiv}>
                                                           <div className={styles.shippingObjectKeyName}>
                                                               Package Dimensions
                                                           </div>
                                                           <div className={styles.packageProperties}>
                                                               {productInfo?.metadata[531] ? (
                                                                <div className={styles.packageObjectValue}>
                                                                    {productInfo?.metadata[531]}
                                                                    <span className={styles.packageMeausure}>L</span>
                                                                </div>
                                                               ) : ''}
                                                               {productInfo?.metadata[532] ? (
                                                                <div className={styles.packageObjectValue}>
                                                                    {productInfo?.metadata[532]}
                                                                    <span className={styles.packageMeausure}>W</span>
                                                                </div>
                                                               ) : ''}

                                                               {productInfo?.metadata[533] ? (
                                                                <div className={styles.packageObjectValue}>
                                                                {productInfo?.metadata[533]}
                                                                    <span className={styles.packageMeausure}>H</span>
                                                                </div>
                                                               ) : ''}
                                                           </div>
                                                       </div>
                                                      ) : ''
                                                 }

                                             </div>
                                         </>
                                        }
                                        <div className={styles.description}>
                                            <div className={styles.fieldTitle}>Description</div>
                                            {productInfo?.metadata[descriptionKey] ? (
                                             <div
                                              className={styles.descriptionText}
                                              style={{whiteSpace: 'pre-line'}}
                                              dangerouslySetInnerHTML={{__html: bdecode(productInfo?.metadata[descriptionKey])}}
                                             />
                                            ) : ''}
                                        </div>
                                    </div>
                                </div>
                                <div className={`${styles.ordersFooters}`}>
                                    <div className={styles.globalFooters}>
                                        {
                                         (!hasSimple && !hasBusiness) &&
                                         <>
                                             <div className={styles.fieldTitle}>Global Footers</div>
                                             {renderFooterItem('mepr_mercari_description', 'Mercari Footer')}
                                             {renderFooterItem('mepr_poshmark_description', 'Poshmark Footer')}
                                             {renderFooterItem('mepr_ebay_description', 'eBay Footer')}
                                             {renderFooterItem('mepr_etsy_description', 'Etsy Footer')}
                                             {renderFooterItem('mepr_depop_description', 'Depop Footer')}
                                             {renderFooterItem(
                                              'mepr_facebookmarketplace_description',
                                              'Facebook Footer',
                                             )}
                                             {renderFooterItem(
                                              'mepr_instagram_description',
                                              'Instagram Footer',
                                             )}
                                             {renderFooterItem('mepr_grailed_description', 'Grailed Footer')}
                                             {renderFooterItem('mepr_kidizen_description', 'Kidizen Footer')}
                                             {renderFooterItem('mepr_shopify_description', 'Shopify Footer')}
                                             {renderFooterItem(
                                              'mepr_vestiaire_description',
                                              'Vestiaire Footer',
                                             )}</>
                                        }
                                        <div className={styles.lpIdContainer}>
                                            <p className={styles.lpHeading}>LP ID</p>
                                            <p className={styles.lpId}>{listViewId}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    );
};

export default SingleProductView;