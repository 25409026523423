import React, {useState} from 'react' ;
import styles from './SkuLocations.module.scss'
import SkuLocationItem from "./components/SkuLocationItem";
import { QuestionIcon} from "../../../../assets/icons";
import Tippy from "@tippyjs/react";
import {useSelector} from "react-redux";

interface ISkuLocations {
	skuLocations:any;
	handleChangeSkuLocation:(index:number,event:any) => void;
}

const SkuLocations = (props:ISkuLocations) => {
	const isMobile = useSelector((state: any) => state.navigation.isMobile);
	const {skuLocations,handleChangeSkuLocation}  = props ;

	const [isClickedQuestionIcon,setIsClickedQuestionIcon] = useState(false)

	return (
	 <div className={styles.skuGenerator}>
		 <div className={styles.titleBlock}>
			 <div className={styles.title}>SKU Locations</div>
			 <Tippy
			  interactive={true}
			  zIndex={5}
			  arrow={false}
			  trigger="focus click"
			  content={
				  <p>
					  These fields are used to define the storage location of your items. Enter generic location details such as aisle, shelf, bin, or any other relevant identifiers. The specific number will be set in the individual listing in the "Location Number". This structured approach helps in quickly identifying and locating items in your inventory.
				  </p>
			  }
			  placement={isMobile ? 'bottom-end' : 'right-end'}
			  className={styles.toolTip}
			  onClickOutside={() => setIsClickedQuestionIcon(false)}
			 >
				 <div
				 >
					 <QuestionIcon
					  className={styles['questionIcon']}
					  // className={`${styles.questionSvg} ${isClickedQuestionIcon ? styles.isClickedQuestionClass : ""}`}
					  onClick={() => setIsClickedQuestionIcon(!isClickedQuestionIcon)}
					  style={{
						  cursor:'pointer'
					  }}
					 />
				 </div>
			 </Tippy>
		 </div>
		 <div className={styles.skuLocationsWrapper}>
			 {skuLocations.map((skuLocation:any,index:number) => (
			  <SkuLocationItem
			   key={skuLocation.id}
			   title={skuLocation.title}
			   value={skuLocation.value}
			   index={index}
			   payloadKey={skuLocation.payloadKey}
			   handleChangeSkuLocation={handleChangeSkuLocation}
			  />
			 ))}
		 </div>
	 </div>
	)
}

export default SkuLocations ;