import React, {useEffect, useState} from 'react';
import styles from './ListingAssistantModal.module.scss'
import {MoonLoader} from "react-spinners";
import {CloseModal, Lightbulb, PopoverIcon} from "../../../assets/icons";
import {instanceAxios} from "../../../services";
import {toast} from "react-toastify";
import Listings from '../../../services/Listings'
import getToken from "../../../utils/getToken";
import _ from "lodash";
import jwt_decode from "jwt-decode";
import CreatableSelect from 'react-select/creatable';
import Tippy from "@tippyjs/react";
import useBasisFuncs from "@hooks/useBasisFuncs";

export const ListingAssistantVision = (
    {
        configID,
        setShowListingAssistantModal,
        imagesFromUploader,
        listingAssistantTone,
        setListingAssistantTone,
        listingAssistantContext,
        setShowRegenerate,
        setListingAssistantContext,
        setRegenerateAttempsLeftCount,
        assistantTitle,
        setRegenerateId,
        regenerateId,
        regenerateAttempsLeftCount,
        regenerateTotal,
        setRegenerateTotal,
        setGeneratedModal,
        setCommonState,
        isModal,
        quotasLimit,
    }: any) => {
    const [listingAssistantLoader, setListingAssistantLoader] = useState(false);
    const {bdecode} = useBasisFuncs();
    const [enableLaunchAssistant, setEnableLaunchAssistant] = useState(true);
    const [selectedOption, setSelectedOption] = useState<any>(listingAssistantTone ? {value: listingAssistantTone, label: listingAssistantTone} : null);
    const [options, setOptions] = useState([
        { value: 'Slightly funny', label: 'Slightly funny' },
        { value: 'Very funny', label: 'Very funny' },
        { value: 'Storyteller', label: 'Storyteller' },
        { value: 'Verbose', label: 'Verbose' },
        { value: 'Professional', label: 'Professional' },
        { value: 'Concise', label: 'Concise' },
        { value: 'Just the facts', label: 'Just the facts' },
        { value: 'Antique or Vintage', label: 'Antique or Vintage' },
        { value: 'Sports or Athletics', label: 'Sports or Athletics' },
        { value: 'Bookworm', label: 'Bookworm' },
        { value: 'Persuasive', label: 'Persuasive' },
        { value: '', label: 'Add your own' }
    ]);
    const [preventFirstRender, setPreventFirstRender] = useState(false);
    const [availableLoader, setAvailableLoader] = useState(true)
    const token:any = getToken();
    const decodedToken: any = jwt_decode(token);
    const roles = decodedToken?.data?.user?.roles;


    const hasBusiness = roles
        .map((role: string) => role.toLowerCase())
        .includes('Business'.toLowerCase());
    const hasSimple = roles
        .map((role: string) => role.toLowerCase())
        .includes('simple'.toLowerCase());


    const customStyles = {
        input: (provided:any) => ({
            ...provided,
            cursor: 'pointer',
        }),
        clearIndicator: (provided:any) => ({
            ...provided,
            cursor: 'pointer',
        }),
        menuList: (provided:any) => ({
            ...provided,
            maxHeight: '200px',
            overflow:'auto'
        }),
    };

    const handleUpdateTone = async(value :any) => {
        setEnableLaunchAssistant(false);
        try {
            const payload = {
                filter_by: { id: configID },
                data: {
                    config: {
                        ['listing_assistant_tone'] : value
                    },
                },
            };
            const response = await instanceAxios.put(
                '/user-configs/website/update',
                payload
            );
            setListingAssistantTone(value)
        }
        catch (e) {
            console.log(e, 'error')
        }
        finally {
            setEnableLaunchAssistant(true);
        }
    }

    const moveLongTagsToLast = (keywordsString: string) => {
        try {
            const resultArray = keywordsString?.split(', ').map((item) => item.trim());
            // Move items with more than 20 characters to the end
            const movedItems = resultArray.filter((item) => item.length > 20);
            const remainingItems = resultArray.filter((item) => item.length <= 20);
            const finalResultArray = [...remainingItems, ...movedItems];

            // Convert the array to a string
            const finalResultString = finalResultArray.join(', ');
            return finalResultString;
        }
        catch (e) {
            console.log(e)
        }
    };


    const handleContextChange = (e:any) =>{
        setListingAssistantContext(e.target?.value);
    }

    const handleLaunchClicked = async()=>{
        setListingAssistantLoader(true);
        try {
            if(imagesFromUploader.length){
                const changeImagesPrefix = imagesFromUploader.map((url:any) =>
                    url.includes('https://s3.amazonaws.com/app1.listperfectly') ?
                        url.replace('https://s3.amazonaws.com/app1.listperfectly', 'https://media.listperfectly.com') :
                        url
                );
                const valueOfTone = selectedOption?.value ? selectedOption?.value : "";
                const payload: any = {
                    133: 'Journey Through Time: Mysteries of Ancient Civilizations',
                    image_urls : changeImagesPrefix,
                }
                if(valueOfTone) {
                    payload.tone = valueOfTone;
                }
                if(listingAssistantContext) {
                    payload.additional_context = listingAssistantContext;
                }
                if (assistantTitle === 'regenerate') {
                    payload.regeneration_id = regenerateId;
                }

                const options = {
                    version: 4,
                }

                const response : any = await Listings.tools_generator_add(token,  payload, options);

                if(response){
                    const fieldMapping:any = {
                        133: 'title',
                        505: 'description',
                        209: !hasSimple ? 'color' : null,
                        82: !hasSimple ? 'material' : null,
                        213: !hasSimple ? 'size' : null,
                        206: !hasSimple ? 'brand' : null,
                        704: !hasSimple ? 'upc' : null,
                        15: (!hasSimple && !hasBusiness) ? 'condition' : null,
                        205: (!hasSimple && !hasBusiness) ? 'style' : null,
                        211: !hasSimple ? 'madeIn' : null,
                        2: (!hasSimple && !hasBusiness) ? 'shippingWeightlbkg' : null,
                        3: (!hasSimple && !hasBusiness) ? 'shippingWeightOzg' : null,
                        795: (!hasSimple && !hasBusiness) ? 'subCondition' : null,
                        796: (!hasSimple && !hasBusiness) ? 'preOwnedCondition' : null,
                        17: 'conditionNotes',
                        210: (!hasSimple && !hasBusiness) ? 'patternOrTexture' : null,
                        43: (!hasSimple && !hasBusiness) ? 'care' : null,
                        36: (!hasSimple && !hasBusiness) ? 'seasonOrWeather' : null
                    };
                    // Filter out null values from the fieldMapping
                    const filteredFieldMapping:any = Object.fromEntries(
                        Object.entries(fieldMapping).filter(([key, value]) => value !== null)
                    );
                    Object.entries(filteredFieldMapping).forEach(([key, field]:any) => {
                        if (response?.data && _.has(response.data, key)) {
                            setCommonState((prevState: any) => ({
                                ...prevState,
                                [field]: response.data[key],
                            }));
                        }
                    });
                    if (_.has(response?.data, 453) && !hasSimple) {
                        const keywordsString = response.data[453];
                        const modifiedString = moveLongTagsToLast(keywordsString);
                        setCommonState((prevState: any) => ({
                            ...prevState,
                            keywords: modifiedString,
                        }));
                    }
                    setListingAssistantTone(valueOfTone)
                    setGeneratedModal(true)
                    setRegenerateAttempsLeftCount(response?.regeneration_quota?.available);
                        setRegenerateTotal(response?.regeneration_quota?.total);
                        if(response?.data?.regeneration_id) {
                            setRegenerateId(response?.data?.regeneration_id);
                        }
                }

                toast.success(`Details generated`, {
                    autoClose: 2000,
                    position: 'bottom-right', // You can customize the notification position
                });
                setShowListingAssistantModal(false);
                setShowRegenerate(true);
            }
            else {
                toast.warning(`Please upload some images for generate details`, {
                    position: 'bottom-right', // You can customize the notification position
                });
            }
        }
        catch (e) {
            toast.error(`Request failed`, {
                position: 'bottom-right', // You can customize the notification position
            });
            console.log('error', e)
        }
        finally {
            setListingAssistantLoader(false);
        }
    }
    const handleCloseAssistantModal = () => {
        setShowListingAssistantModal(false);
    }

    const handleInputChange = async(newValue: any, actionMeta: any) => {
        try {
            if (actionMeta.action === 'clear' || newValue.value === '') {
                setSelectedOption(null);
            } else if (newValue && typeof newValue === 'object' && newValue.value) {
                setSelectedOption(newValue);
            } else if (typeof newValue === 'string') {
                const strippedValue = newValue.replace(/\s/g, ''); // Remove spaces
                if (strippedValue.length <= 20) {
                    setSelectedOption({ value: newValue, label: newValue });
                }
            }
        } catch (error) {
            console.error('Error handling input change:', error);
        }
    };

    useEffect(() => {
        setPreventFirstRender(true);
        if(preventFirstRender) {
            const valueOfTone = selectedOption?.value ? selectedOption?.value : "";
            handleUpdateTone(valueOfTone)
        }
    }, [selectedOption]);

    useEffect(() => {
        document.body.classList.add('overflow-hidden');
        return () => {
            document.body.classList.remove('overflow-hidden');
        }
    }, []);

    return (
        <div className={`${styles.listingAssistantModalContainer} ${isModal ? styles.isModal : ''}`} style={{width: `100vw`}}>
            <div className={styles.listingAssistantModal}>
                <div className={styles.closeModal} onClick={handleCloseAssistantModal}>
                    <CloseModal height={20} width={20}/>
                </div>
                {
                    listingAssistantLoader ? <div className={styles.loadercontainerOverlay}><MoonLoader/>
                            Generating details ...</div> :
                        (
                            <>

                                <p className={styles.notice}>{assistantTitle === 'assistant' ?  <div className={styles.listingAssistantTitle}><Lightbulb /> <span>Listing Assistant Vision</span></div> : `Regenerate all listing details`}</p>
                                <div className={styles.assistantInnerContainer}>
                                    {
                                        assistantTitle === 'assistant' ?
                                            <>
                                                <p className={styles.description}>
                                                    Listing Assistant uses the first six images to generate your listing. For the best
                                                    results, images can include:
                                                </p>
                                                <ul className={styles.suggestedList}>
                                                    <li>Labels</li>
                                                    <li>Brand</li>
                                                    <li>Size</li>
                                                </ul>
                                            </>
                                            :
                                            <>
                                                <p className={styles.description} style={{paddingBottom:'10px'}}>
                                                    Use if the initial details had errors or you want to try a different tone.
                                                    Moving or changing images will have no effect on regeneration. <br/> If you need
                                                    details generated based on adjusted photos, use Listing Assistant option instead.
                                                </p>
                                            </>
                                    }
                                    {
                                        !enableLaunchAssistant &&
                                        <div style={{display: 'flex', alignItems: 'center', gap: '10px', marginTop: '10px'}}><MoonLoader
                                            size={20}/>Saving tone...</div>
                                    }
                                    <div>
                                        <div className={styles.toneTitleContainer}>
                                            <p className={styles.headingTitle}>Choose tone <Tippy
                                                interactive={true}
                                                arrow={false}
                                                zIndex={9999}
                                                trigger="click"
                                                content={
                                                    <div className={`popover ${styles.popoverContent}`}>
                                                        Add or choose the tone for your listing to match the style and voice you want to convey. Whether it’s professional, friendly, or playful, selecting the right tone helps engage your target audience.
                                                    </div>
                                                }
                                            >
                                                <PopoverIcon
                                                    className={styles.popoverIcon}
                                                    color={'#fff'}
                                                ></PopoverIcon>
                                            </Tippy></p>
                                            <p style={{color: '#767676', fontStyle: 'italic', paddingTop: '5px'}}>Max
                                                character
                                                limit is 20</p>
                                        </div>
                                        <CreatableSelect
                                            placeholder={"Select a tone or add your own"}
                                            styles={customStyles}
                                            value={selectedOption}
                                            options={options}
                                            onChange={handleInputChange}
                                            isClearable
                                            isValidNewOption={(inputValue, selectValue, selectOptions) =>
                                                inputValue.trim().length > 0 && inputValue.trim().length <= 20
                                            }
                                        />
                                        <div className={styles.contextTitleContainer}>
                                            <p className={styles.headingTitle}>Add details <Tippy
                                                interactive={true}
                                                arrow={false}
                                                zIndex={9999}
                                                trigger="click"
                                                content={
                                                    <div className={`popover ${styles.popoverContent}`}>
                                                        Provide additional context or details that will help tailor the listing to your specific needs. This can include special features, unique selling points, or any other relevant information to enhance your listing.
                                                    </div>
                                                }
                                            >
                                                <PopoverIcon
                                                    className={styles.popoverIcon}
                                                    color={'#fff'}
                                                ></PopoverIcon>
                                            </Tippy></p>
                                            <p style={{color: '#767676', fontStyle: 'italic', paddingTop: '5px'}}>Max
                                                character
                                                limit is 200 </p>
                                        </div>
                                        <textarea
                                            className={styles.assistantTextarea}
                                            placeholder={''}
                                            value={bdecode(listingAssistantContext)}
                                            maxLength={200}
                                            onChange={(e) => handleContextChange(e)}
                                        ></textarea>
                                    </div>
                                    {assistantTitle === 'assistant' &&
                                        <p className={styles.description}>Ensure these images accurately represent your item for the
                                            best results! Review for accuracy. </p>}
                                    {
                                        assistantTitle === 'assistant' ?
                                           <></> :
                                            <div className={styles.available}>
                                                {`${regenerateAttempsLeftCount} of ${regenerateTotal} available`}
                                            </div>
                                    }
                                </div>
                                {
                                    assistantTitle === 'assistant' ?
                                        quotasLimit?.available ?
                                            <div onClick={()=>{
                                                if(enableLaunchAssistant){
                                                    handleLaunchClicked();
                                                }
                                            }}
                                                 className={`${styles.launchButton} ${imagesFromUploader?.length && enableLaunchAssistant ? '' : styles.disabledLaunchButton}`}
                                            >Launch Listing Assistant
                                            </div> : null
                                        :
                                        <div onClick={handleLaunchClicked}
                                             className={`${styles.launchButton} ${imagesFromUploader?.length ? '' : styles.disabledLaunchButton}`}> Generate
                                        </div>
                                }
                            </>
                        )
                }
            </div>
        </div>
    );
};