import React, {useEffect, useRef} from 'react';

import styles from './PlatformsModal.module.scss';

import PlatformModalRow from './components/PlatformModalRow';
import {ITableData} from '../../../../interfaces';
import {CloseSvg} from '../../../../assets/icons';
import Button, {ModifierType} from "../../../../components/UI/Button";

interface IPlatformsModalProps {
  handleTogglePlatformsModalShow: () => void;
  handleClick?: (platformName: string) => void;
  lpPlatforms?: any;
  setLpPlatforms?: (platforms: any) => void;
  main: boolean;
  unescapedRowMetadata?: any;
  activePlatforms?: string[];
  tableData?: ITableData[];
  setTableData?: (tableData: ITableData[]) => void;
  index?: number;
  setChangedPatforms?: (value: any) => void;
  customMarketplaces?: any;
  saveActive?:any;
  setSaveActive?:any;
  setRetryActive?:any;
  retryActive?:any;
}

const PlatformsModal = (props: IPlatformsModalProps) => {
  const {
    handleTogglePlatformsModalShow,
    handleClick,
    lpPlatforms,
    main,
    unescapedRowMetadata,
    setLpPlatforms,
    tableData,
    index,
    setTableData,
    setChangedPatforms,
    customMarketplaces,
    saveActive,
    setSaveActive,
    setRetryActive,
    retryActive
  } = props;

  const plartformModalRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      if (
        plartformModalRef.current &&
        !plartformModalRef.current.contains(event.target as Node)
      ) {
        handleTogglePlatformsModalShow();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [plartformModalRef, handleTogglePlatformsModalShow]);

  const handleModalScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const { scrollTop, scrollHeight, clientHeight } =
      plartformModalRef.current!;

    if (
      ((e.nativeEvent as WheelEvent).deltaY &&
        (e.nativeEvent as WheelEvent).deltaY < 0 &&
        scrollTop === 0) ||
      ((e.nativeEvent as WheelEvent).deltaY > 0 &&
        scrollTop + clientHeight >= scrollHeight)
    ) {
      e.preventDefault();
    }
  };


  return (
    <div className={styles.platformsModal__overlay}>
      <div
        className={styles.modal}
        onClick={(e) => e.stopPropagation()}
        onScroll={handleModalScroll}
        ref={plartformModalRef}
      >
        <div className={styles.close__block}>
          {main ? (
           <h1>Platforms</h1>
          ) : (
           <div className={styles.platformsTitleWrapper}>
             <h1>Platforms</h1>
             <h1>Platform ID</h1>
           </div>
          )}
          <Button
           style={{fontSize:"2em",color:"#393838"}}
           modifier={ModifierType.Transparent}
           handleClick={handleTogglePlatformsModalShow}>
            <CloseSvg />
          </Button>
        </div>
        <div className={styles.platformsModal__wrapper}>
          {lpPlatforms &&
            lpPlatforms.map((item: any) => (
              <PlatformModalRow
                key={item?.id}
                item={item}
                handleClick={handleClick}
                main={main}
                unescapedRowMetadata={unescapedRowMetadata}
                tableData={tableData}
                setTableData={setTableData}
                index={index}
                setChangedPatforms={setChangedPatforms}
                isCustom={false}
                saveActive={saveActive}
                setSaveActive={setSaveActive}
                setRetryActive={setRetryActive}
                retryActive={retryActive}
              />
            ))}
          {customMarketplaces?.length > 0 &&
              customMarketplaces?.map((item: any) =>
                  item?.platform?.title?.length > 0 ? (
                      <PlatformModalRow
                          key={item?.id}
                          item={item}
                          handleClick={handleClick}
                          main={main}
                          unescapedRowMetadata={unescapedRowMetadata}
                          tableData={tableData}
                          setTableData={setTableData}
                          index={index}
                          setChangedPatforms={setChangedPatforms}
                          isCustom={true}
                          saveActive={saveActive}
                          setSaveActive={setSaveActive}
                          setRetryActive={setRetryActive}
                          retryActive={retryActive}
                      />
                  ) : null
              )}

          <Button style={{color:"#393838",width:"100%", justifyContent:"center"}} modifier={ModifierType.Transparent} handleClick={handleTogglePlatformsModalShow}>Close</Button>
        </div>
      </div>
    </div>
  );
};

export default PlatformsModal;
