import styles from './ListingAssistant.module.scss';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { MoonLoader } from 'react-spinners';
import ScanComponent from '../ScanComponent';
import {CloseModal, KeyboardSvg, Lightbulb, UploadSvg} from "../../../assets/icons";
import useBasisFuncs from "@hooks/useBasisFuncs";
import {instanceAxios} from "@services/index";
import useQuotasTotal from "@hooks/useQuotasTotal";

function ListingAssistant({
                              changeListingAssistance,
                              failedBarcode,
                              setFailedBarcode,
                              scanLoader,
                              sendRequestForScan,
                              assistantTitle,
                              setAssistantTitle,
                              barCodeLimits,
                              sendRequestForSuggestDescription,
                              handleChangeEditInput,
                              loaderForSuggest,
                              setNewTableInfo,
                              newTableInfo,
                              itemData,
                              setItemData,
                              editIndex,
                              setCreateFromImage,
                              hideAssistantModal,
                              setHideAssistantModal,
                              imagesFromUploader,
                              mainTitle,
                              isModal,
                              setAssistantFromTitle,
                              unescapedRowMetadata,
                              setScanningFailed,
                              scanningFailed,
                              setScanLoader,
                              setBarCodeLimits,
                              setQuotasLimit,
                              quotasLimit,
                          }: any) {
    const [assistantScanTitle, setAssistantScanTitle] = useState( '');
    const [scanShow, setScanShow] = useState(false);
    const [localTitle, setLocalTitle] = useState('');
    const [hideDefaultModalItems, setHideDefaultModalItems] = useState(false);
    const [createFromTitle, setCreateFromTitle] = useState(false);
    const [showNotifyImagesModal, setShowNotifyImagesModal] = useState(false);
    const [permissionDenied, setPermissionDenied] = useState(false);
    const [loaderQuotas, setLoaderQuotas] = useState(true);
    const [loaderBarcode, setLoaderBarcode] = useState(true)
    const {bdecode} = useBasisFuncs();

    const handleGetQuotasTotal = async () => {
        setLoaderQuotas(true)
        try {
            const response = await instanceAxios.post('listings/tools/quotas/list', {
                filter_by: { name: 'generator' },
            });

            const data = response.data.data[0];
            setQuotasLimit({
                available: data.available,
                total: data.total,
            });
        } catch (e) {
            console.log(e, 'error');
        }
        finally {
            setLoaderQuotas(false);
        }
    };

    useEffect(() => {
            handleGetQuotasTotal();
    }, []);

    const handleGetBarCodeTotal = async () => {
        setLoaderBarcode(true);
        try {
            const response = await instanceAxios.post('listings/tools/quotas/list', {
                filter_by: { name: 'barcode_scanner' },
            });

            const data = response.data.data[0];
            setBarCodeLimits({
                available: data.available,
                total: data.total,
            });
        } catch (e) {
            console.log(e, 'error');
        }
        finally{
            setLoaderBarcode(false);
        }
    };

    useEffect(() => {
            handleGetBarCodeTotal();
    }, []);

    const handleChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
        const isChecked = event.target.checked;
        setHideAssistantModal(!isChecked);

        if (isChecked) {
            changeListingAssistance(true, 'show');
        } else {
            changeListingAssistance(true, 'hide');
        }

    };

    const handleCreateFromImage = () =>{
        setCreateFromImage(true);
        changeListingAssistance(false);
    }
    const handleTitleChange = (e: any) => {
        const { value } = e.target;
        setLocalTitle(value)
        setAssistantTitle(value);
    };
    const handleScanTitleChange = (e: any) => {
        const { value } = e.target;
        setAssistantScanTitle(value);
    };

    const toggleCreateFromTitle = ()=> {
        setCreateFromTitle(true);
        setHideDefaultModalItems(true);
        if(mainTitle){
            setAssistantTitle(mainTitle)
            setLocalTitle(mainTitle)
        }
    }
    function limitToMaxCharacters(input: string, maxChars: number): any {
      try{
          let charCount = 0;
          let realIndex = 0;

          for (let i = 0; i < input.length; i++) {
              const char = input[i];

              // Check if adding this character exceeds the limit
              if (char === '\n') {
                  if (charCount + 2 > maxChars) break; // Exceeds limit, stop
                  charCount += 2;
              } else {
                  if (charCount + 1 > maxChars) break; // Exceeds limit, stop
                  charCount += 1;
              }

              // Only increase the realIndex if we are still within the character limit
              realIndex++;
          }

          // Return the substring up to the valid character index
          return input.substring(0, realIndex);
      }catch(e){
          console.log(e, 'error')
      }
    }

    const titleSubmit = (e: any) => {
       try{
           if (localTitle.length < 40) {
               toast.error(
                   'Min chars for title field "Suggest description" button to become active is 40 characters.',
                   {
                       position: 'top-right', // You can customize the notification position
                   }
               );
           } else {
               // setNewTableInfo({
               //     ...newTableInfo,
               //     ['newTitle']: { text: localTitle, changed: true },
               // });
               setNewTableInfo({
                   ...newTableInfo,
                   ['newTitle']: { text: localTitle, changed: true },
                   ...(
                       (newTableInfo.newMercaryTitle.changed ||
                           (unescapedRowMetadata[789] )) &&

                       newTableInfo.newMercaryTitle.text !==  unescapedRowMetadata[133] &&

                       (newTableInfo.newMercaryTitle.text !== newTableInfo.newTitle.text.substring(0, 80))
                           ? {}
                           : {
                               newMercaryTitle: {
                                   text: limitToMaxCharacters(localTitle, 80), // Sync with first input or use assistanceValue
                                   changed: true, // Mark as changed
                               },
                           }
                   ),
                   ...(
                       (newTableInfo.newPoshmarkTitle.changed ||
                           (unescapedRowMetadata[786] )) &&

                       newTableInfo.newPoshmarkTitle.text !==  unescapedRowMetadata[133] &&

                       ( newTableInfo.newPoshmarkTitle.text !== newTableInfo.newTitle.text.substring(0, 80))
                           ? {}
                           : {
                               newPoshmarkTitle: {
                                   text: limitToMaxCharacters(localTitle, 80), // Sync with first input or use assistanceValue
                                   changed: true, // Mark as changed
                               },
                           }
                   ),

                   ...(
                       (newTableInfo.newGrailedTitle.changed ||
                           (unescapedRowMetadata[788])) &&

                       newTableInfo.newGrailedTitle.text !==  unescapedRowMetadata[133] &&

                       ( newTableInfo.newGrailedTitle.text !== newTableInfo.newTitle.text.substring(0, 60))
                           ? {}
                           : {
                               newGrailedTitle: {
                                   text:  limitToMaxCharacters(localTitle, 60), // Sync with first input or use assistanceValue
                                   changed: true, // Mark as changed
                               },
                           }
                   ),

                   ...(
                       (newTableInfo.newAmazonEbayTitle.changed ||
                           (unescapedRowMetadata[791])) &&

                       newTableInfo.newAmazonEbayTitle.text !==  unescapedRowMetadata[133] &&

                       ( newTableInfo.newAmazonEbayTitle.text !== newTableInfo.newTitle.text.substring(0, 80))
                           ? {}
                           : {
                               newAmazonEbayTitle: {
                                   text: limitToMaxCharacters(localTitle, 80), // Sync with first input or use assistanceValue
                                   changed: true, // Mark as changed
                               },
                           }
                   ),

                   ...(
                       (newTableInfo.newFacebookTitle.changed ||
                           (unescapedRowMetadata[869])) &&

                       newTableInfo.newFacebookTitle.text !==  unescapedRowMetadata[133] &&

                       ( newTableInfo.newFacebookTitle.text !== newTableInfo.newTitle.text.substring(0, 99))
                           ? {}
                           : {
                               newFacebookTitle: {
                                   text:  limitToMaxCharacters(localTitle, 99), // Sync with first input or use assistanceValue
                                   changed: true, // Mark as changed
                               },
                           }
                   ),

               });
               const updatedItem = [...itemData];
               updatedItem[editIndex] = {
                   ...updatedItem[editIndex],
                   ['newTitle']: localTitle,
                   changed: true,
               };
               setItemData(updatedItem)
               sendRequestForSuggestDescription('assistance');
               setAssistantTitle('');
               setFailedBarcode('');
               setAssistantFromTitle(true);
               setLocalTitle('');
               setAssistantScanTitle('');
           }
       }
       catch(e){
           console.log(e, 'error')
       }
    };

    const barcodeClick = () => {
        sendRequestForScan(assistantScanTitle);
        setAssistantScanTitle('');
        setFailedBarcode('');
    };

    useEffect(() => {
        if(scanningFailed){
            setTimeout(()=>{
                setScanningFailed(false);
            },7000)
        }
    }, [scanningFailed]);

    const checkCameraPermission = async () => {
        try {
            // Check camera permissions
            const stream = await navigator.mediaDevices.getUserMedia({ video: true });
            // If permission is granted, stop the stream tracks
            stream.getTracks().forEach(track => track.stop());
            return true; // Permission granted
        } catch (error) {
            console.error('Camera permission denied or an error occurred:', error);
            return false; // Permission denied or an error occurred
        }
    };

    const handleScanClicked = async () => {
        try{
            setScanLoader(true)
            const isPermissionGranted = await checkCameraPermission();
            if (isPermissionGranted) {
                setScanShow(true);
                setScanLoader(false);
                setPermissionDenied(false);
            } else {
                setPermissionDenied(true);
                setScanLoader(false);
            }
        }catch(e){
            console.log(e, 'error')
        }
    };

    useEffect(() => {
        if(failedBarcode){
            setAssistantScanTitle(failedBarcode);
        }
    }, [failedBarcode]);

    useEffect(() => {
        document.body.style.overflow = 'hidden';
        return () => {
            document.body.style.overflow = '';
        };
    }, []);

    return (
        <div className={`${styles.listingAssistantMain} ${isModal ? styles.isModal : ''}`}>
            <div className={styles.listingAssistantContainer}>
                {scanShow ? (
                    <ScanComponent
                        setScanShow={setScanShow}
                        sendRequestForScan={sendRequestForScan}
                    />
                ) : loaderForSuggest || scanLoader ? (
                    <div className={styles.assistantLoader}>
                        {' '}
                        <MoonLoader color="#1e3166" loading={true} size={48} />
                    </div>
                ) : (
                    <>
                        <div className={styles.closeListingAssistant} onClick={() => {
                            changeListingAssistance(false)
                            setAssistantScanTitle('')
                            setFailedBarcode('')
                        }}>
                            <CloseModal/>
                        </div>
                        <h2 className={styles.assistHeading}><Lightbulb/> Listing Assistant </h2>
                        {
                            showNotifyImagesModal ?
                                <div>
                                    <p className={styles.notifyModalDesc}>Please upload a few images into the uploader to activate this feature and generate the listing details. </p>
                                </div>
                                :
                                <>
                                    {
                                        !hideDefaultModalItems && <p className={styles.assistDesc}>
                                            Use one of the options below and let LP create the listing for
                                            you!
                                        </p>
                                    }
                                    {
                                        !hideDefaultModalItems && <div className={styles.buttonsContainerForAssistant}>
                                            <button className={styles.assistantButtonChoice}
                                                    disabled={(quotasLimit && quotasLimit?.available === 0) || !quotasLimit || loaderQuotas}
                                                    style={(quotasLimit && quotasLimit?.available === 0) || !quotasLimit || loaderQuotas ? {opacity: 0.7} : {opacity: 1}}
                                                    onClick={() => {
                                                        if (imagesFromUploader?.length) {
                                                            handleCreateFromImage()
                                                        } else {
                                                            setShowNotifyImagesModal(true)
                                                        }

                                            }}><UploadSvg/> Create from Images
                                            </button>
                                            <button disabled={(quotasLimit && quotasLimit?.available === 0 ) || !quotasLimit || loaderQuotas}
                                                    style={(quotasLimit && quotasLimit?.available === 0 ) || !quotasLimit || loaderQuotas ? {opacity: 0.7} : {opacity: 1}}
                                                    className={styles.assistantButtonChoice}
                                                    onClick={toggleCreateFromTitle}>
                                                <KeyboardSvg/> Create from Title
                                            </button>
                                        </div>
                                    }
                                    {
                                        createFromTitle && <div className={styles.detailedDate}>
                                            <div className={styles.detailedTitleContainer}>
                                                <input
                                                    name="input_133"
                                                    value={bdecode(assistantTitle)}
                                                    maxLength={250} // Set the maximum character limit
                                                    className={styles.detailedDateInputTitle}
                                                    placeholder="Add a detailed title"
                                                    onChange={handleTitleChange}
                                                />
                                                <input
                                                    name="input_133"
                                                    type="button"
                                                    value="Submit"
                                                    className={styles.showSubmitBtn}
                                                    onClick={(e) => titleSubmit(e)}
                                                />
                                            </div>

                                        </div>
                                    }

                                    {
                                        !hideDefaultModalItems && <>
                                            {!localTitle.length && (
                                                <>
                                                    <p className={styles.charactersQuantity}
                                                       style={{display: 'flex', alignItems: 'center', gap:'5px'}}
                                                    >
                                                        {loaderQuotas ? <MoonLoader size={18}/> : quotasLimit?.available} of {loaderQuotas ? <MoonLoader size={18}/> : quotasLimit?.total} available
                                                    </p>
                                                    <div className={styles.or}>OR</div>

                                                    <div className={styles.scanInput}>
                                                        <div className={styles.scanInputContainer}>
                                                            {' '}
                                                            <input
                                                                value={assistantScanTitle}
                                                                className={styles.detailedDateInput}
                                                                style={{height: '40px'}}
                                                                placeholder="Scan or enter barcode"
                                                                onChange={handleScanTitleChange}
                                                            />
                                                            <button
                                                                className={styles.scanBtn}
                                                                onClick={() => handleScanClicked()}
                                                                style={(barCodeLimits && barCodeLimits?.available === 0) || !barCodeLimits || loaderBarcode ? {opacity: 0.7} : {opacity: 1}}
                                                                disabled={(barCodeLimits && barCodeLimits?.available  === 0) || !barCodeLimits || loaderBarcode}
                                                            >
                                                                Scan
                                                            </button>
                                                        </div>
                                                        {assistantScanTitle.length > 0 && (
                                                            <button
                                                                onClick={() => barcodeClick()}
                                                                className={styles.showSubmitBtn}
                                                                style={(barCodeLimits && barCodeLimits?.available === 0) || !barCodeLimits || loaderBarcode ? {opacity: 0.7} : {opacity: 1}}
                                                                disabled={(barCodeLimits && barCodeLimits?.available  === 0) || !barCodeLimits || loaderBarcode}
                                                            >
                                                                Submit
                                                            </button>
                                                        )}
                                                        <p className={styles.charactersQuantity}
                                                         style={{display: 'flex', alignItems: 'center', gap: '5px'}}
                                                        >
                                                            {loaderBarcode ? <MoonLoader size={18}/> : barCodeLimits?.available} of{' '}
                                                            {loaderBarcode ? <MoonLoader size={18}/> : barCodeLimits?.total} available
                                                        </p>
                                                    </div>
                                                    {
                                                            scanningFailed && <p className={styles.scanningError}><span style={{fontWeight:'bold'}}>Error: No Results Found</span> <br/>
                                                            We couldn’t find anything for the barcode you scanned. The database is updated regularly, so feel free to try again in 24 hours.</p>
                                                    }
                                                    {
                                                        permissionDenied && <p className={styles.scanningError}>Scan needs camera permission</p>
                                                    }
                                                </>
                                            )}
                                        </>
                                    }

                                    {
                                        !hideDefaultModalItems && <div className={styles.submitButtons}>
                                            <button onClick={() => changeListingAssistance(false)}>
                                                List on my own
                                            </button>
                                            {
                                                <div className={styles.autoModalSwitcher}>
                                                    <span>Always Show: </span>
                                                    <input
                                                        type="checkbox"
                                                        style={{visibility: 'visible'}}
                                                        className={styles.myCheckbox}
                                                        name={'showAutoModal'}
                                                        checked={!hideAssistantModal}
                                                        onChange={handleChangeCheckbox}
                                                    />
                                                </div>
                                            }

                                        </div>
                                    }
                                </>
                        }


                    </>
                )}
            </div>
        </div>
    );
}

export default ListingAssistant;