import {useEffect, useState} from "react";

const UseExtensionInstall = () => {
	const [hasExtensionInstall, setHasExtensionInstall] = useState(false);

	useEffect(() => {
		const bodyElement = document.body;
		const handleAttributeChange = () => {
			const extensionVersion = bodyElement.getAttribute('lp_custom_chrome_extension_version');
			try {
				if (extensionVersion) {
					setHasExtensionInstall(true)
				}
				else {
					setHasExtensionInstall(false)
				}
			} catch (e) {
				console.log('error', e);
			}
		};

		const observer = new MutationObserver(() => {
			handleAttributeChange();
		});

		observer.observe(bodyElement, {
			attributes: true,
			attributeFilter: ['lp_custom_chrome_extension_version'],
		});

		// Initial attribute check
		handleAttributeChange();

		return () => {
			observer.disconnect();
		};
	}, []);
	return {
		hasExtensionInstall
	}
}

export default UseExtensionInstall;