import EditAndAddListingModal from "../../components/EditAddListingModal";
import Iframe from "../../components/UI/Iframe";
import {Helmet} from "react-helmet";
import React from "react";

const AddListingPage = () => {
    return(
        <>
            <Helmet>
                <title>
                    Add Listing | List Perfectly
                </title>
            </Helmet>
            <Iframe />
            <EditAndAddListingModal /></>
    )
}

export default AddListingPage;