import React, {useEffect, useRef} from 'react'
import styles from './SuccessModal.module.scss'
import {SuccessCircle} from "../../../../../../assets/icons";
import SuccessModalListing from "./components/SuccessModalListing";
import Button, {ModifierType} from "../../../../../../components/UI/Button";

interface ISuccessModalProps {
	tableData: any;
	handleChangeSuccess: () => void;
	selectedListingsData: any;
}

const SuccessModal = (props: ISuccessModalProps) => {
	const {tableData, handleChangeSuccess,selectedListingsData} = props;

	const successModalRef = useRef<HTMLDivElement>(null);
	const sendedTableData = selectedListingsData?.length ?
	 selectedListingsData.filter((listingData:any) => !listingData.removedFromSendToSales) :
	 tableData?.filter((item: any) => item.isSendToSales)


	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (
			 successModalRef.current &&
			 !successModalRef.current.contains(event.target as Node)
			) {
				handleChangeSuccess()
			}
		};

		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [successModalRef, handleChangeSuccess]);

	const handleGoToSales = () => {
		window.open('/sales', '_blank');
	}

	const sendedTableDataLength = sendedTableData.length

	return (

	 sendedTableDataLength ? (
	  <div className={styles.overlay}>
		  <div
		   ref={successModalRef}
		   className={styles.successModal}
		   style={{
			   height: sendedTableDataLength >= 9 ? '100vh' : 'auto'
		   }}
		  >
			  <div className={styles.header}>
				  <SuccessCircle/>
				  <span>Success!</span>
			  </div>
			  <div className={styles.successItemsCount}>
				  <span>{sendedTableDataLength}</span>
				  {sendedTableDataLength > 1 ? 'items were' : 'item was'} successfully sent to Sales & Analytics
			  </div>
			  <div className={styles.wrapper}>
				  {sendedTableData.map((modalListing: any) => (
				   <SuccessModalListing
					key={modalListing.id}
					modalListing={modalListing}/>
				  ))}
			  </div>
			  <div className={styles.btns}>
				  <Button style={{border:"1px solid #1E3166", color:"#1E3166"}} modifier={ModifierType.Transparent} handleClick={handleChangeSuccess}>Close</Button>
				  <Button  style={{border:"1px solid #4DB89E", color:"#4DB89E"}} modifier={ModifierType.Transparent} handleClick={handleGoToSales}>
					  Go To Sales & Analytics
				  </Button>
			  </div>
		  </div>

	  </div>
	 ) : null

	)
}

export default SuccessModal