import React, { useEffect, useRef, useState } from 'react';
// @ts-ignore
import Quagga from '@ericblade/quagga2';
import { MoonLoader } from 'react-spinners';
import styles from './ScanComponent.module.scss';

interface ScanComponentProps {
    setScanShow: (show: boolean) => void;
    sendRequestForScan: (barcodeValue: string) => void;
}

const ScanComponent: React.FC<ScanComponentProps> = ({ setScanShow, sendRequestForScan }) => {
    const videoRef = useRef<any>(null);
    const beepSound = useRef<HTMLAudioElement | null>(new Audio('/sound/barcode.mp3'));
    const [loading, setLoading] = useState(true);
    const [deviceId, setDeviceId] = useState<string>('');
    const [cameras, setCameras] = useState<MediaDeviceInfo[]>([]);


    // Fetch available cameras
    const getCameras = async () => {
        try {
            const devices = await navigator.mediaDevices.enumerateDevices();
            const videoDevices = devices.filter(device => device.kind === 'videoinput');
            setCameras(videoDevices);

            const backCamera = videoDevices.find(device => device.label.toLowerCase().includes('back'));
            setDeviceId(backCamera ? backCamera.deviceId : videoDevices[0]?.deviceId || '');
        } catch (error) {
            console.error('Error fetching cameras:', error);
        }
    };

    useEffect(() => {
        getCameras();
    }, []);

    const stopScanning = () => {
        Quagga.stop();
        Quagga.offDetected(handleDetected);
    };

    const handleDetected = (result: any) => {
        const code = result?.codeResult?.code;
        if (!code || code.length < 8 || code.length > 13 || !/^\d+$/.test(code)) return;

        console.log('Detected barcode:', code);
        playBeepSound();
        sendRequestForScan(code);
        stopScanning();
        setScanShow(false);
    };

    const playBeepSound = () => {
        beepSound.current?.play().catch(err => console.error('Error playing sound:', err));
    };

    const startQuagga = () => {
        setLoading(true);

        Quagga.init({
            inputStream: {
                type: 'LiveStream',
                target: videoRef.current, // Use the div as the target
                constraints: {
                    deviceId: deviceId ? { exact: deviceId } : undefined,
                    facingMode: 'environment',
                },
                area: {
                    top: '10%', // Adjust the scanning area if needed
                    right: '10%',
                    left: '10%',
                    bottom: '10%',
                },
            },
            locator: { patchSize: 'large' },
            decoder: { readers: ['ean_reader', 'upc_reader', 'code_128_reader'] },
            locate: true,
        }, (err: any) => {
            if (err) {
                console.error('Quagga initialization error:', err);
                setLoading(false);
                return;
            }
            Quagga.start();
            setLoading(false);
        });

        Quagga.onDetected(handleDetected);
    };

    useEffect(() => {
        if (deviceId) {
            startQuagga();
        }

        return () => {
            stopScanning();
        };
    }, [deviceId]);

    const handleClose = () => {
        stopScanning();
        setScanShow(false);
    };

    return (
        <div style={{width: '100%', height: 'auto'}}>
            {loading && (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '20px' }}>
                    <MoonLoader />
                </div>
            )}

            {!loading && (
                <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '15px' }}>
                    <select
                        style={{ width: '100%', padding: '10px' }}
                        onChange={(e) => setDeviceId(e.target.value)}
                        value={deviceId}
                    >
                        {cameras.map(camera => (
                            <option key={camera.deviceId} value={camera.deviceId}>
                                {camera.label || `Camera ${camera.deviceId}`}
                            </option>
                        ))}
                    </select>
                </div>
            )}

            {/* Quagga will attach the camera stream to this div */}
            <div ref={videoRef} style={{ width: '100%',  display: loading ? 'none' : 'flex' }} className={styles.videoElement} />

            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '15px' }}>
                <button onClick={handleClose} style={{ background: '#1E3166', color: '#fff', padding: '10px 20px', borderRadius: '8px', border: 'none', cursor: 'pointer' }}>
                    Close
                </button>
            </div>
        </div>
    );
};

export default ScanComponent;