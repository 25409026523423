import styles from './HeaderComponent.module.scss';
import {ExitButton, Lightbulb, SavingIcon} from "../../../../assets/icons";
import {Dashicon} from "../../../../assets/icons";
import {Previewicon} from "../../../../assets/icons";
import {PlusIconNew} from "../../../../assets/icons";
import {SaveProgress} from "../../../../assets/icons";
import {useDispatch} from "react-redux";
import {
    setEditAddModalId,
    setEditAddModalShow,
    setShowEditTemplateModal
} from "../../../../redux/slices/NavigationSlice";
import {FooterComponent} from "../FooterComponent";
import React from "react";
import {MoonLoader} from "react-spinners";

const HeaderComponent = ({SKUloader, savingProgress, showEditAddModal, afterCreate, disableSaveButtons, handleToggleListingAssistant, showRegenerate,regenerateAttempsLeftCount,  setAssistantTitleVision, changeListingAssistance, enabledButtons, handleSaveForm, setSaveAndClose, setSaveAndPreview, setSaveAndNew, editId, handleAddListing}:any) => {
    const dispatch = useDispatch();

    const handleCloseEdit = () =>{
        dispatch(setEditAddModalShow(false));
    }

    return (
        <div className={`${styles.editHeader} ${ (showEditAddModal) ? styles.fixedHeader : styles.stickyHeader}`}>
            <div className={styles.headerLeftContainer}>
                <button
                    className={styles.listingAssistButton}
                    disabled={!enabledButtons || savingProgress || SKUloader}
                    style={!enabledButtons || savingProgress || SKUloader ? {opacity: 0.7} : {opacity: 1}}
                    onClick={() => {
                        setAssistantTitleVision('assistant')
                        changeListingAssistance(true)
                    } }
                >
                    <Lightbulb />
                    <span>Listing Assistant</span>

                </button>
                {
                    (showRegenerate && regenerateAttempsLeftCount !== 0) &&
                    <button className={styles.listingAssistButton}
                         disabled={!enabledButtons  || savingProgress || SKUloader }
                         style={{
                             backgroundColor: '#F2711C',
                             opacity: !enabledButtons || savingProgress || SKUloader ? 0.7 : 1
                         }}
                         onClick={() => {
                           setAssistantTitleVision('regenerate');
                           handleToggleListingAssistant();
                    }}><Lightbulb/> Regenerate
                    </button>
                }
            </div>
           <div className={styles.headerMiddleContainer}>
               <FooterComponent enabledButtons={enabledButtons}
                                handleSaveForm={handleSaveForm}
                                forTabletSize={true}
                                setSaveAndClose={setSaveAndClose}
                                setSaveAndPreview={setSaveAndPreview}
                                setSaveAndNew={setSaveAndNew}
                                handleAddListing={handleAddListing}
                                editId={editId}
                                disableSaveButtons={disableSaveButtons}
                                afterCreate={afterCreate}
                                showEditAddModal={showEditAddModal}
                                savingProgress={savingProgress}
                                SKUloader={SKUloader}
               />
               {
                   savingProgress && <div className={styles.loaderForSavingProgress}>
                       <SavingIcon/>
                       <p>Saving...</p>
                   </div>
               }
           </div>

            {/*<>*/}
            {/*    <div className={styles.editSubmitButtons}>*/}
            {/*        {*/}
            {/*                <button*/}
            {/*                    onClick={() => {*/}
            {/*                        if (enabledButtons) {*/}
            {/*                            editId ? handleSaveForm() : handleAddListing()*/}
            {/*                        }*/}
            {/*                    }}*/}
            {/*                    className={styles.saveProgress}*/}
            {/*                    disabled={!enabledButtons || disableSaveButtons}*/}
            {/*                    style={!enabledButtons || disableSaveButtons ? {opacity: 0.7} : {opacity: 1}}*/}
            {/*                >*/}
            {/*                    <SaveProgress/>*/}
            {/*                    Save Progress*/}
            {/*                </button>*/}
            {/*        }*/}

            {/*        <button*/}
            {/*            className={styles.saveAndNew}*/}
            {/*            disabled={!enabledButtons || disableSaveButtons}*/}
            {/*            onClick={() => {*/}
            {/*                if (enabledButtons) {*/}
            {/*                    setSaveAndNew(true);*/}
            {/*                    editId ? handleSaveForm() : handleAddListing()*/}
            {/*                }*/}
            {/*            }}*/}
            {/*            style={!enabledButtons || disableSaveButtons ? {opacity: 0.7} : {opacity: 1}}*/}
            {/*        >*/}
            {/*            <PlusIconNew/>*/}
            {/*            Save & New*/}
            {/*        </button>*/}
            {/*        <button*/}
            {/*            className={styles.saveAndPreview}*/}
            {/*            onClick={() => {*/}
            {/*                if (enabledButtons) {*/}
            {/*                    setSaveAndPreview(true);*/}
            {/*                    editId ? handleSaveForm() : handleAddListing()*/}
            {/*                }*/}
            {/*            }}*/}
            {/*            disabled={!enabledButtons || disableSaveButtons}*/}
            {/*            style={!enabledButtons || disableSaveButtons ? {opacity: 0.7} : {opacity: 1}}*/}
            {/*        >*/}
            {/*            <Previewicon/>*/}
            {/*            Save & Preview*/}
            {/*        </button>*/}
            {/*        <button*/}
            {/*            className={styles.saveAndClose}*/}
            {/*            disabled={!enabledButtons || disableSaveButtons}*/}
            {/*            style={!enabledButtons || disableSaveButtons ? {opacity: 0.7} : {opacity: 1}}*/}
            {/*            onClick={() => {*/}
            {/*                if (enabledButtons) {*/}
            {/*                    setSaveAndClose(true);*/}
            {/*                    editId ? handleSaveForm() : handleAddListing()*/}
            {/*                }*/}
            {/*            }}*/}
            {/*        >*/}
            {/*            <Dashicon/>*/}
            {/*            Save & Close*/}
            {/*        </button>*/}
            {/*    </div>*/}
            {/*</>*/}
            {
                (showEditAddModal) ? <div
                        className={styles.editCloseButton}
                        onClick={() => {
                            dispatch(setEditAddModalShow(false))
                            dispatch(setEditAddModalId(null))
                        }}
                    >
                        <ExitButton className={styles.closeEditIcon}/> Cancel
                    </div>
                    : <div style={{width: showRegenerate ? '0px' : '150px'}}></div>
            }

        </div>
    );
};

export default HeaderComponent;