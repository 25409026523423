import React, { useEffect, useState } from 'react';
import styles from './inputCount.module.scss';
import UseIsSmallLaptop from "@hooks/UseIsSmallLaptop";

interface TextAreaInreface {
    placeholder: string;
    name: string;
    value: string;
    limit: number;
    height: number;
    type?: string;
    handleChange:any;
    hideCount?:any;
}

export const InputCount = ({
    placeholder,
    name,
    value,
    limit,
    height,
    type,
    handleChange,
    hideCount
    }: TextAreaInreface) => {
    const isSmallScreenLaptop = UseIsSmallLaptop() ;
    const [characterCount, setCharacterCount] = useState(0);
    const id = Math.random().toString();
    const [hideCharactersCount, setHideCharactersCount] = useState(false);
    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (type === 'number') {
            const charCode = event.charCode;
            if ((charCode < 48 || charCode > 57) && charCode !== 46) {
                event.preventDefault();
            }
        }
    };

    const handleFocus = () => {
        if(!hideCount){
            setHideCharactersCount(true)
        }
    };

    const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
        if (type === 'number') {
            const paste = event.clipboardData.getData('text');
            if (!/^\d*\.?\d*$/.test(paste)) {
                event.preventDefault();
            }
        }
    };

    const handleBlur = () => {
        setHideCharactersCount(false)
    };

    useEffect(() => {
        if (value) {
            setCharacterCount(value.length);
        }
    }, [value]);

    return (
        <div style={{ width: '100%' }}>
            <input
                onKeyPress={handleKeyPress}
                className={styles.brandInfoTextarea}
                id={id}
                value={value}
                name={name}
                style={{ height: isSmallScreenLaptop ? height * 0.8 : height }}
                onFocus={handleFocus}
                onBlur={handleBlur}
                placeholder={placeholder}
                onPaste={handlePaste}
                maxLength={limit}
                onChange={(e) => {
                    const newText = e.target.value;
                    if (newText.length <= limit) {
                        handleChange(e)
                        if(!hideCharactersCount){
                            setCharacterCount(newText.length);
                        }
                    }
                }}
            />
            {
                hideCharactersCount &&  <p className={styles.charactersQuantity}>
                    Character Count: {characterCount} / {limit}
                </p>
            }

        </div>
    );
};