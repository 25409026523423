import { UploaderState} from "../interfaces";
import {Action} from "../actions"
import {addOrRemoveElementIfItExistArray, updatePropertyInFile} from "../common";

export const uploaderGlobalReducer = (state: UploaderState, action: Action): UploaderState => {
    switch (action.type) {
        case 'MODALLOADERACTION':
            return {...state,   showModalLoader: action.data.status, modalLoaderText: action.data.text}
        case 'SAVEIMAGE':
            return { ...state, saveImage: !state.saveImage};
        case 'SHOWIMAGESELECTION':
            return { ...state, showImageSelection:  action.data};
        case 'INCREASEIMAGELOADINGQUEUE':
            console.log('imageLoadingThreads changed: INCREASE: ', state.imageLoadingThreadsQueue + 1,9999)
            return { ...state, imageLoadingThreadsQueue:  state.imageLoadingThreadsQueue + 1};
        case 'DECREASEIMAGELOADINGQUEUE':
            console.log('imageLoadingThreads changed: DECREASE: ', state.imageLoadingThreadsQueue - 1,9999)
            return { ...state, imageLoadingThreadsQueue:  state.imageLoadingThreadsQueue - 1};
        case 'UPDATESELECTEDIMAGEINDEXES':
            return { ...state, selectedImageIndexes:  action.data};
        case 'UPDATESELECTEDIMAGEINDEX':
            return { ...state, selectedImageIndexes:  addOrRemoveElementIfItExistArray(state.selectedImageIndexes, action.data)};
        case 'SETNEWPROPERTYINFILE':
            return { ...state, files: updatePropertyInFile(state.files, action.data.index, action.data.properties)};
        case 'CONFIRMCROP':
            return { ...state, confirmCrop: !state.confirmCrop};
        case 'SETUSERCONFIG':
            return { ...state, userConfig: action.data};
        case 'SETBACKGROUNDSTATS':
            return { ...state, removeBackgroundStats: action.data};
        case 'SETBACKGROUNDREMOVECOLORS':
            const {selected_remove_bg_color,remove_bg_colors} = action.data;
            return { ...state, selected_remove_bg_color, remove_bg_colors};
        case 'SETCACHEDFILE':
            return { ...state, cachedFile: action.data};
        case 'REMOVEFILE':
            let newFileList = state.files.filter((file, index) => index !== action.data);
            return { ...state, files: newFileList};
        case 'ADDNEWFILE':
            return { ...state, files: [...state.files,action.data]};
        case 'SETFILES':
            return { ...state, files: [...action.data]};
        case 'INCREASELOADCOUNTER':
            return { ...state, uploadedFileCounter: action.data};
        case 'SETACTIVEFILE':
            return { ...state, activeFile: action.data};
        case 'SETUPLOADERCROPGRIDVIEW':
            return { ...state, showUploaderCropGrid: action.data};
        case 'SETUPLOADERCROPSQUARE':
            return { ...state, showUploaderCropSquare: action.data};
        case 'SETUPLOADERCROPVIEW':
            return { ...state, showUploaderCropView: action.data};
        case 'SETCROPBUTTONDISABLED':
            return { ...state, cropButtonDisabled: action.data};
        default:
            return state;
    }
};