import React, {useLayoutEffect, useState} from 'react';
import styles from '../CatalogInModal.module.scss';

import _ from 'lodash'

import useAppPrefix from '../../../../../hooks/UseAppPrefix';
import useBasicFuncs from '../../../../../hooks/useBasisFuncs';
import {SoldTagSvg} from "../../../../../assets/icons";

// Importing services
import AuthService from '../../../../../services/Auth';
import OrdersService from '../../../../../services/Orders';
import ListingsService from '../../../../../services/Listings';
import Button, {ModifierType} from "../../../../../components/UI/Button";
import UseIsSmallLaptop from "@hooks/UseIsSmallLaptop";

interface IListingInModalProps {
  listing: any;
  linkedListing: any;
  setLinkedListing: (linkedListing: any) => void;
  listingId: number;
  orderId: number;
  scrollToTop:() => void;
}

const ListingInModal = (props: IListingInModalProps) => {
  const {
    listing, // TODO this is listing which you're getting with listings/list when looking up listings by title.
    linkedListing, // TODO this is listing which is already attached(linked) to the order listing
    setLinkedListing,
    listingId, // TODO this is order_listing_id
    orderId, // TODO this is id of an order
    scrollToTop
  } = props;
  const isSmallScreenLaptop = UseIsSmallLaptop() ;
  const { bdecode } = useBasicFuncs();
  const linkPrefix = useAppPrefix();

  const [dimensions, setDimensions] = useState<string | null>(null);

  useLayoutEffect(() => {
    const getImageDimensions = (src: any) => {
      try {
        if (src ) {
          const img = new Image();
          img.src = src.thumbnail;
          img.onload = () => {
            if (img.width >= img.height) {
              setDimensions('width');
            } else {
              setDimensions('height');
            }
          };
        }
      } catch (e) {
        console.log('e');
      }
    };
    getImageDimensions(listing);
  }, [listing]);

  const order_listing_catalog_id_new = _.get(listing, 'id');
  const is_linked = _.get(linkedListing, 'id') === order_listing_catalog_id_new;

  // This function is responsible for linking listings
  const handleListingLinking = async () => {

    // Get listing id
    const listing_id = _.get(listing, 'id');

    // Fetch the latest version of listing from the database before linking
    // it's important to have this handler, it ensures, that we're not using
    // caching version and not overwriting user updates.
    const listings = await ListingsService.list({ id: listing_id }, { offset: 0, limit: 1 }, { include_thumbnail: true }, null, AuthService.get_token());
    const latest_version_of_listing = _.get(listings, 'data[0]');

    // Set up concurrent requests array
    const concurrent_requests = [];

    // Init
    const order_id = orderId;
    const order_listing_id = listingId;

    // Update order listing details in the Database
    const orders_listings_update_filter_by = { id: order_listing_id };
    const orders_listings_update_payload = { catalog_id: order_listing_catalog_id_new };

    // Perform some basic checks and populate metadata
    const sku = _.get(latest_version_of_listing, 'metadata[1]');
    if(sku !== undefined) _.set(orders_listings_update_payload, 'sku', sku);
    let cogs = _.get(latest_version_of_listing, 'metadata[847]');
    if(cogs) cogs = _.toNumber(cogs);
    if(cogs !== undefined) _.set(orders_listings_update_payload, 'cogs', cogs);


    // Add to concurrent requests
    if(!_.isEmpty(orders_listings_update_payload)) concurrent_requests.push( OrdersService.listings_update(AuthService.get_token(), orders_listings_update_filter_by, orders_listings_update_payload) );


    // Update order details in the Database
    const orders_update_filter_by = { id: order_id };
    const orders_update_payload = {};

    // Perform some basic checks and populate metadata
    let fees = _.get(latest_version_of_listing, 'metadata[844]');
    if(fees) fees = _.toNumber(fees);
    if(fees !== undefined) _.set(orders_update_payload, 'fees', fees);

    // Add to concurrent requests
    if(!_.isEmpty(orders_update_payload)) concurrent_requests.push( OrdersService.update(AuthService.get_token(), orders_update_filter_by, orders_update_payload) );

    // Execute concurrent requests (save to the Database) TODO add exponential backoffs later
    if(!_.isEmpty(concurrent_requests)) await Promise.all(concurrent_requests);

    // Set new linked listing and scroll to the top
    setLinkedListing(latest_version_of_listing);
    scrollToTop()

  };

  return (
   <div className={styles.listingInModalItem}>
     <div className={styles.left}>
       <div className={styles.thumbnail}>
         {_.get(listing, 'thumbnail') ? <img
          style={{
            width: dimensions === 'width' ? (!isSmallScreenLaptop ? '70px' : '56px') : 'auto',
            height: dimensions === 'height'? (!isSmallScreenLaptop ? '70px' : '56px') : 'auto',
          }}
          src={_.get(listing, 'thumbnail')}/> : <div className={styles.noThumbnail}></div>}
       </div>
       <h3>
         {<a
            href={`
            https://${linkPrefix.prefix}.listperfectly.com/listings/single/view/${listing.id}`}
            target="_blank">
             {_.get(listing,'metadata') && bdecode(_.get(listing,'metadata[133]'))}
           </a>}
       </h3>
     </div>

  <div className={styles.right}>
    {_.get(listing,'metadata') &&  _.get(listing,'metadata[539.1]') === 'SOLD' && (
     <div className={styles.soldIcon}>
       <SoldTagSvg/>
     </div>
    )}

    <Button
     style={{
       border: '1px solid #4DB89E',
       color:'#4DB89E'
     }}
     handleClick={() => {
      if(listing) {
        handleListingLinking()
      }
    }}
    modifier={ModifierType.Transparent}
    >{is_linked? 'Link' : linkedListing ? 'Replace' : 'Link'}</Button>
  </div>

   </div>
  );
};
export default ListingInModal;
