import React, {useEffect, useRef} from 'react';

import styles from './ConfirmModal.module.scss';
import {IConfirmModalShow} from '@interfaces/index';
import {CloseSvg} from '../../../../../../assets/icons';
import Button, {ModifierType} from "../../../../../../components/UI/Button";

interface IConfirmModalProps {
  handleToggleConfirmModalShow: () => void;
  confirmModalShow: IConfirmModalShow;
  handleFuncDeleteOrRestore: (role: string) => void;
  type:string;
  selectedListings?:any;
  handleDeleteForOneSale?:() => void;
}

const ConfirmModal = (props: IConfirmModalProps) => {
  const {
    handleToggleConfirmModalShow,
    confirmModalShow,
    handleFuncDeleteOrRestore,
    type,
    selectedListings,
    handleDeleteForOneSale
  } = props;

  const confirmModalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        confirmModalRef.current &&
        !confirmModalRef.current.contains(event.target as Node)
      ) {
        handleToggleConfirmModalShow();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [confirmModalRef, handleToggleConfirmModalShow]);

  const ordersType = type === 'orders' && confirmModalShow?.listingCount === 1 ? 'order' : 'orders' ;

  return (
    <div className={styles.confirmModalOverlay}>
      <div ref={confirmModalRef} className={styles.modal}>

        <div className={styles.close__block}>
          <Button
           style={{
             fontSize:"2em",color:"#000"
           }}
           modifier={ModifierType.Transparent}
           handleClick={handleToggleConfirmModalShow}>
            <CloseSvg />
          </Button>
        </div>

        <div className={styles.modalWrapper}>
          <h1>
            <span className={styles.role}>{confirmModalShow.role}</span>
            <span>{confirmModalShow?.listingCount} {type === 'orders' ? ordersType : type}. Confirm?</span>
          </h1>

          <div className={styles.buttons}>
            <Button modifier={ModifierType.Primary} handleClick={() => {
              if(type === 'orders' && handleDeleteForOneSale) {
                confirmModalShow?.listingCount === 1 ? handleDeleteForOneSale(): handleFuncDeleteOrRestore(confirmModalShow.role)
              } else {
                handleFuncDeleteOrRestore(confirmModalShow.role)
              }
            }}>Yes</Button>
            <Button style={{
              background:"#b6b6b6", color:"#fff"
            }} modifier={ModifierType.Transparent}  handleClick={handleToggleConfirmModalShow}>
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmModal;
