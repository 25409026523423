import React, {ClipboardEvent, useState} from 'react'
import styles from '../../SendToSalesModal.module.scss'

import _ from "lodash";
import DatePicker from "react-datepicker";
import moment from "moment/moment";
import Select from "react-select";
import {v4 as uuidv4} from "uuid";

import {ModalCloseSvg} from "../../../../../../assets/icons";
import CombineItem from "./components/CombineItem";
import SoldOnPlatforms from "../SoldOnPlatforms";
import convertToCamelCase from "../../../../../../utils/convertToCamelCase";
import transformString from "../../../../../../utils/transformString";
import {instanceAxios2} from "../../../../../../services";
import Loader from "../../../../../../components/Templates/Loader";
import Button, {ModifierType} from "../../../../../../components/UI/Button";
import UseIsSmallLaptop from "@hooks/UseIsSmallLaptop";

const combineModalFields = [
	{
		id: 1,
		value: 'date_sold',
		title: "Date Sold",
		width: "132px"

	},
	{
		id: 2,
		value: 'sold_on',
		title: "Sold On",
		width: '159px'

	},
	{
		id: 3,
		value: 'shop',
		title: "Shop",
		width: '140px'

	},
	{
		id: 33,
		value: 'sold_price',
		title: "Sold Price",
		width: '100px'

	},
	{
		id: 4,
		value: 'shipping_cost_buyer',
		title: "Shipping Cost Buyer",
		width: '100px'

	},
	{
		id: 5,
		value: 'shipping_cost_seller',
		title: "Shipping Cost Seller",
		width: '100px'

	},
	{
		id: 7,
		value: 'fees',
		title: "Fees",
		width: '100px'

	},
	{
		id: 8,
		value: 'sales_tax',
		title: "Sales Tax",
		width: '100px'

	},
	{
		id: 9,
		value: 'shipping_status',
		title: "Shipping Status",
		width: '180px'
	},

]

interface ICombineItemsModalProps {
	modalListings: any;
	setModalListings: (modalListings: any) => void;
	handleToggleCombineModalShow: () => void;
	customMarketplaces: any;
	handleToggleShowSalesModal: () => void;
	checkPlatformInMetadata: (metadata: any) => string;
	handleChangeSuccess: () => void;
	tableData:any;
	setTableData:(tableData:any) => void;
	checkShopId:(platformName:string, index:number) => void;
	handleDeleteListingInModal:(id:number)=> void;
	checkPlatformListingId:(metadata:any) => string;
	handleChangeListingIdByPlatforms:(platformCode:string, index:number) => void;
}

interface ICombineModalInfo {
	[key: string]: {
		text: string;
		changed: boolean;
	};
}

const CombineItemsModal = (props: ICombineItemsModalProps) => {
	const {
		modalListings,
		setModalListings,
		handleToggleCombineModalShow,
		customMarketplaces,
		handleToggleShowSalesModal,
		checkPlatformInMetadata,
		checkPlatformListingId,
		handleChangeSuccess,
		tableData,
		setTableData,
		checkShopId,
		handleDeleteListingInModal,
		handleChangeListingIdByPlatforms
	} = props;

	const isSmallScreenLaptop = UseIsSmallLaptop() ;

	let unescapedRowMetadata: any = null

	function combineMetadata() {

		modalListings.forEach((item: any) => {
			unescapedRowMetadata = {...unescapedRowMetadata, ...item.metadata};
		});

		return unescapedRowMetadata;
	}

	combineMetadata()

	const [combineModalNewInfo, setCombineModalNewInfo] = useState<ICombineModalInfo>({
		newDateSold: {
			text: "",
			changed: false
		},
		newSoldOn: {
			text: "",
			changed: false
		},
		newShop: {
			text: "",
			changed: false
		},
		newSoldPrice: {
			text: "",
			changed: false
		},
		newShippingCostBuyer: {
			text: "",
			changed: false
		},
		newShippingCostSeller: {
			text: "",
			changed: false
		},
		newFees: {
			text: "",
			changed: false
		},
		newSalesTax: {
			text: "",
			changed: false
		},
		newShippingStatus: {
			text: "",
			changed: false
		},
	})

	const initialDate = moment(unescapedRowMetadata[846]).toDate();

	const [selectedDate, setSelectedDate] = useState(initialDate);
	const [isLoading,setIsLoading] = useState(false)

	const handleChange = (date: any, name: string) => {

		const formattedDate = moment(date).toISOString();
		updateModalListings('newDateSold', formattedDate)

		setSelectedDate(date);

	}

	// custom select styles
	const customStyles = {
		control: (provided: any,state:any) => ({
			...provided,
			backgroundColor: '#fff',
			color: '#1E3166',
			borderColor: state.isFocused ? '#4db89e' : '#393838',
			borderRadius: isSmallScreenLaptop ? '6px' : '8px',
			minWidth: isSmallScreenLaptop ? '140px' : '175px',
			cursor: 'pointer',
			zIndex: 1,
			minHeight: !isSmallScreenLaptop ? '39px' : '31px',
			height: !isSmallScreenLaptop ? '39px' : '31px',
			boxShadow:"none",
			padding: '0',
			outline: 'none',

			'&:hover': {
				...(state.isFocused) && {borderColor: '#4db89e',},
				boxShadow:"none"
			},
		}),
		menu: (provided: any) => ({
			...provided,
			width: '100%',
			minWidth: isSmallScreenLaptop ? '140px' : '175px',
			border: '1px solid #4db89e',
			outline:'none',
			borderRadius: isSmallScreenLaptop ? '6px' : '8px',
			paddingTop: isSmallScreenLaptop ? '8px' : '10px',
			boxShadow:"none"

		}),
		valueContainer: (provided: any) => ({
			...provided,
			height: !isSmallScreenLaptop ? '39px' : '31px',
			padding:  !isSmallScreenLaptop ? '0 10px' : '0 8px'
		}),
		indicatorsContainer: (provided: any) => ({
			...provided,
			height: !isSmallScreenLaptop ? '39px' : '31px',
		}),
		option: (provided: any, state: any) => ({
			...provided,
			cursor: 'pointer',
			fontWeight: 600,
			fontSize: isSmallScreenLaptop ? '13px' : '16px',
		}),
	};

	const [selectedOption, setSelectedOption] = useState({
		value: "",
		label: "-",
	});

	const options = [
		{value: '', label: '-'},
		{value: 'pending', label: 'Pending'},
		{value: 'shipped', label: 'Shipped'},
		{value: 'canceled', label: 'Canceled'},
		{value: 'returned', label: 'Returned'},

		{value: 'hold', label: 'Hold'},
		{value: 'awaiting_shipment', label: 'Awaiting Shipment'},
	];

	const dropDownChange = (selectedOption: any) => {
		updateModalListings('newShippingStatus', selectedOption.value)

		setSelectedOption(selectedOption);

	};

	function updateModalListings(name: string, value: string) {
		setCombineModalNewInfo({...combineModalNewInfo, [name]: {text: value, changed: true}})

		const updatedModalListings = [...modalListings]

		updatedModalListings.forEach((item: any, index: number) => {
			if (value) {
				item[name] = value;
				item.changed = true

			} else {
				delete item[name]
			}
		});

		// setModalListings(updatedModalListings);

	}

	const checkInputType = (item: any) => {
		let type = "text"
		if (item.value === 'shipping_cost_buyer' || item.value === 'shipping_cost_seller' || item.value === 'sales_tax'  || item.value === 'sold_price' || item.value === 'fees' ) {
			type = 'number'
		}

		return type
	}

	const checkInputValue = (item: any) => {
		let value = ""
		if (item.value === 'sku') {
			value = unescapedRowMetadata[1] || '-'
		} else if (item.value === 'sold_price') {
			value = unescapedRowMetadata[842]
		} else if (item.value === 'shipping_cost_seller') {
			value = unescapedRowMetadata[852]
		} else if (item.value === 'fees') {
			value = unescapedRowMetadata[844]
		} else if (item.value === 'cogs') {
			value = unescapedRowMetadata[847]
		} else if (item.value === 'quantity_available') {
			value = unescapedRowMetadata[851]
		}
		return value
	}



	const handleChangeInput = (e:
								| React.ChangeEvent<HTMLInputElement>
								| React.ChangeEvent<HTMLTextAreaElement>
								| React.ChangeEvent<HTMLSelectElement>) => {
		const {name, value} = e.target;


		updateModalListings(name, value)

	}

	const handleSendCombineItems = () => {
		if(isButtonDisabled()) return
		setIsLoading(true)
		const updatedModalListings = [...modalListings]
		const today = moment().toISOString();


		const sendedOrder: any = {
			listings: [],
		};

		let payload: any = {}
		let platformName: any = checkPlatformInMetadata(unescapedRowMetadata)

		updatedModalListings.forEach((item, index) => {
			const listingId: any = item?.newListingId || checkPlatformListingId(item?.metadata)


			const keys = Object.keys(item).filter(key => !['newSku', 'newItemSoldPrice', 'newQuantitySold',
				'newQuantityAvailable', 'newCogs', 'newListingId'].includes(key));
			keys.forEach(key => {
				if (!sendedOrder[key] && item[key] !== undefined) {
					sendedOrder[key] = item[key];
				}
			});


			const listingObj: any = {
				catalog_id: item.id,
				listing_id: listingId,
				title: _.get(item,'metadata[133]'),
				thumbnail: _.get(item,'thumbnail'),
				sku: _.get(item,'metadata[1]'),
				quantity_available: +_.get(item,'metadata[851]'),
				quantity_sold: +_.get(item,'newQuantitySold') || 1,
				cogs: +_.get(item,'metadata[847]')

			};

			const listingKeys = ['newSku', 'newItemSoldPrice', 'newQuantitySold', 'newQuantityAvailable', 'newCogs','newListingId'];
			if (listingKeys.some(key => item[key] !== undefined)) {

				listingKeys.forEach(key => {
					if (item[key] !== undefined) {
						const transformedKey = transformString(key)

						listingObj[transformedKey] = key === 'newCogs' || key === 'newQuantityAvailable'
						|| key === 'newQuantitySold' || key === 'newItemSoldPrice'
						 ? +item[key] : item[key]

					}
				});

				sendedOrder.listings.push(listingObj);
			} else {
				listingObj['catalog_id'] = _.get(item,'id')
				listingObj['listing_id'] = listingId
				listingObj['title'] = _.get(item,'metadata[133]')
				listingObj['thumbnail'] = _.get(item,'thumbnail')
				listingObj['sku'] = _.get(item,'metadata[1]')
				listingObj['quantity_available'] = +_.get(item,'metadata[851]')
				listingObj['quantity_sold'] = +_.get(item,'newQuantitySold')
				listingObj['cogs'] = +_.get(item,'metadata[847]')

				sendedOrder.listings.push(listingObj);

			}
		});

		if (sendedOrder.listings.length === 0) {
			delete sendedOrder.listings;
		}

		const uuid_order_id = uuidv4();

		payload = {
			marketplace: (sendedOrder.newSoldOn || ((platformName !== 'SOLD'|| platformName?.toLowerCase() !== 'listing party') && platformName))?.toLowerCase(),
			order_id: uuid_order_id,
			currency: 'usd',
			 date_sold: sendedOrder.newDateSold || (unescapedRowMetadata[846] && moment(unescapedRowMetadata[846]).toDate()) || today,
			...(sendedOrder.newShop) && {shop_id: sendedOrder.newShop},
			...(sendedOrder.newShippingStatus) && {shipping_status: sendedOrder.newShippingStatus},
			total_price:+sendedOrder?.newSoldPrice || (unescapedRowMetadata[852] && +unescapedRowMetadata[842]),
			...(sendedOrder.newShippingCostBuyer) && {shipping_cost_buyer: +sendedOrder.newShippingCostBuyer},
			shipping_cost_seller: +sendedOrder.newShippingCostSeller ||( unescapedRowMetadata[852] && +unescapedRowMetadata[852]),
			...(sendedOrder.newSalesTax) && {sales_tax: +sendedOrder.newSalesTax},
			fees:+sendedOrder.newFees || (unescapedRowMetadata[844] && +unescapedRowMetadata[844]),
			user_id_marketplace: 'user_id_marketplace',
			listings: sendedOrder?.listings || [],
			source: 'website',

		}
		combineOrderFetch(payload)
	}

	const combineOrderFetch = async (payload: any) => {
		try {
			const response = await instanceAxios2.post(
			 '/v2/orders/add',
			 {payload}
			);

			if (response.status === 200) {
				setIsLoading(false)
				const data = _.get(response, 'data.data');
				const orderId  = _.get(data, 'listings[0][order_id]')
				handleChangeSuccess()
				handleToggleShowSalesModal()

				const updatedTableData = tableData.map((item: any) => {
					const listing = data.listings.find((listing: any) => item.id === listing.catalog_id);
					if (listing) {
						item.isSendToSales = true
						if (Array.isArray(item.metadata[902])) {
							item.metadata[902] = [...item.metadata[902],orderId]
						} else {
							item.metadata[902] = [orderId]
						}
					}
					return item;
				});

				setTableData(updatedTableData);
			}

		} catch (error) {
			setIsLoading(false)
			console.error(error)
		}
	}

	const isButtonDisabled = () => {
		const requiredKeys = ['newSoldOn'];
		return !modalListings.every((listing: any) => {
			return requiredKeys.every((key: string) => {
				return key in listing && listing[key] !== '';
			}) || (checkPlatformInMetadata(unescapedRowMetadata) !== null)

		});
	};

	const handlePaste = (event: ClipboardEvent<HTMLInputElement>) => {
		const inputType = event.currentTarget.type;
		const pasteData = event.clipboardData?.getData('Text');

		if (inputType === 'number' && isNaN(Number(pasteData))) {
			event.preventDefault();
		}
	};

	return (
	 <div className={styles.combineItemsModalOverlay}>
		 <div
		  className={styles.modal}
		 >
			 {
				 isLoading ? (<Loader maxHeight={isLoading && '100vh'}/>)
				  : (
				   <>
					   <div className={styles.newHeaderModal}>
						   <div className={styles.modalBtns}>
							   <Button
								modifier={ModifierType.Transparent}
								style={{border:"1px solid #1E3166",color:"#1E3166"}}
								handleClick={handleToggleCombineModalShow}
							   >
								   Back
							   </Button>
							   <Button
								modifier={ModifierType.Secondary}
								disabled={isButtonDisabled()}
								handleClick={handleSendCombineItems}
								useModifierDisabledStyle={true}
							   >
								   Send to Sales & Analytics
							   </Button>
						   </div>
						   <div
							className={styles.closeBtn}
							onClick={handleToggleCombineModalShow}
						   >
							   <ModalCloseSvg/>
						   </div>
					   </div>
					   <div className={styles.combineModalWrapper}>
						   <div style={{padding: '0 30px', marginBottom: 10}}>
							   <div className={styles.outerModalTitle}>
								   <div>
									   <span className={styles.spanTitle}>Combine Items to a Single Order</span>
									   <span>
                    ({modalListings?.length})
                     </span>
								   </div>
							   </div>
							   <div
								className={styles.modalFields}>
								   {combineModalFields?.map((item: any, combineModalFieldIndex) => (
									<div
									 key={item.id}
									 className={styles.modalFieldItem}
									 style={{position: item.value === 'sold_on' ? 'relative' : 'static'}}
									>
										<label htmlFor={item.value}>
											{item.value === 'sold_on' || item.value === 'order_id' ? `${item.title} *` : item.title}
										</label>
										{item.value === 'date_sold'
										 ? (
										  <DatePicker
										   selected={selectedDate}
										   onChange={handleChange}
										   className={styles.datePicker}
										   name="newDateSold"
										  />
										 )
										 : item.value === 'shipping_status'
										  ? (
										   <Select
											styles={customStyles}
											value={selectedOption}
											options={options}
											isSearchable={false}
											onChange={dropDownChange}
										   />
										  )
										  : item.value === 'sold_on'
										   ? (
											<div className={styles.spesificInputWrapper}>

												<SoldOnPlatforms
												 index={0}
												 metadata={unescapedRowMetadata}
												 updateModalListings={updateModalListings}
												 customMarketplaces={customMarketplaces}
												 checkShopId={checkShopId}
												 handleChangeListingIdByPlatforms={handleChangeListingIdByPlatforms}
												 modalListings={modalListings}
												/>
											</div>
										   )
										   :
										   <div className={styles.spesificInputWrapper}>
											   <input
												type={checkInputType(item)}
												style={{width: item.width}}
												className={styles['sendToSales__input']}
												name={convertToCamelCase(item.value)}
												value={combineModalNewInfo[convertToCamelCase(item.value)].changed
												 ?
												 combineModalNewInfo[convertToCamelCase(item.value)].text
												 :
												 item.value === 'shop' ? _.get(modalListings[0], 'newShop') :
												  combineModalNewInfo[convertToCamelCase(item.value)].text

												  || checkInputValue(item)
												  || modalListings[0][convertToCamelCase(item.value)]}
												onChange={handleChangeInput}
												onPaste={handlePaste}
											   />
										   </div>
										}
									</div>
								   ))}
							   </div>

						   </div>
						   <div
							className={styles.wrapper}
						   >
							   {modalListings?.map((item: any, index: number) => (
								<CombineItem
								 key={item.id}
								 id={item.id}
								 index={index}
								 thumbnail={_.get(item, 'thumbnail') || ""}
								 metadata={_.get(item, 'metadata')}
								 modalListings={modalListings}
								 setModalListings={setModalListings}
								 handleDeleteListingInModal={handleDeleteListingInModal}
								 checkPlatformListingId={checkPlatformListingId}
								 modalListing={item}
								/>
							   ))}
						   </div>
					   </div>
				   </>
				  )
			 }
		 </div>

	 </div>
	)
}

export default CombineItemsModal
