import styles from './InventoryAndSales.module.scss';
import Tippy from "@tippyjs/react";
import {AddStoreIcon, CheckIcon, PopoverIcon, StorePurple,} from "../../../../assets/icons";
import {InputCount} from "../../InputCount";
import useBasisFuncs from "../../../../hooks/useBasisFuncs";
import React, {useEffect, useState} from "react";
import SalesItem1 from "../../../../assets/icons/png/SalesItem-1.png";
import SalesItem2 from "../../../../assets/icons/png/SalesItem-2.png";
import SalesItem3 from "../../../../assets/icons/png/SalesItem-3.png";
import SalesItem4 from "../../../../assets/icons/png/SalesItem-4.png";
import SalesItem5 from "../../../../assets/icons/png/SalesItem-5.png";
import SalesItem6 from "../../../../assets/icons/png/SalesItem-6.png";
import SalesItem7 from "../../../../assets/icons/png/SalesItem-7.png";
import SalesItem8 from "../../../../assets/icons/png/SalesItem-8.png";
import SalesItem9 from "../../../../assets/icons/png/SalesItem-9.png";
import SalesItem10 from "../../../../assets/icons/png/SalesItem-10.png";
import SalesItem11 from "../../../../assets/icons/png/SalesItem-11.png";
import SalesItem12 from "../../../../assets/icons/png/SalesItem-12.png";
import SalesItem13 from "../../../../assets/icons/png/SalesItem-13.png";
import SalesItem14 from "../../../../assets/icons/png/SalesItem-14.png";
import {MoonLoader} from "react-spinners";
import CustomIds from "./CustomIds";
import DatePicker from "react-datepicker";
import moment from "moment/moment";
import {useNavigate} from "react-router-dom";

export const InventoryAndSalesComponent = ({ afterCreate, hasExtensionInstall, isExtensionSupported,  salesListings, setSalesListings, dataFromExtension, setDisableSaveButtons, hasSimple, editId, setFormInfo, loaderForCustomMarkets, customMarketplacesTableRow,setCustomMarketplacesTableRow, editIndex, formInfo, unescapedRowMetadata, listInfo, itemData, setItemData, handleStandartInputChange }:any)=> {
    const { bdecode } = useBasisFuncs();

    const navigate = useNavigate();

    const salesItemsImages = [
        SalesItem1,
        SalesItem2,
        SalesItem3,
        SalesItem4,
        SalesItem5,
        SalesItem6,
        SalesItem7,
        SalesItem8,
        SalesItem9,
        SalesItem10,
        SalesItem11,
        SalesItem12,
        SalesItem13,
        SalesItem14,
    ];


    const soldPrice = formInfo?.newSold_price?.text?.length
        ? Number(formInfo?.newSold_price.text)
        : Number(unescapedRowMetadata[842] || 0);
    const shippingCost = formInfo?.newShippingCost?.text?.length
        ? Number(formInfo?.newShippingCost.text)
        : Number(unescapedRowMetadata[852] || 0);
    const cogs = formInfo?.newCogs?.text?.length
        ? Number(formInfo?.newCogs.text)
        : Number(unescapedRowMetadata[847] || 0);
    const fees = formInfo?.newFees?.text?.length
        ? Number(formInfo?.newFees.text)
        : Number(unescapedRowMetadata[844] || 0);

    const profit = soldPrice - cogs - shippingCost - fees;


    const initialDate = unescapedRowMetadata[846] ? moment(unescapedRowMetadata[846]).toDate() : moment().toDate();

    const [selectedDate, setSelectedDate] = useState(initialDate);

    const [showSoldCalendar, setShowSoldCalendar] = useState(false)

    useEffect(() => {
        const isSoldChecked = salesListings.find((listing:any) => listing.title === 'SOLD')?.checked;
        if (isSoldChecked) {
            setShowSoldCalendar(true)
        }else{
            setShowSoldCalendar(false);
        }
    }, [salesListings]);

    const handleChangeSoldDate = (date: any, name: string) => {
        setSelectedDate(date);
    };

    useEffect(() => {
     try{
         const formatedDate = moment(selectedDate).format('YYYY-MM-DD');
         if(showSoldCalendar){
             const updatedItem = [...itemData];
             updatedItem[editIndex] = {
                 ...updatedItem[editIndex],
                 ['newDateSold_date']: formatedDate,
                 changed: true,
             };
             setItemData(updatedItem)
             setFormInfo({
                 ...formInfo,
                 ['newDateSold_date']: { text: formatedDate, changed: true },
             });
         }else{
             const updatedItem = [...itemData];

             // Remove newDateSold_date if it exists
             if (updatedItem[editIndex].hasOwnProperty('newDateSold_date')) {
                 const { newDateSold_date, ...rest } = updatedItem[editIndex];
                 updatedItem[editIndex] = { ...rest, changed: true }; // Optionally keep changed true
             }

             setItemData(updatedItem);
         }
     }catch(e){
         console.log('error', e)
     }
    }, [selectedDate, showSoldCalendar]);

    const toggleSalesItem = (index: any, name: string, sendName: string, marketChecked:any) => {
        setDisableSaveButtons(false);
        setSalesListings((prevListings: any) => {
            const updatedListings = [...prevListings];
            updatedListings[index] = {
                ...updatedListings[index],
                checked: !updatedListings[index].checked,
                changed: true,
            };
            return updatedListings;
        });
        const updatedItem = [...itemData];

        updatedItem[editIndex] = {
            ...updatedItem[editIndex],
            [sendName]: marketChecked ? name : null,
            changed: true,
        };
        setItemData(updatedItem)
    };

    const handleClickMarket = (platformName: string) => {
        let activePlatformName: string | null = null;

        let isActive = false;
        let isNotOnPlatform = false;
        setDisableSaveButtons(false);

        const updatedCustomMarketplaces = customMarketplacesTableRow?.map(
            (item: any) => {
                if (item.platform.title === platformName) {
                    isActive = !item.platform.isChecked;
                    activePlatformName = platformName;
                    return {
                        ...item,
                        platform: {
                            ...item.platform,
                            isChecked: isActive,
                        },
                        notOnPlatform: {
                            ...item.notOnPlatform,
                            isChecked: false,
                        },
                    };
                } else if (item.notOnPlatform.title === platformName) {
                    isNotOnPlatform = !item.notOnPlatform.isChecked;
                    activePlatformName = item.platform.title;
                    return {
                        ...item,
                        platform: {
                            ...item.platform,
                            isChecked: false,
                        },
                        notOnPlatform: {
                            ...item.notOnPlatform,
                            isChecked: isNotOnPlatform,
                        },
                    };
                }
                return item;
            }
        );

        const updatedTableData = itemData?.map((item: any) => {
            const key = `newCustom${activePlatformName}`;

            return {
                ...item,
                changed: true,

                [key]: isNotOnPlatform
                    ? null
                    : isActive
                        ? activePlatformName === 'Listing_party'
                            ? 'Listing Party'
                            : activePlatformName
                        : null,
            };
            return item;
        });

        setItemData(updatedTableData);
        setCustomMarketplacesTableRow(updatedCustomMarketplaces);
    };

    return(
        <div>
            <div className={styles.accordionContent}>
                <div className={styles.accordionContent4}>
                    {
                        !hasSimple && <div className={styles.inputsContainerDown}>
                            <div className={styles.inputsItem}>
                                <div className={styles.inpLeftItem}>
                                    <p className={styles.heading}>
                                        Total Sold Price
                                        <Tippy
                                            interactive={true}
                                            zIndex={5}
                                            trigger="click"
                                            arrow={false}
                                            content={
                                                <div
                                                    className={`popover ${styles.popoverContent}`}
                                                >
                                                    <ul>
                                                        <li>
                                                            The total amount you collected when
                                                            the listing sold
                                                        </li>
                                                        <div className={styles.line}></div>
                                                        <p>
                                                            Add details to update{' '}
                                                            <a
                                                                href="https://app.listperfectly.com/docs/topics/inventory-management/sales-analytics/"
                                                                target="_blank"
                                                            >
                                                                Sales Analytics
                                                            </a>
                                                        </p>
                                                    </ul>
                                                </div>
                                            }
                                        >
                                            <PopoverIcon
                                                className={styles.popoverIcon}
                                                color={'#fff'}
                                            ></PopoverIcon>
                                        </Tippy>
                                    </p>
                                    <InputCount
                                        placeholder=""
                                        name="input_842"
                                        type="number"
                                        value={
                                            formInfo.newSold_price.changed
                                                ? bdecode(formInfo.newSold_price.text)
                                                : unescapedRowMetadata[842]
                                                    ? bdecode(unescapedRowMetadata[842])
                                                    : ''
                                        }
                                        limit={500}
                                        height={40}
                                        hideCount={true}
                                        handleChange={handleStandartInputChange}
                                    />
                                </div>
                                <div className={styles.inpRightItem}>
                                    <p className={styles.heading}>
                                        Shipping
                                        <Tippy
                                            interactive={true}
                                            zIndex={5}
                                            trigger="click"
                                            arrow={false}
                                            content={
                                                <div
                                                    className={`popover ${styles.popoverContent}`}
                                                >
                                                    <ul>
                                                        <li>
                                                            What you paid to ship the listing to
                                                            the customer
                                                        </li>
                                                        <div className={styles.line}></div>
                                                        <p>
                                                            Add details to update{' '}
                                                            <a
                                                                href="https://app.listperfectly.com/docs/topics/inventory-management/sales-analytics/"
                                                                target="_blank"
                                                            >
                                                                Sales Analytics
                                                            </a>
                                                        </p>
                                                    </ul>
                                                </div>
                                            }
                                        >
                                            <PopoverIcon
                                                className={styles.popoverIcon}
                                                color={'#fff'}
                                            ></PopoverIcon>
                                        </Tippy>
                                    </p>
                                    <InputCount
                                        placeholder=""
                                        name="input_852"
                                        type="number"
                                        value={
                                            formInfo?.newShippingCost.changed
                                                ? bdecode(formInfo?.newShippingCost.text)
                                                : unescapedRowMetadata[852]
                                                    ? bdecode(unescapedRowMetadata[852])
                                                    : ''
                                        }
                                        limit={500}
                                        height={40}
                                        hideCount={true}
                                        handleChange={handleStandartInputChange}
                                    />
                                </div>
                                <div className={styles.inpRightItem}>
                                    <p className={styles.heading}>
                                        Fees or Other Costs
                                        <Tippy
                                            interactive={true}
                                            zIndex={5}
                                            trigger="click"
                                            arrow={false}
                                            content={
                                                <div
                                                    className={`popover ${styles.popoverContent}`}
                                                >
                                                    <ul>
                                                        <li>
                                                            Include any other costs related to
                                                            what selling this listing cost you,
                                                            including marketplace fees, etc
                                                        </li>
                                                        <div className={styles.line}></div>
                                                        <p>
                                                            Add details to update{' '}
                                                            <a
                                                                href="https://app.listperfectly.com/docs/topics/inventory-management/sales-analytics/"
                                                                target="_blank"
                                                            >
                                                                Sales Analytics
                                                            </a>
                                                        </p>
                                                    </ul>
                                                </div>
                                            }
                                        >
                                            <PopoverIcon
                                                className={styles.popoverIcon}
                                                color={'#fff'}
                                            ></PopoverIcon>
                                        </Tippy>
                                    </p>
                                    <InputCount
                                        placeholder=""
                                        name="input_844"
                                        type="number"
                                        value={
                                            formInfo?.newFees.changed
                                                ? bdecode(formInfo?.newFees.text)
                                                : unescapedRowMetadata[844]
                                                    ? bdecode(unescapedRowMetadata[844])
                                                    : ''
                                        }
                                        limit={500}
                                        height={40}
                                        hideCount={true}
                                        handleChange={handleStandartInputChange}
                                    />
                                </div>
                                <div className={styles.cogsAndProfit}>
                                    <div className={styles.inpRightItem}>
                                        <p className={styles.heading}>
                                            COGS
                                            <Tippy
                                                interactive={true}
                                                zIndex={5}
                                                trigger="click"
                                                arrow={false}
                                                content={
                                                    <div
                                                        className={`popover ${styles.popoverContent}`}
                                                    >
                                                        <ul>
                                                            <li>
                                                                Referenced from COGS field in
                                                                Pricing section
                                                            </li>
                                                            <div className={styles.line}></div>
                                                            <p>
                                                                Add details to update{' '}
                                                                <a
                                                                    href="https://app.listperfectly.com/docs/topics/inventory-management/sales-analytics/"
                                                                    target="_blank"
                                                                >
                                                                    Sales Analytics
                                                                </a>
                                                            </p>
                                                        </ul>
                                                    </div>
                                                }
                                            >
                                                <PopoverIcon
                                                    className={styles.popoverIcon}
                                                    color={'#fff'}
                                                ></PopoverIcon>
                                            </Tippy>
                                        </p>
                                        <p className={styles.amount}>
                                            $
                                            {formInfo?.newCogs.changed
                                                ? bdecode(formInfo?.newCogs.text)
                                                : unescapedRowMetadata[847]
                                                    ? bdecode(unescapedRowMetadata[847])
                                                    : '0.00'}
                                        </p>
                                    </div>
                                    <div className={styles.equal}>=</div>
                                    <div className={styles.inpRightItem}>
                                        <p className={styles.heading}>
                                            Profit
                                            <Tippy
                                                interactive={true}
                                                zIndex={5}
                                                trigger="click"
                                                arrow={false}
                                                content={
                                                    <div
                                                        className={`popover ${styles.popoverContent}`}
                                                    >
                                                        <ul>
                                                            <li>
                                                                Profit is calculated by subtracting
                                                                Cost of Goods, Fees, and Shipping
                                                                from Total Sold Price
                                                            </li>
                                                            <div className={styles.line}></div>
                                                            <p>
                                                                Add details to update{' '}
                                                                <a
                                                                    href="https://app.listperfectly.com/docs/topics/inventory-management/sales-analytics/"
                                                                    target="_blank"
                                                                >
                                                                    Sales Analytics
                                                                </a>
                                                            </p>
                                                        </ul>
                                                    </div>
                                                }
                                            >
                                                <PopoverIcon
                                                    className={styles.popoverIcon}
                                                    color={'#fff'}
                                                ></PopoverIcon>
                                            </Tippy>
                                        </p>
                                        <p className={styles.amount}>
                                            {`$${profit.toFixed(2)}`}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {
                        showSoldCalendar && <div className={styles.soldDateCalendar}>
                            <DatePicker
                                selected={selectedDate}
                                onChange={handleChangeSoldDate}
                                name="newDateSold_date"
                            />
                        </div>
                    }

                    <p className={styles.heading}>
                        Select Sold if Your Listing Has Been Sold
                    </p>
                    <div className={styles.salesListingsContainer}>
                        {salesListings.map((listing: any, index: any) => (
                            <div
                                key={listing.id}
                                className={`${(listing.title === 'SOLD' && editId) ? 'lp_custom_chrome_extension_edit_page_mark_sold_button' : ''} ${styles.salesItem} ${
                                    listing.checked ? `${styles.selectedSalesItem}` : ''
                                }`}
                                onClick={() => {
                                    if (listing.title === 'SOLD' && hasExtensionInstall && isExtensionSupported && editId && !afterCreate) return
                                    toggleSalesItem(
                                        index,
                                        listing.title,
                                        listing.placeholder,
                                        !listing.checked
                                    )
                                 }
                                }
                            >
                                {listing.checked && (
                                    <CheckIcon className={styles.checkIcon}/>
                                )}
                                <img
                                    src={salesItemsImages[index]}
                                    alt={listing.title}
                                />
                                <p>{listing.title}</p>
                                {/* Add other properties or elements you want to display */}
                            </div>
                        ))}
                    </div>
                    {
                        !hasSimple && <div className={styles.customMarketplacesContainer}>
                            <div className={styles.title}>
                                Add custom marketplace for inventory management
                                <Tippy
                                    interactive={true}
                                    zIndex={5}
                                    trigger="click"
                                    arrow={false}
                                    content={
                                        <div
                                            className={`popover ${styles.popoverContent}`}
                                        >
                                            Use for platforms, for example your own
                                            website, that are not integrated with List
                                            Perfectly. When adding links, add the full url
                                            to create a shortcut to your listing. Please
                                            note: custom marketplaces cannot be ended
                                            using List Perfectly’s "End Listing" or "mark
                                            SOLD" features.
                                            <br/>
                                            <a
                                                href="https://app.listperfectly.com/docs/topics/inventory-management/adding-custom-marketplace/">
                                                Learn more.
                                            </a>
                                        </div>
                                    }
                                >
                                    <PopoverIcon
                                        className={styles.popoverIcon}
                                        color={'#fff'}
                                    ></PopoverIcon>
                                </Tippy>
                            </div>
                            {
                                loaderForCustomMarkets ? (<div className={styles.customsLoader}><MoonLoader/></div>) : (
                                    <div className={styles.customMarketItems}>
                                        {customMarketplacesTableRow?.map((market: any) => (
                                            <div
                                                className={`${styles.marketItem} ${
                                                    market?.platform?.isChecked
                                                        ?  `${styles.activeMarket}`
                                                        : ''
                                                }`}
                                                onClick={() => {
                                                    handleClickMarket(market?.platform?.title);
                                                }}
                                                style={{
                                                    border:
                                                        market?.platform?.customColor &&
                                                        !market?.platform?.isChecked
                                                            ? `1px solid ${market?.platform?.customColor}`
                                                            : market?.platform?.isChecked
                                                                ? 'none'
                                                                : '1px solid #000',
                                                }}
                                            >
                                                {market?.platform?.isChecked && (
                                                    <CheckIcon className={styles.checkIcon}/>
                                                )}
                                                <StorePurple
                                                    className={styles.storeIcon}
                                                    fill={
                                                        market?.platform?.customColor
                                                            ? market?.platform?.customColor
                                                            : '#000'
                                                    }
                                                />
                                                <p>{market?.platform?.title}</p>
                                            </div>
                                        ))}

                                        <div
                                            className={styles.marketItemAdd}
                                            onClick={() => {
                                                navigate('/settings?page=website')
                                            }}
                                        >
                                            <AddStoreIcon/>
                                        </div>
                                    </div>
                                )
                            }


                        </div>
                    }

                    {/*{showCustomMarketsModal && (*/}
                    {/*    <CustomMarketsModal*/}
                    {/*        customMarketplaces={customMarketplaces}*/}
                    {/*        setCustomsChanged2={setCustomsChanged2}*/}
                    {/*        setSaveProgress={setSaveProgress}*/}
                    {/*        setCustomMarketPlacesChanges={*/}
                    {/*            setCustomMarketPlacesChanges*/}
                    {/*        }*/}
                    {/*        setCustomMarketsModal={setCustomMarketsModal}*/}
                    {/*        setCustomMarketplacesTableRow={setCustomMarketplacesTableRow}*/}
                    {/*    />*/}
                    {/*)}*/}

                    <div className={styles.title}>
                        Platform IDs
                        <Tippy
                            interactive={true}
                            zIndex={5}
                            trigger="click"
                            arrow={false}
                            content={
                                <div
                                    className={`popover ${styles.popoverContent}`}
                                >
                                    <ul>
                                        <li>
                                            <a
                                                href="https://app.listperfectly.com/docs/topics/inventory-management/item-numbers-101/"
                                                target="_blank"
                                            >
                                                Saved IDs
                                            </a>{' '}
                                            below are what link your active listings to
                                            your LP catalog listing.
                                        </li>
                                        <li>
                                            Platform IDs and item numbers are gathered
                                            when importing to LP and when copying from
                                            LP to supported marketplaces.
                                        </li>
                                        <li>
                                            You can also{' '}
                                            <a
                                                href="https://app.listperfectly.com/docs/topics/inventory-management/managing-item-numbers-all-plans/"
                                                target="_blank"
                                            >
                                                manually update
                                            </a>{' '}
                                            and link active listings to change statuses.
                                        </li>
                                    </ul>
                                </div>
                            }
                        >
                            <PopoverIcon
                                className={styles.popoverIcon}
                                color={'#fff'}
                            ></PopoverIcon>
                        </Tippy>
                    </div>
                    <div className={styles.inputsItem}>
                        <div className={styles.inpLeftItem}>
                            <p className={styles.heading}>eBay Item #</p>
                            <InputCount
                                placeholder=""
                                name="input_618"
                                value={
                                    formInfo.newPlatformIdByeBay.changed
                                        ? bdecode(
                                            formInfo.newPlatformIdByeBay.text
                                        )
                                        : unescapedRowMetadata[618]
                                            ? bdecode(unescapedRowMetadata[618])
                                            : ''
                                }
                                limit={500}
                                height={40}
                                hideCount={true}
                                handleChange={handleStandartInputChange}
                            />
                        </div>
                        <div className={styles.inpRightItem}>
                            <p className={styles.heading}>Etsy Item #</p>
                            <InputCount
                                placeholder=""
                                name="input_619"
                                value={
                                    formInfo.newPlatformIdByEtsy.changed
                                        ? bdecode(
                                            formInfo.newPlatformIdByEtsy.text
                                        )
                                        : unescapedRowMetadata[619]
                                            ? bdecode(unescapedRowMetadata[619])
                                            : ''
                                }
                                limit={500}
                                height={40}
                                hideCount={true}
                                handleChange={handleStandartInputChange}
                            />
                        </div>
                        <div className={styles.inpRightItem}>
                            <p className={styles.heading}>Poshmark Item #</p>
                            <InputCount
                                placeholder=""
                                name="input_620"
                                value={
                                    formInfo.newPlatformIdByPoshmark.changed
                                        ? bdecode(
                                            formInfo.newPlatformIdByPoshmark.text
                                        )
                                        : unescapedRowMetadata[620]
                                            ? bdecode(unescapedRowMetadata[620])
                                            : ''
                                }
                                limit={500}
                                height={40}
                                hideCount={true}
                                handleChange={handleStandartInputChange}
                            />
                        </div>
                        <div className={styles.inpRightItem}>
                            <p className={styles.heading}>Mercari Item #</p>
                            <InputCount
                                placeholder=""
                                name="input_621"
                                value={
                                    formInfo.newPlatformIdByMercari.changed
                                        ? bdecode(
                                            formInfo.newPlatformIdByMercari.text
                                        )
                                        : unescapedRowMetadata[621]
                                            ? bdecode(unescapedRowMetadata[621])
                                            : ''
                                }
                                limit={500}
                                height={40}
                                hideCount={true}
                                handleChange={handleStandartInputChange}
                            />
                        </div>
                        <div className={styles.inpRightItem}>
                            <p className={styles.heading}>Amazon Item #</p>
                            <InputCount
                                placeholder=""
                                name="input_836"
                                value={
                                    formInfo.newPlatformIdByAmazon.changed
                                        ? bdecode(
                                            formInfo.newPlatformIdByAmazon.text
                                        )
                                        : unescapedRowMetadata[836]
                                            ? bdecode(unescapedRowMetadata[836])
                                            : ''
                                }
                                limit={500}
                                height={40}
                                hideCount={true}
                                handleChange={handleStandartInputChange}
                            />
                        </div>
                        <div className={styles.inpRightItem}>
                            <p className={styles.heading}>Grailed Item #</p>
                            <InputCount
                                placeholder=""
                                name="input_839"
                                value={
                                    formInfo.newPlatformIdByGrailed.changed
                                        ? bdecode(
                                            formInfo.newPlatformIdByGrailed.text
                                        )
                                        : unescapedRowMetadata[839]
                                            ? bdecode(unescapedRowMetadata[839])
                                            : ''
                                }
                                limit={500}
                                height={40}
                                hideCount={true}
                                handleChange={handleStandartInputChange}
                            />
                        </div>
                        <div className={styles.inpRightItem}>
                            <p className={styles.heading}>Facebook Item #</p>
                            <InputCount
                                placeholder=""
                                name="input_840"
                                value={
                                    formInfo.newPlatformIdByFacebook.changed
                                        ? bdecode(
                                            formInfo.newPlatformIdByFacebook.text
                                        )
                                        : unescapedRowMetadata[840]
                                            ? bdecode(unescapedRowMetadata[840])
                                            : ''
                                }
                                limit={500}
                                height={40}
                                hideCount={true}
                                handleChange={handleStandartInputChange}
                            />
                        </div>
                        <div className={styles.inpRightItem}>
                            <p className={styles.heading}>Depop Item #</p>
                            <InputCount
                                placeholder=""
                                name="input_841"
                                value={
                                    formInfo.newPlatformIdByDepop.changed
                                        ? bdecode(
                                            formInfo.newPlatformIdByDepop.text
                                        )
                                        : unescapedRowMetadata[841]
                                            ? bdecode(unescapedRowMetadata[841])
                                            : ''
                                }
                                limit={500}
                                height={40}
                                hideCount={true}
                                handleChange={handleStandartInputChange}
                            />
                        </div>
                        <div className={styles.inpRightItem}>
                            <p className={styles.heading}>Kidizen Item #</p>
                            <InputCount
                                placeholder=""
                                name="input_853"
                                value={
                                    formInfo.newPlatformIdByKidizen.changed
                                        ? bdecode(
                                            formInfo.newPlatformIdByKidizen.text
                                        )
                                        : unescapedRowMetadata[853]
                                            ? bdecode(unescapedRowMetadata[853])
                                            : ''
                                }
                                limit={500}
                                height={40}
                                hideCount={true}
                                handleChange={handleStandartInputChange}
                            />
                        </div>
                        <div className={styles.inpRightItem}>
                            <p className={styles.heading}>Shopify Item #</p>
                            <InputCount
                                placeholder=""
                                name="input_855"
                                value={
                                    formInfo.newPlatformIdByShopify.changed
                                        ? bdecode(
                                            formInfo.newPlatformIdByShopify.text
                                        )
                                        : unescapedRowMetadata[855]
                                            ? bdecode(unescapedRowMetadata[855])
                                            : ''
                                }
                                limit={500}
                                height={40}
                                hideCount={true}
                                handleChange={handleStandartInputChange}
                            />
                        </div>
                        <div className={styles.inpRightItem}>
                            <p className={styles.heading}>Instagram Item #</p>
                            <InputCount
                                placeholder=""
                                name="input_856"
                                value={
                                    formInfo.newPlatformIdByInstagram.changed
                                        ? bdecode(
                                            formInfo.newPlatformIdByInstagram.text
                                        )
                                        : unescapedRowMetadata[856]
                                            ? bdecode(unescapedRowMetadata[856])
                                            : ''
                                }
                                limit={500}
                                height={40}
                                hideCount={true}
                                handleChange={handleStandartInputChange}
                            />
                        </div>
                        <div className={styles.inpRightItem}>
                            <p className={styles.heading}>Vestiaire Item #</p>
                            <InputCount
                                placeholder=""
                                name="input_885"
                                value={
                                    formInfo.newPlatformIdByVestiaire.changed
                                        ? bdecode(
                                            formInfo.newPlatformIdByVestiaire.text
                                        )
                                        : unescapedRowMetadata[885]
                                            ? bdecode(unescapedRowMetadata[885])
                                            : ''
                                }
                                limit={500}
                                height={40}
                                hideCount={true}
                                handleChange={handleStandartInputChange}
                            />
                        </div>

                        {(!hasSimple &&  customMarketplacesTableRow?.length > 0) &&
                            customMarketplacesTableRow?.map((item: any) =>
                                item?.platform?.title?.length > 0 ? (
                                    <CustomIds title={item?.platform?.title}
                                               setItemData={setItemData}
                                               itemData={itemData}
                                               editIndex={editIndex}
                                               setNewTableInfo={setFormInfo}
                                               unescapedRowMetadata={unescapedRowMetadata}
                                               id={item?.platform?.platformId}
                                               newTableInfo={formInfo}
                                    />
                                ): null)
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}