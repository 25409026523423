import {
	AddListingSVG, CircleExclamationSolidSVG, CommentsDollarSVG,
	CommunityQuickLink, ContactUsSVG,
	DesktopSVG,
	ListingsSVG, LPIconQuickLinkSVG, MyAccountQuickLinkSVG,
	NewsQuickLinkSVG, OptProPlus,
	QuickStartGuideSVG
} from "@assets/icons";

const quickLinks = [

	{
		id: 5,
		title: 'My Listings',
		link: "/listings",
		icon: <ListingsSVG/>
	},
	{
		id: 6,
		title: 'Add Listing',
		link: "/listings/add",
		icon: <AddListingSVG/>
	},
	{
		id: 11,
		title: 'Listing Party',
		link: "https://listingparty.com/",
		icon: <CommunityQuickLink/>
	},
	{
		id: 19,
		title: 'Quick Start Guide',
		link: "https://help.listperfectly.com/en/collections/8205657-getting-started",
		icon: <QuickStartGuideSVG/>
	},
	{
		id: 8,
		title: 'Software',
		link: "https://help.listperfectly.com/en/articles/8913965",
		icon: <DesktopSVG/>
	},
	{
		id: 4,
		title: 'News',
		link: "",
		icon: <NewsQuickLinkSVG/>
	},

	{
		id: 7,
		title: 'Current Issues',
		link: "https://help.listperfectly.com/en/articles/9039723-current-technical-issue ",
		icon:<CircleExclamationSolidSVG/>
	},
	{
		id: 10,
		title: 'Referral Program',
		link: "https://help.listperfectly.com/en/articles/9047288-unlock-infinite-earnings-with-the-list-perfectly-referral-program",
		icon: <CommentsDollarSVG/>
	},

	{
		id: 9,
		title: 'My Account',
		link: "https://app.listperfectly.com/account/?action=subscriptions",
		icon:<MyAccountQuickLinkSVG/>
	},
	{
		id: 12,
		title: 'Contact Us',
		link: "",
		icon: <ContactUsSVG/>
	},
	{
		id: 2,
		title: 'Guide to LP',
		link: "https://help.listperfectly.com/en/",
		icon:<LPIconQuickLinkSVG/>
	},
	{
		id: 1,
		title: 'Opt Into Pro Plus',
		link: "https://help.listperfectly.com/en/articles/9512203-access-more-features-with-pro-plus ",
		icon:<OptProPlus/>
	},
]


export default quickLinks