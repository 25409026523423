import React from 'react';
import styles from './Checkbox.module.scss';

export enum CheckboxModifier {
    Sticky = 'sticky',
    Primary = 'primary',
}

interface ICheckboxProps {
    name: string;
    checked?: boolean;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    modifier?: CheckboxModifier | "";
    disabled?: boolean;
    checkboxStyle?: React.CSSProperties;
    labelStyle?: React.CSSProperties;
    visibility?: boolean; // Visibility toggle
    defaultStickyCheckbox?: boolean;
    label?: string; // Optional label
    labelDirection?: 'left' | 'right'; // Label direction
}

const Checkbox: React.FC<ICheckboxProps> = ({
      name,
      checked = false,
      onChange,
      modifier,
      disabled = false,
      checkboxStyle,
      labelStyle,
      visibility = true,
      defaultStickyCheckbox,
      label,
      labelDirection = 'right',
      }) => {
    // Utility to join class names safely
    const classNames = (...classes: (string | undefined | false)[]) =>
        classes.filter(Boolean).join(' ');

    const finalClassName = classNames(
        styles.checkbox,
        modifier ? styles[`checkbox--${modifier}`] : undefined,
        defaultStickyCheckbox ? styles[`checkbox--default-sticky`] : undefined,
        disabled ? styles[`checkbox--disabled`] : undefined
    );

    const inputProps: React.InputHTMLAttributes<HTMLInputElement> = {
        type: 'checkbox',
        name,
        checked,
        onChange,
        className: finalClassName,
        style: {
            ...checkboxStyle,
            visibility: visibility ? 'visible' : 'hidden', // Valid visibility values
        },
        disabled,
        ...(label && { id: name }), // Add `id` only if `label` exists
    };

    const inputElement = <input {...inputProps} />;

    if (!label) {
        return inputElement;
    }

    return (
        <div className={styles.checkboxContainer}>
            {labelDirection === 'left' && (
                <label
                    htmlFor={name}
                    className={styles.checkboxLabel}
                    style={{
                        cursor: disabled ? 'not-allowed' : 'pointer',
                        ...labelStyle,
                    }}
                >
                    {label}
                </label>
            )}
            {inputElement}
            {labelDirection === 'right' && (
                <label
                    htmlFor={name}
                    className={styles.checkboxLabel}
                    style={{
                        cursor: disabled ? 'not-allowed' : 'pointer',
                        ...labelStyle,
                    }}
                >
                    {label}
                </label>
            )}
        </div>
    );
};

export default Checkbox;
