import ScanComponent from "../../../UI/ScanComponent";
import React from "react";
import styles from './ModalForScan.module.scss';
import {MoonLoader} from "react-spinners";

const ModalForScan = ({setScanLoader, scanLoader, setShowScanModal, sendRequestForScan}:any) => {
    return(
        <div className={styles.scanModalMainContainer}>
            <div className={styles.scanModalInner}>
                {
                    scanLoader ? (
                        <div className={styles.assistantLoader}>
                            {' '}
                            <MoonLoader color="#1e3166" loading={true} size={48} />
                        </div>
                    ) : (
                        <ScanComponent
                            setScanShow={setShowScanModal}
                            sendRequestForScan={sendRequestForScan}
                        />
                    )
                }
            </div>
        </div>
    )
}

export default ModalForScan;