import { useState, useEffect } from 'react';

function useIsMobile() {
	const [isMobileScreen, setIsMobileScreen] = useState(window.innerWidth < 900);

	useEffect(() => {
		const handleResize = () => {
			setIsMobileScreen(window.innerWidth < 900);
		};

		window.addEventListener('resize', handleResize);

		handleResize();

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	return isMobileScreen;
}

export default useIsMobile;
