import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import styles from './CatalogInModal.module.scss';

import _ from 'lodash';

import {FaImage, LinkedPlus, LinkSolidSvg, ModalCloseSvg, SoldTagSvg} from '../../../../assets/icons';
import useDebounce from '../../../../hooks/UseDebounce';
import Loader from '../../../../components/Templates/Loader';
import ListingInModal from './ListingInModal';
import useBasicFuncs from '../../../../hooks/useBasisFuncs';
import Listings from "../../../../services/Listings";
import getToken from "../../../../utils/getToken";

// Importing services
import AuthService from '../../../../services/Auth';
import OrdersService from '../../../../services/Orders';
import Button, {ModifierType} from "../../../../components/UI/Button";
import UseIsSmallLaptop from "@hooks/UseIsSmallLaptop";

const imgPrefix = 'https://s3.amazonaws.com/listperfectly.orders';

interface ICatalogInModal {
  handleToogleShowCatalogModalShow: () => void;
  selectedListing:any;
  orderId: number;
}

const CatalogInModal = (props: ICatalogInModal) => {
  const {
    handleToogleShowCatalogModalShow,
    selectedListing,
    orderId,
  } = props;
  const isSmallScreenLaptop = UseIsSmallLaptop() ;
  const { thumbnail, title } = selectedListing;

  const { bdecode } = useBasicFuncs();
  const token:string = getToken() || "" ;

  const listingId = _.get(selectedListing,'id')
  const listingTitleLong =   _.get(title, 'long') || (_.get(selectedListing,'title') || '');
  const words = typeof listingTitleLong === 'string' ? listingTitleLong.split(' ') : [];
  const firstThreeWord = words?.slice(0, words?.length)?.join(' ');

  const [dimensions, setDimensions] = useState<string | null>(null);
  const [modalInput, setModalInput] = useState(bdecode(firstThreeWord));
  const [modalListings, setModalListings] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isNoResults, setIsNoResults] = useState(false);
  const [linkedListing, setLinkedListing] = useState<any>(null);
  const [isSearch, setIsSearch] = useState(false);

  const catalogInModalRef = useRef<HTMLDivElement | null>(null);
  const modalInnerRef = useRef<any>(null)

  const handleChangeModalInput = (e: any) => {
    setIsSearch(true);
    setModalInput(e.target.value);
  };

  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      if (
        catalogInModalRef.current &&
        !catalogInModalRef.current?.contains(event.target as Node)) {
        handleToogleShowCatalogModalShow();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [catalogInModalRef, handleToogleShowCatalogModalShow]);

  useEffect(() => {
    const getListingByTitle = async () => {
      try {
        const options = {include_thumbnail: true};
        const metadata = {offset: 0, limit: 10};
        const filter_by = { 133: firstThreeWord };

        const response:any = await Listings.list(filter_by, metadata, options, null,token)
        const { data } = response;

        setIsLoading(false)

        if (!data.length) {
          setIsNoResults(true);
        }

        setModalListings(data);

      } catch (error) {
        setIsLoading(false)
        setIsNoResults(true);
        console.error(error);
      }
    };

    if (listingTitleLong) {
      getListingByTitle();
    } else {
      setIsLoading(false)
      setIsNoResults(true)
    }
  }, []);

  const debounceSearchItem = useDebounce(modalInput, 1000);

  useEffect(() => {
    if (debounceSearchItem && isSearch) {
      setIsNoResults(false);
      getListingForSearch();
    } else {
      setModalListings([]);
    }
  }, [debounceSearchItem]);

  useEffect(() => {
    const catalogMetadata = _.get(selectedListing,'catalog_metadata')

    if(catalogMetadata) {
      const getLinkedListingById  = async () => {
        const catalogListingID =  _.get(selectedListing,'catalog_id')  // * catalog_id
        const filter_by = {id:catalogListingID}
        const metadata = {offset:0, limit:1}
        const options = {include_thumbnail:true}

        // TODO add error handler in the future
        const response:any = await Listings.list(filter_by, metadata,options,null,  token)
        const data = response.data ;
        const linkedListing = _.get(data,'[0]')

        setLinkedListing(linkedListing)
      }
      getLinkedListingById()
    }
  }, []);

  const getListingForSearch = async () => {
    setIsNoResults(false);
    setIsLoading(true);

    try {
      const options = {include_thumbnail: true} ;
      const metadata = {offset: 0, limit: 10} ;
      const filter_by = { 133: modalInput };

      // TODO add error handler in the future
      const response:any = await Listings.list(filter_by,metadata, options, null, token)
      const { data } = response ;

      setIsLoading(false) ;

      if(!data.length) {
        setIsNoResults(true)
      }

      setModalListings(data)

    } catch (error) {
      setIsLoading(false);
      setIsNoResults(true);

      console.error(error);
    }
  };

  const handleRedirectToListings = () => {
    window.open(`https://my.listperfectly.com/listings/view/${linkedListing.id}`, '_blank'); // TODO add hostname check later
  }


  const handleListingUnlinking = async () => {

    try {

      // Save to the database
      await OrdersService.listings_update(AuthService.get_token(), { id: listingId }, { catalog_id: null });

      // Set linked listing to null and something else
      setLinkedListing(null);
      setIsNoResults(false);

    } catch (error) {
      // TODO add proper error handling here
      console.error(error);
    }

  };

  const filteredModalListings =
   modalListings?.filter((fModalListing:any) => _.get(fModalListing,'id') !== _.get(linkedListing,'id'))

  useEffect(() => {
    if(!filteredModalListings.length)  {

    setIsNoResults(true)
    }
  }, [filteredModalListings]);

  useLayoutEffect(() => {
    const getImageDimensions = (src: any) => {
      try {
        if (src ) {
          const img = new Image();
          img.src = src.thumbnail.includes(imgPrefix) ? src.thumbnail : `${imgPrefix}/${src.thumbnail}`

          img.onload = () => {
            if (img.width >= img.height) {
              setDimensions('width');
            } else {
              setDimensions('height');
            }
          };
        }
      } catch (e) {
        console.log('e');
      }
    };
    getImageDimensions( selectedListing );
  }, []);

  const linkOrderThumbnail = (_.get(selectedListing,'thumbnail') || thumbnail)

  const linkOrderThumbnailSrc = linkOrderThumbnail?.includes(imgPrefix)
   ? linkOrderThumbnail
   : `${imgPrefix}/${linkOrderThumbnail}`

  const scrollToTop = () => {
    if(modalInnerRef.current) {
      modalInnerRef.current.scrollTo({top:0})
    }
  };

  return (
    <div className={styles.catalogInModalOverlay}>
      <div
        ref={catalogInModalRef}
        className={styles.modal}>
        <div className={styles.modalHeader}>
          <div className={styles.modalWrapper}>
            <div className={styles.thumbnail}>
              {_.get(selectedListing, 'thumbnail') ? <img
               style={{
                 width: dimensions === 'width' ? (!isSmallScreenLaptop ? '90px' : '72px') : 'auto',
                 height: dimensions === 'height' ? (!isSmallScreenLaptop ? '90px' : '72px') : 'auto',
               }}
               src={linkOrderThumbnailSrc}
               alt=""
              /> : <FaImage/>}
            </div>

            <h3>{bdecode(listingTitleLong)}</h3>
          </div>
          <ModalCloseSvg className={styles.closeBtn} onClick={handleToogleShowCatalogModalShow}/>

        </div>
        <div
         className={styles.line}></div>
        <div
         ref={modalInnerRef}
         className={styles.modalInner}>
          {linkedListing && <div  className={styles.linkedBlock}>
            <div className={styles.linkedHeader}>
              <LinkedPlus/>
              <h2>Linked</h2>
            </div>
            <div className={styles.linkedListing}>
              <div className={styles.left}>
                <div className={styles.thumbnail}>
                  {_.get(linkedListing, 'thumbnail') ? <img
                    style={{
                      width: dimensions === 'width' ? (!isSmallScreenLaptop ? '70px' : '56px') : 'auto',
                      height: dimensions === 'height'? (!isSmallScreenLaptop ? '70px' : '56px') : 'auto',
                    }}
                    src={_.get(linkedListing, 'thumbnail')}/>
                   : <div className={styles.noThumbnail}></div>}
                </div>
                <h3>
                  {
                    <a
                     target="_blank">{_.get(linkedListing, 'metadata') && bdecode(_.get(linkedListing, 'metadata[133]'))}
                    </a>
                  }
                </h3>
              </div>
              <div className={styles.right}>
                {_.get(linkedListing, 'metadata') && _.get(linkedListing, 'metadata[539.1]') === 'SOLD' && (
                 <div className={styles.soldIcon}>
                   <SoldTagSvg/>
                 </div>
                )}
                <div className={styles.btnsWrapper}>
                  <Button style={{
                    color:'#1E3166',
                    border:'1px solid #1E3166'
                  }} handleClick={handleRedirectToListings} modifier={ModifierType.Transparent}>View</Button>

                  <Button  style={{color: '#FF4D4D', border:'1px solid #FF4D4D'}} handleClick={handleListingUnlinking} modifier={ModifierType.Transparent}>Unlink</Button>
                </div>

              </div>
            </div>
          </div>}

          <div className={styles.linkText}>
            <div className={styles.heading}>
              <LinkSolidSvg/>
              <h2>{!linkedListing || filteredModalListings?.length === 0  ? 'Link Listing to List Perfectly' : 'Replace Linked Listing'}</h2>
            </div>
            <p>
              Select a listing below to link, or search your listings.
            </p>
          </div>
          <div className={styles.inputBlock}>
            <input
             type="text"
             className="myInput"
             value={modalInput}
             onChange={handleChangeModalInput}
            />
          </div>
          {!isLoading ? (
           <div className={styles.modalListingWrapper}>
             {!isLoading && isNoResults && filteredModalListings.length === 0 && <h3 className={styles.noResult}> No results. <a target="_blank" href="/listings/express">Add a listing</a></h3>}
             {filteredModalListings?.map((listing: any) => (
              <ListingInModal
               listing={listing}
               linkedListing={linkedListing}
               setLinkedListing={setLinkedListing}
               listingId={listingId}
               orderId={orderId}
               scrollToTop={scrollToTop}
              />
             ))}
           </div>
          ) : (
           <div className={styles.loader}>
             <Loader/>
           </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CatalogInModal;
