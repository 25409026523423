import React from 'react';
import styles from './MarketModalMobile.module.scss';
import {CloseSvg, PlatformIconShop} from "../../../../../assets/icons";
import ColorPicker from "../../ColorPicker";
import Button, {ModifierType} from "../../../../../components/UI/Button";

const MarketModalMobile = ({
							   setOpenMarketModalMobile,
							   setIsEdit,
							   isEdit,
							   colorPickerColor,
							   customMarketNameValue,
							   setColorPickerColor,
							   setCustomMarketNameValue,
							   setCharCount,
							   updateCustomMarket,
							   setIsSaved,
							   setIsCanceled,
							   handleDelete,
							   handleCancel,
							   isMobile,
							   addNewCustom,
							   handleDeleteNewCustom,
							   setShowCustomObject,
							   setCustomMarketNameValueNew,
							   setColorPickerColorNew,
							   setAllowAdd,
							   handleSaveNewCustom,
							   colorPickerColorNew,
							   setCharCountNewCustom,
							   charCountNewCustom,
							   customMarketNameValueNew
						   }: any) => {

	const handleCloseMarketModal = ()=>{
		setOpenMarketModalMobile(false);
		if(!addNewCustom){
			setIsEdit(!isEdit);
			handleCancel();
		}else{
			setShowCustomObject(false);
			setCustomMarketNameValueNew('');
			setColorPickerColorNew('#000');
			setAllowAdd(true);
		}

	}
	const handleChangeCustomMarketValue = (value: string) => {
		if (addNewCustom && value.length <= 11) {
			setCustomMarketNameValueNew(value);
			setCharCountNewCustom(value.length);
		}else if(value.length <= 11 && !addNewCustom){
			setCustomMarketNameValue(value);
			setCharCount(value.length);
		}
	};
	const handleChangeColorPickerColor = (color: string) => {
		if(addNewCustom){
			setColorPickerColorNew(color);
		}else{
			setColorPickerColor(color);
		}

	};
	const handleSave = () => {
		if( addNewCustom){
			handleSaveNewCustom();
		}else{
			updateCustomMarket();
			setIsEdit(false);
			setIsSaved(true);
			setIsCanceled(false);
		}

	};

	return (
	 <div className={styles.customMarketModalContainer}>
		 <div className={styles.customMarketModal}>
			 <p className={styles.editCustomMarket}> Edit Custom Marketplace</p>
			 <div className={styles.imageContainer}>
				 <p>Image (optional)</p>
				 <PlatformIconShop width={40} height={40} fill={addNewCustom ? colorPickerColorNew : colorPickerColor} />
			 </div>
			 <CloseSvg className={styles.closeIcon} height={25} onClick={handleCloseMarketModal} />

			 <div className={styles.marketNameContainer}>
				 <p>Title*</p>
				 <div className={styles.platformInputWrapper}>
					 <input
					  type="text"
					  className="myInput"
					  value={addNewCustom ? customMarketNameValueNew : customMarketNameValue}
					  onChange={(e) => handleChangeCustomMarketValue(e.target.value)}
					 />
					 {
						 addNewCustom ? (<span>{customMarketNameValueNew?.length ? customMarketNameValueNew?.length : 0}/11</span>) : (<span>{customMarketNameValue?.length ? customMarketNameValue?.length : 0}/11</span>)
					 }

				 </div>
			 </div>
			 <div className={styles.colorContainer}>
				 <p>Color*</p>
				 <ColorPicker
				  defaultColor={colorPickerColor}
				  handleChangeColorPickerColor={handleChangeColorPickerColor}
				  isMobile={isMobile}
				 />
			 </div>
			 <div className={styles.buttonsContainer}>
				 <Button modifier={ModifierType.Primary} handleClick={handleSave}>Save</Button>
				 {
					 addNewCustom ? (
						  <Button
						   style={{
							   border:'1px solid  #B6B6B6',
							   color:'#B6B6B6'
						   }}
						   modifier={ModifierType.Transparent} handleClick={handleDeleteNewCustom}>
							  <span>Delete</span>
						  </Button>
					 ) : (
					  <Button
					   style={{
						   border: '1px solid #B6B6B6',
						   color: '#B6B6B6'
					   }}
					   modifier={ModifierType.Transparent} handleClick={handleCancel}>
							  <span>Cancel</span>
						  </Button>
					 )
				 }
			 </div>
		 </div>
	 </div>
	);
};

export default MarketModalMobile;