import React, {ClipboardEvent, useLayoutEffect, useState} from 'react'
import styles from '../../../../SendToSalesModal.module.scss'

import _ from "lodash";

import {TrashSolidSvg} from "../../../../../../../../assets/icons";
import useBasisFuncs from "../../../../../../../../hooks/useBasisFuncs";
import convertToCamelCase from "../../../../../../../../utils/convertToCamelCase";
import UseIsSmallLaptop from "@hooks/UseIsSmallLaptop";

const modalListingsFields = [
	{
		id: 1,
		value: "sku",
		title: "SKU",
		width: '140px'
	},
	{
		id: 3,
		value: "quantity_sold",
		title: "Qty Sold",
		width: '80px'
	},
	{
		id: 4,
		value: "quantity_available",
		title: "Qty Available",
		width: '80px'

	},
	{
		id: 5,
		value: "cogs",
		title: "COGS",
		width: '100px'
	},
	{
		id: 6,
		value: "listing_id",
		title: "Listing ID",
		width: '125px'
	},
]

interface ICombineItemProps {
	id:number;
	index: number;
	thumbnail: string;
	metadata: any;
	modalListings: any;
	setModalListings: (modalListings: any) => void;
	handleDeleteListingInModal:(id:number)=> void;
	checkPlatformListingId:(metadata:any) => string;
	modalListing:any;
}

const CombineItem = (props: ICombineItemProps) => {
	const {id,index, thumbnail, metadata, modalListings, setModalListings,handleDeleteListingInModal,checkPlatformListingId,modalListing} = props;
	const isSmallScreenLaptop = UseIsSmallLaptop() ;

	const {bdecode} = useBasisFuncs()

	const [combineListing, setCombineListing] = useState<any>({
		newSku: {
			text: "",
			changed: false
		},
		newItemSoldPrice: {
			text: "",
			changed: false
		},
		newQuantitySold: {
			text: "",
			changed: false
		},
		newQuantityAvailable: {
			text: "",
			changed: false
		},
		newCogs: {
			text: "",
			changed: false
		},

	})
	const [dimensions, setDimensions] = useState<string | null>(null);

	const handleChangeInput = (e:
								| React.ChangeEvent<HTMLInputElement>
								| React.ChangeEvent<HTMLTextAreaElement>
								| React.ChangeEvent<HTMLSelectElement>) => {
		const {name, value} = e.target;

		setCombineListing({})

		updateModalListings(name, value)
	}


	function updateModalListings(name: string, value: string) {

		setCombineListing({...combineListing, [name]: {text: value, changed: true}});

		const updatedModalListings = [...modalListings]

		if (value === "") {
			delete updatedModalListings[index][name]
		} else {
			updatedModalListings.forEach((item: any, upIndex: number) => {
				if (upIndex === index) {
					item[name] = value;
					item.changed = true
				}
			})
		}

		setModalListings(updatedModalListings)
	}

	const checkInputValue = (item: any) => {
		let value = ""
		if (item.value === 'sku') {
			value = metadata[1] || '-'
		} else if (item.value === 'sold_price') {
			value = metadata[842]
		} else if (item.value === 'cogs') {
			value = metadata[847]
		} else if (item.value === 'quantity_available') {
			value = metadata[851]
		} else if(item.value === 'listing_id') {
			value = _.has(modalListing, 'newListingId') ? modalListing?.newListingId :  checkPlatformListingId(metadata)
		}  else if(item.value === 'quantity_sold') {
			value =	'1'
		}
		return value
	}

	const checkInputType = (item: any) => {
		let type = "text"
		if (item.value === 'quantity_sold' || item.value === 'quantity_available' || item.value === 'cogs' ) {
			type = 'number'
		}

		return type
	}

	const handlePaste = (event: ClipboardEvent<HTMLInputElement>) => {
		const inputType = event.currentTarget.type;
		const pasteData = event.clipboardData?.getData('Text');

		if (inputType === 'number' && isNaN(Number(pasteData))) {
			event.preventDefault();
		}
	};

	useLayoutEffect(() => {
		const getImageDimensions = (src: string | undefined) => {
			try {
				if (src) {
					const img = new Image();
					img.src = src;


					img.onload = () => {
						if (img.width >= img.height) {
							setDimensions('width');
						} else {
							setDimensions('height');
						}
					};
				}
			} catch (e) {
				console.log('e');
			}
		};
		getImageDimensions(modalListing?.thumbnail);
	}, []);

	return (
	 <div
	  className={styles.modalItem}>
		 <div className={styles.modalNumWithTrashBlock}>
			 <div className={styles.modalItemNumber}>
				 <span>   {index + 1}</span>

			 </div>

			 <TrashSolidSvg
			  className={styles.trashSolid}
			  onClick={() => handleDeleteListingInModal(id)}

			 />
		 </div>
		 <div>
			 <div className={styles.modalHeader}>


				 <div
				  style={{
					  borderRadius: '8px',
					  background: thumbnail ? '0' : '#DBDBDB'
				  }}
				  className={styles.modalThumbnail}>
					 {thumbnail ? <img
					  style={{
						  width: dimensions === 'width' ? (isSmallScreenLaptop ? "68px" : "85px") : 'auto',
						  height: dimensions === 'height' ? (isSmallScreenLaptop ? "68px" : "85px") : 'auto',
					  }}
					  src={thumbnail}/> : <div className={styles.noThumbnail}></div>}
				 </div>
				 <div className={styles.modalTitle}>

					 {bdecode(metadata[133]) || '-'}

				 </div>
			 </div>
			 <div
			  className={styles.modalFields}>
				 {modalListingsFields?.map((item: any) => (
				  <div
				   key={item.id}
				   className={styles.modalFieldItem}
				  >
					  <label htmlFor={item.value}>
						  {item.title}
					  </label>
					  <input
					   type={checkInputType(item)}
					   style={{width: item?.width}}
					   className={`${styles.sendToSales__input} ${styles.combineModalItemInput}`}
					   name={convertToCamelCase(item?.value)}
					   value={combineListing[convertToCamelCase(item.value)]?.changed ? combineListing[convertToCamelCase(item.value)]?.text : checkInputValue(item)}
					   onChange={handleChangeInput}
					   onPaste={handlePaste}
					  />
				  </div>
				 ))}
			 </div>
		 </div>

	 </div>
	)
}

export default CombineItem